import React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { unregister } from "./core/registerServiceWorker";
import { store } from "./store";
import Root from "./Root/Root";
import { globalStyles } from "./core/styles.global";
import { history } from "./core/history";
import versionWatcher from "./core/lifecycle/versionWatcher";
import { routes } from "./core/routes";
import { SnackbarProvider } from "notistack";

const App = () => (
  <Provider store={store}>
    <SnackbarProvider maxSnack={3}>
      <Router history={history}>
        <Root>{routes}</Root>
      </Router>
    </SnackbarProvider>
  </Provider>
);

ReactDOM.render(<App />, document.getElementById("root") as HTMLElement);
unregister();
globalStyles();
versionWatcher();
