import React from "react";
import { connect } from "react-redux";
import { Grid, Hidden, Button, LinearProgress } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { withSnackbar, WithSnackbarProps } from "notistack";

import { IRootState } from "../store";
import { withThemeProvider } from "../util/withThemeProvider";
import { ISubscriptionCollection } from "./models/ISubscription";
import {
  getSubscriptionCollection,
  changeSubscriptionCollectionPage
} from "./actions/SubscriptionCollectionAction";
import { SubTable } from "./components/SubTable";
import {
  NoStyleLink,
  StyledTitle,
  StyledFormWrapper,
  NewStyledPaper
} from "../components/sharedStyledComponents";
import {
  SnackbarError,
  isSnackbarError
} from "../core/utilities/AlertUtilities";
import { MobileTable } from "../components/MobileTable/MobileTable";
import { subscriptionsToMobileTableRow } from "./services/SubscriptionService";

interface ISubscriptionStateProps {
  subscriptionCollection: ISubscriptionCollection;
}

interface ISubscriptionDispatchProps {
  getSubscriptionCollection: () => Promise<
    ISubscriptionCollection | SnackbarError
  >;
  changePage: (
    page: string
  ) => Promise<ISubscriptionCollection | SnackbarError>;
}

type SubscriptionsType = ISubscriptionStateProps &
  ISubscriptionDispatchProps &
  WithSnackbarProps;

export class Subscriptions extends React.Component<SubscriptionsType> {
  public fetchSubscriptions = async (page?: string) => {
    const {
      changePage,
      getSubscriptionCollection,
      enqueueSnackbar
    } = this.props;
    let collection: ISubscriptionCollection | SnackbarError | undefined;
    if (page) {
      collection = await changePage(page);
    } else {
      collection = await getSubscriptionCollection();
    }
    if (collection && isSnackbarError(collection)) {
      enqueueSnackbar(collection.message, collection.options);
    }
  };

  public changePage = (page: string) => {
    this.fetchSubscriptions(page);
  };

  public async componentDidMount() {
    this.fetchSubscriptions();
  }

  public render() {
    const {
      subscriptionCollection: { members, view, totalItems, isLoading }
    } = this.props;

    return (
      <React.Fragment>
        <StyledFormWrapper>
          <Grid container={true}>
            <Grid item={true} xs={4} sm={3} md={2}>
              <StyledTitle margintop="8px">Subscriptions</StyledTitle>
            </Grid>
            <Grid item={true} xs={4} sm={6} md={8} />
            <Grid item={true} xs={4} sm={3} md={2}>
              <StyledTitle textalign="right">
                <NoStyleLink to="/subscriptions/create">
                  <Button color="default">
                    <AddIcon />
                    Create
                  </Button>
                </NoStyleLink>
              </StyledTitle>
            </Grid>
          </Grid>
          <NewStyledPaper>
            <Hidden smDown={true}>
              <SubTable
                isLoading={isLoading}
                pagination={view}
                rows={members}
                totalItems={totalItems}
                onChangePage={this.changePage}
              />
            </Hidden>
            <Hidden mdUp={true}>
              <MobileTable
                rows={subscriptionsToMobileTableRow(members)}
                pagination={view}
                totalItems={totalItems}
                onChangePage={this.changePage}
                isLoading={isLoading}
              />
            </Hidden>
            {isLoading && (
              <React.Fragment>
                <br /> <LinearProgress /> <br />
              </React.Fragment>
            )}
          </NewStyledPaper>
        </StyledFormWrapper>
        <br />
        <br />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IRootState): ISubscriptionStateProps => ({
  subscriptionCollection: state.subscriptionCollection
});

const mapDispatchToProps = (dispatch: any): ISubscriptionDispatchProps => {
  return {
    getSubscriptionCollection: (): Promise<
      ISubscriptionCollection | SnackbarError
    > => {
      return dispatch(getSubscriptionCollection());
    },
    changePage: (
      page: string
    ): Promise<ISubscriptionCollection | SnackbarError> => {
      return dispatch(changeSubscriptionCollectionPage(page));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withThemeProvider(withSnackbar(Subscriptions)));
