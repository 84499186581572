import React from "react";
import { Field, Form, FormikProps } from "formik";
import { Button, LinearProgress, NoSsr, Grid } from "@material-ui/core";

import { ISubscription, initialSubscription } from "../models/ISubscription";
import { Validators } from "../../util/validators";
import { StyledTextField, TextField } from "../../components/TextField";
import { StyledAutocompleteSelectLabel } from "../../components/sharedStyledComponents";
import {
  AutocompleteSelect,
  StyledAutocomplete
} from "../../components/AutocompleteSelect";

export const CreateSubForm: React.FunctionComponent<
  FormikProps<ISubscription>
> = ({ submitForm, isSubmitting, values }: FormikProps<ISubscription>) => {
  const { isLoading, userList } = values;
  const { name, owner } = Validators.editSubscription;

  return (
    <Form>
      <StyledTextField
        type="text"
        label="Name"
        name="name"
        multiline={false}
        component={TextField}
        disabled={isSubmitting || isLoading}
        validate={name}
      />
      <br />
      <br />
      <NoSsr>
        {Boolean(values.owner.id) && !isLoading && (
          <StyledAutocompleteSelectLabel htmlFor="owner">
            Owner
          </StyledAutocompleteSelectLabel>
        )}

        <StyledAutocomplete>
          <Field
            name="owner"
            options={userList}
            component={AutocompleteSelect}
            isDisabled={isSubmitting || isLoading}
            validate={owner}
            labelField="name"
            identityKey="id"
            placeholder="Select Owner"
            isClearable={true}
            clearValue={initialSubscription}
          />
        </StyledAutocomplete>
      </NoSsr>

      {(isSubmitting || isLoading) && <LinearProgress />}
      <br />
      <Grid
        container={true}
        item={true}
        direction="row"
        justify="flex-start"
        alignItems="center"
      >
        <Button
          variant="contained"
          color="primary"
          disabled={isSubmitting || isLoading}
          onClick={submitForm}
        >
          Create
        </Button>
      </Grid>
    </Form>
  );
};
