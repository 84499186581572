import React, { FunctionComponent } from "react";
import { IDeviceCalibrationTableRow } from "../../../models/IDevice";
import { TableCell, TableRow } from "@material-ui/core";

export const CalibrationTableRow: FunctionComponent<
  IDeviceCalibrationTableRow
> = ({ actualValue, deviceValue }) => {
  return (
    <TableRow>
      <TableCell>
        {actualValue}
      </TableCell>
      <TableCell>
        {deviceValue}
      </TableCell>
    </TableRow>
  );
};
CalibrationTableRow.displayName = "CalibrationTableRow";
