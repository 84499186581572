import * as React from "react";

import { TableRow, Button } from "@material-ui/core";

import { IPolicy } from "../../models/IPolicy";
import { ITableDeleteCell } from "../../../models/shared/IShared";
import {
  NoStyleLink,
  ButtonTableCell,
  SmallIcon,
  SmallDeleteIcon,
  StyledTableCell
} from "../../../components/sharedStyledComponents";

interface IPolicyTableRowProps {
  policy: IPolicy;
  handleDialogOpen: (
    policyInfo: ITableDeleteCell
  ) => (_: React.MouseEvent) => void;
}

export const PolicyTableRow: React.FunctionComponent<IPolicyTableRowProps> = ({
  policy: {
    id,
    shortId,
    name,
    subscription: { name: subName }
  },
  handleDialogOpen
}: IPolicyTableRowProps) => (
  <TableRow key={id}>
    <StyledTableCell>{name}</StyledTableCell>
    <StyledTableCell>{subName}</StyledTableCell>
    <ButtonTableCell>
      <NoStyleLink to={"/policies/" + shortId}>
        <Button color="primary">
          Edit <SmallIcon />
        </Button>
      </NoStyleLink>
      <br />
      <Button
        color="primary"
        onClick={handleDialogOpen({
          visible: true,
          id: id,
          title: name
        })}>
        Delete <SmallDeleteIcon />
      </Button>
    </ButtonTableCell>
  </TableRow>
);
