import React from "react";
import { Field, Form, FormikProps } from "formik";
import { Button, LinearProgress, NoSsr, Grid } from "@material-ui/core";

import { IRole } from "../models/IRole";
import { Validators } from "../../util/validators";
import { StyledTextField, TextField } from "../../components/TextField";
import {
  AutocompleteSelect,
  StyledAutocomplete
} from "../../components/AutocompleteSelect";
import { initialRole } from "../models/IRole";
import { StyledAutocompleteSelectLabel } from "../../components/sharedStyledComponents";
import { ISubscriptionSelection } from "../../models/shared/IShared";
import { initialSubscription } from "../../Subscriptions/models/ISubscription";

interface IEditRoleProps {
  subscriptionSelection: ISubscriptionSelection;
}

type CreateRoleFormType = FormikProps<IRole> & IEditRoleProps;

export const CreateRoleForm: React.FunctionComponent<CreateRoleFormType> = ({
  submitForm,
  isSubmitting,
  values,
  subscriptionSelection
}: CreateRoleFormType) => {
  const { isLoading, subscriptionList, policyList } = values;
  const { name, subscription } = Validators.editRole;

  values.subscription = {
    ...initialSubscription,
    name: subscriptionSelection.label,
    id: subscriptionSelection.value
  };

  return (
    <Form>
      <StyledTextField
        type="text"
        label="Name"
        name="name"
        multiline={false}
        component={TextField}
        disabled={isSubmitting || isLoading}
        value={values.name}
        validate={name}
      />
      <br />
      <br />
      <NoSsr>
        {Boolean(values.subscription.id) && !isLoading && (
          <StyledAutocompleteSelectLabel htmlFor="subscription">
            Subscription
          </StyledAutocompleteSelectLabel>
        )}

        <StyledAutocomplete>
          <Field
            name="subscription"
            options={subscriptionList}
            component={AutocompleteSelect}
            isDisabled={true}
            validate={subscription}
            labelField="name"
            identityKey="id"
            placeholder="Select Subscription"
            isClearable={true}
            clearValue={initialRole}
          />
        </StyledAutocomplete>
      </NoSsr>

      <br />
      <br />

      <NoSsr>
        <StyledAutocomplete>
          <Field
            name="appliedPolicies"
            options={policyList}
            component={AutocompleteSelect}
            isDisabled={isSubmitting || isLoading}
            labelField="name"
            identityKey="id"
            sortableKey="order"
            placeholder="Add Policies"
            isMulti={true}
            isClearable={true}
            clearValue={[]}
          />
        </StyledAutocomplete>
      </NoSsr>

      {(isSubmitting || isLoading) && <LinearProgress />}
      <br />
      <Grid
        container={true}
        item={true}
        direction="row"
        justify="flex-start"
        alignItems="center"
      >
        <Button
          variant="contained"
          color="primary"
          disabled={isSubmitting || isLoading}
          onClick={submitForm}
        >
          Create
        </Button>
      </Grid>
    </Form>
  );
};
