import React from "react";
import { connect } from "react-redux";
import { Grid, Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { FormikProps, FormikActions } from "formik/dist/types";
import { Formik } from "formik";
import styled from "styled-components";

import { withThemeProvider } from "../util/withThemeProvider";
import { IRootState } from "../store";
import {
  IDevice,
  IDeviceCalibration,
  IDeviceCalibrationTable,
  IDeviceCalibrationHistoryTable,
  IDeviceCalibrationHistory
} from "./models/IDevice";
import {
  initializeCalibration,
  getDeviceCalibrationHistory,
  getDeviceCalibrationNewFormula,
  createDeviceCalibration,
  getDeviceCalibrationById,
  clearDeviceCalibration,
  clearDeviceCalibrationCurrentTable,
  clearDeviceCalibrationHistory,
  updateDeviceCalibrationValue
} from "./actions/deviceAction";
import {
  StyledTitle,
  StyledBackground,
  StyledFormWrapper
} from "../components/sharedStyledComponents";
import {
  isSnackbarError,
  SnackbarError,
  alertMessages,
  getStyledSnackbarOptions
} from "../core/utilities/AlertUtilities";
import { ExpansionPanelComponent } from "./components/calibration/ExpansionPanel";
import { CalibrationForm } from "./components/calibration/CalibrationForm";

interface IPathParamsType {
  id: string;
}

interface ICalibrateDeviceStateProps {
  calibration: IDeviceCalibration;
  calibrationHistoryCollection: IDeviceCalibrationHistory;
}

interface ICalibrateDeviceDispatchProps {
  calibrationInit: (id: string) => Promise<IDevice | SnackbarError>;
  getDeviceCalibrationHistory: (
    deviceId: string,
    parameter: string,
    calibrationTable: IDeviceCalibrationTable
  ) => Promise<IDeviceCalibrationHistoryTable[] | SnackbarError>;
  getDeviceCalibrationNewFormula: (
    calibrationTable: IDeviceCalibrationTable
  ) => Promise<IDeviceCalibrationTable | SnackbarError>;
  createDeviceCalibration: (
    calibration: IDeviceCalibration
  ) => Promise<IDeviceCalibrationTable | SnackbarError>;
  getDeviceCalibrationById: (
    calibrationId: string
  ) => Promise<void | SnackbarError>;
  clearDeviceCalibration: () => void;
  clearDeviceCalibrationCurrentTable: () => void;
  clearDeviceCalibrationHistory: () => void;
  updateDeviceCalibrationValue: (
    rowIndex: number,
    calibration: IDeviceCalibration
  ) => Promise<IDeviceCalibrationTable | SnackbarError>;
}

type CalibrateDeviceType = ICalibrateDeviceStateProps &
  ICalibrateDeviceDispatchProps &
  RouteComponentProps<IPathParamsType> &
  WithSnackbarProps;

export const CalibrationHistoryContainer = styled(Grid)`
  padding-top: 35px;
  & > * {
    width: 100%;
  }
`;
CalibrationHistoryContainer.displayName = "CalibrationHistoryContainer";

export class CalibrateDevice extends React.Component<CalibrateDeviceType> {
  public async componentDidMount() {
    const {
      calibrationInit,
      enqueueSnackbar,
      match: {
        params: { id }
      }
    } = this.props;
    const getDeviceResult = await calibrationInit(id);
    if (isSnackbarError(getDeviceResult)) {
      enqueueSnackbar(getDeviceResult.message, getDeviceResult.options);
    }
  }

  public async componentWillUnmount() {
    const {
      clearDeviceCalibration,
      clearDeviceCalibrationHistory
    } = this.props;
    clearDeviceCalibration();
    clearDeviceCalibrationHistory();
  }

  public getDeviceCalibrationHistoryHandler = async (
    deviceId: string,
    parameter: string,
    currentCalibrationTable: IDeviceCalibrationTable
  ) => {
    const { getDeviceCalibrationHistory, enqueueSnackbar } = this.props;
    const result = await getDeviceCalibrationHistory(
      deviceId,
      parameter,
      currentCalibrationTable
    );
    if (isSnackbarError(result)) {
      enqueueSnackbar(result.message, result.options);
    }
  };

  public getDeviceCalibrationByIdHandler = async (calibrationId: string) => {
    const { getDeviceCalibrationById, enqueueSnackbar } = this.props;
    const result = await getDeviceCalibrationById(calibrationId);
    if (isSnackbarError(result)) {
      enqueueSnackbar(result.message, result.options);
    }
  };

  public getDeviceCalibrationNewFormulaHandler = async (
    currentCalibrationTable: IDeviceCalibrationTable
  ) => {
    const { getDeviceCalibrationNewFormula, enqueueSnackbar } = this.props;
    const result = await getDeviceCalibrationNewFormula(
      currentCalibrationTable
    );
    if (isSnackbarError(result)) {
      enqueueSnackbar(result.message, result.options);
    }
  };

  public clearDeviceCalibrationCurrentTableHandler = () => {
    const { clearDeviceCalibrationCurrentTable } = this.props;
    clearDeviceCalibrationCurrentTable();
  };

  public clearDeviceCalibrationHistoryHandler = () => {
    const { clearDeviceCalibrationHistory } = this.props;
    clearDeviceCalibrationHistory();
  };

  public updateDeviceCalibrationValueHandler = async (
    rowIndex: number,
    item: IDeviceCalibration
  ) => {
    const { updateDeviceCalibrationValue, enqueueSnackbar } = this.props;
    const result = await updateDeviceCalibrationValue(rowIndex, item);
    if (isSnackbarError(result)) {
      enqueueSnackbar(result.message, result.options);
    }
  };

  public renderCalibrateDeviceForm = (
    formikProps: FormikProps<IDeviceCalibration>
  ) => {
    return (
      <CalibrationForm
        {...formikProps}
        getDeviceCalibrationHistory={this.getDeviceCalibrationHistoryHandler}
        getDeviceCalibrationNewFormula={
          this.getDeviceCalibrationNewFormulaHandler
        }
        clearDeviceCalibrationCurrentTable={
          this.clearDeviceCalibrationCurrentTableHandler
        }
        clearDeviceCalibrationHistory={
          this.clearDeviceCalibrationHistoryHandler
        }
        updateDeviceValueForCalibrate={this.updateDeviceCalibrationValueHandler}
      />
    );
  };

  public onSubmit = async (
    values: IDeviceCalibration,
    { setSubmitting }: FormikActions<IDeviceCalibration>
  ) => {
    const { createDeviceCalibration, enqueueSnackbar } = this.props;
    if (values) {
      const createResult = await createDeviceCalibration(values);

      if (isSnackbarError(createResult)) {
        enqueueSnackbar(createResult.message, createResult.options);
      } else {
        if (Boolean(createResult)) {
          enqueueSnackbar(
            alertMessages.DEVICE_CALIBRATE_SUCCESS,
            getStyledSnackbarOptions("success")
          );

          setSubmitting(false);
        }
      }
    }
  };

  public render() {
    const { calibration, calibrationHistoryCollection: history } = this.props;

    return (
      <React.Fragment>
        <StyledFormWrapper>
          <StyledTitle marginbottom="10px">
            {calibration.isLoading ? "Loading..." : "Calibration"}
          </StyledTitle>
          <StyledBackground>
            <Formik
              enableReinitialize={true}
              initialValues={calibration}
              onSubmit={this.onSubmit}
              render={this.renderCalibrateDeviceForm}
            />
            <CalibrationHistoryContainer container={true} item={true} xs={12}>
              <Typography variant="subtitle1">
                {history.calibrationHistoryLoading
                  ? "Loading..."
                  : "Calibration History"}
              </Typography>
              <br />
              <br />
              <ExpansionPanelComponent
                historyList={history}
                getDeviceCalibrationDetails={
                  this.getDeviceCalibrationByIdHandler
                }
              />
            </CalibrationHistoryContainer>
          </StyledBackground>
        </StyledFormWrapper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IRootState): ICalibrateDeviceStateProps => ({
  calibration: state.deviceCalibration,
  calibrationHistoryCollection: state.deviceCalibrationHistory
});

const mapDispatchToProps = (dispatch: any): ICalibrateDeviceDispatchProps => {
  return {
    calibrationInit: (id: string) => {
      return dispatch(initializeCalibration(id));
    },
    getDeviceCalibrationHistory: (
      deviceId: string,
      parameter: string,
      calibrationTable: IDeviceCalibrationTable
    ) => {
      return dispatch(
        getDeviceCalibrationHistory(deviceId, parameter, calibrationTable)
      );
    },
    getDeviceCalibrationNewFormula: (
      calibrationTable: IDeviceCalibrationTable
    ) => {
      return dispatch(getDeviceCalibrationNewFormula(calibrationTable));
    },
    createDeviceCalibration: (calibration: IDeviceCalibration) => {
      return dispatch(createDeviceCalibration(calibration));
    },
    getDeviceCalibrationById: (calibrationId: string) => {
      return dispatch(getDeviceCalibrationById(calibrationId));
    },
    clearDeviceCalibration: () => {
      dispatch(clearDeviceCalibration());
    },
    clearDeviceCalibrationCurrentTable: () => {
      dispatch(clearDeviceCalibrationCurrentTable());
    },
    clearDeviceCalibrationHistory: () => {
      dispatch(clearDeviceCalibrationHistory());
    },
    updateDeviceCalibrationValue: (
      rowIndex: number,
      calibration: IDeviceCalibration
    ) => {
      return dispatch(updateDeviceCalibrationValue(rowIndex, calibration));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withThemeProvider(withSnackbar(CalibrateDevice)));
