import React from "react";
import { FieldArrayRenderProps, Field } from "formik";
import { Add as AddIcon, Delete as DeleteIcon } from "@material-ui/icons";
import {
  IconButton,
  FormHelperText,
  Tooltip,
  MenuItem,
  InputLabel,
  Checkbox,
  ListItemText,
  Grid
} from "@material-ui/core";

import { Validators } from "../../util/validators";
import { StyledTextField, TextField } from "../../components/TextField";
import { IPermission } from "../models/IPolicy";
import { Configuration } from "../../config";
import { StyledSelect, Select } from "../../components/Select";
import {
  StyledTitle,
  ActionButton,
  ParameterFieldContainer,
  AnimatedGrid
} from "../../components/sharedStyledComponents";

export const PermissionsFormArray: React.FunctionComponent<
  FieldArrayRenderProps
> = arrayHelpers => {
  const { form, push, remove } = arrayHelpers;

  const removePermission = (index: number) => () => remove(index);

  const addPermission = (e: React.MouseEvent) => {
    e.preventDefault();
    push({
      access: "",
      methods: [],
      resource: ""
    });
  };

  const {
    values: { permissions, isLoading },
    isSubmitting
  } = form;
  const {
    permissions: { access, methods, resource }
  } = Validators.editPolicy;
  const {
    permissions: { access: configurationAccess, methods: configurationMethods }
  } = Configuration;

  const renderMultiselect = (selected: string[]) => selected.join(", ");

  return (
    <React.Fragment>
      <StyledTitle>Permissions</StyledTitle>
      <Grid container={true} item={true} justify="flex-start">
        {permissions.map((permission: IPermission, index: number) => {
          return (
            <AnimatedGrid
              item={true}
              container={true}
              direction="row"
              xs={12}
              alignItems="center"
              key={index}
              width="97%"
            >
              <Grid item={true} container={true} direction="row" xs={true}>
                <ParameterFieldContainer item={true} xs={3}>
                  <StyledSelect>
                    <InputLabel htmlFor={`permissions[${index}].access`}>
                      Access
                    </InputLabel>
                    <Field
                      name={`permissions[${index}].access`}
                      label="Access"
                      component={Select}
                      multiple={false}
                      disabled={isSubmitting || isLoading}
                      validate={access}
                    >
                      {configurationAccess.map(({ value, text }, index) => (
                        <MenuItem key={index} value={value}>
                          {text}
                        </MenuItem>
                      ))}
                    </Field>
                  </StyledSelect>
                </ParameterFieldContainer>
                <ParameterFieldContainer item={true} xs={3}>
                  <StyledSelect>
                    <InputLabel htmlFor={`permissions[${index}].methods`}>
                      Methods
                    </InputLabel>
                    <Field
                      name={`permissions[${index}].methods`}
                      label="Methods"
                      multiple={true}
                      component={Select}
                      disabled={isSubmitting || isLoading}
                      validate={methods}
                      renderValue={renderMultiselect}
                    >
                      {configurationMethods.map(({ value }, i) => (
                        <MenuItem key={i} value={value}>
                          <Checkbox
                            color="primary"
                            checked={
                              permissions[index].methods.indexOf(value) > -1
                            }
                          />
                          <ListItemText primary={value} />
                        </MenuItem>
                      ))}
                    </Field>
                  </StyledSelect>
                </ParameterFieldContainer>
                <ParameterFieldContainer item={true} xs={6}>
                  <StyledTextField
                    type="text"
                    label="Resource"
                    name={`permissions[${index}].resource`}
                    component={TextField}
                    disabled={isSubmitting || isLoading}
                    validate={resource}
                  />
                </ParameterFieldContainer>
              </Grid>
              {!(isSubmitting || isLoading) && (
                <Tooltip title="Delete">
                  <ActionButton
                    onClick={removePermission(index)}
                    aria-label="Delete permission"
                  >
                    <DeleteIcon />
                  </ActionButton>
                </Tooltip>
              )}
            </AnimatedGrid>
          );
        })}

        {!(isSubmitting || isLoading) &&
          (permissions.length < 70 ? (
            <IconButton onClick={addPermission}>
              <AddIcon />
            </IconButton>
          ) : (
            <FormHelperText error={true}>
              70 permissions is maximum
            </FormHelperText>
          ))}
      </Grid>
    </React.Fragment>
  );
};
PermissionsFormArray.displayName = "PermissionsFormArray";
