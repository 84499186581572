import React from "react";
import { Route, Switch } from "react-router-dom";
import Devices from "../Devices/Devices";
import EditDevice from "../Devices/EditDevice";
import { UserContainer } from "../Users/UserContainer";
import EditUser from "../Users/EditUser";
import Subscriptions from "../Subscriptions/Subscriptions";
import EditSub from "../Subscriptions/EditSub";
import DeviceModels from "../DeviceModels/DeviceModels";
import EditDeviceModel from "../DeviceModels/EditDeviceModel";
import Dashboard from "../Dashboard/Dashboard";
import EditUserProfile from "../Profile/EditUserProfile";
import Roles from "../Roles/Roles";
import EditRole from "../Roles/EditRole";
import Policies from "../Policies/Policies";
import EditPolicies from "../Policies/EditPolicies";
import EditUserBySubscription from "../UsersBySubscription/EditUserBySubscription";
import CalibrateDevice from "../Devices/CalibrateDevice";

const renderEditDevice = (props: any) => (
  <EditDevice key={props.match.params.id} {...props} />
);

const renderCalibrateDevice = (props: any) => (
  <CalibrateDevice key={props.match.params.id} {...props} />
);

const renderEditUser = (props: any) =>
  props.location.state && props.location.state.userBySubscription ? (
    <EditUserBySubscription key={props.match.params.id} {...props} />
  ) : (
    <EditUser key={props.match.params.id} {...props} />
  );

const renderEditRole = (props: any) => (
  <EditRole key={props.match.params.id} {...props} />
);

const renderEditPolicies = (props: any) => (
  <EditPolicies key={props.match.params.id} {...props} />
);

const renderEditDeviceModel = (props: any) => (
  <EditDeviceModel key={props.match.params.id} {...props} />
);

const renderEditSub = (props: any) => (
  <EditSub key={props.match.params.id} {...props} />
);

export const routes = (
  <Switch>
    <Route exact path="/" component={Dashboard} />
    <Route exact path="/devices" component={Devices} />
    <Route exact path="/devices/create" render={renderEditDevice} />
    <Route exact path="/devices/:id" render={renderEditDevice} />
    <Route exact path="/devices/:id/calibrate" render={renderCalibrateDevice} />
    <Route exact path="/users" component={UserContainer} />
    <Route exact path="/users/create" component={renderEditUser} />
    <Route exact path="/users/add" component={renderEditUser} />
    <Route exact path="/users/:id" component={renderEditUser} />
    <Route exact path="/subscriptions" component={Subscriptions} />
    <Route exact path="/subscriptions/create" component={renderEditSub} />
    <Route exact path="/subscriptions/:id" component={renderEditSub} />
    <Route exact path="/deviceModels" component={DeviceModels} />
    <Route exact path="/deviceModels/create" render={renderEditDeviceModel} />
    <Route exact path="/deviceModels/:id" render={renderEditDeviceModel} />
    <Route exact path="/roles" component={Roles} />
    <Route exact path="/roles/create" render={renderEditRole} />
    <Route exact path="/roles/:id" render={renderEditRole} />
    <Route exact path="/policies" component={Policies} />
    <Route exact path="/policies/create" render={renderEditPolicies} />
    <Route exact path="/policies/:id" render={renderEditPolicies} />
    <Route exact path="/profile/:id" component={EditUserProfile} />
  </Switch>
);
