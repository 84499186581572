import { IApiResponseHeader } from "../../models/IApiResponseHeader";
import { IDetermination, ILoadable } from "../../core/models/MixinInterfaces";
import { ISubscription } from "../../Subscriptions/models/ISubscription";
import { IRole } from "../../Roles/models/IRole";
import { ICollection } from "../../models/shared/IShared";

export interface ISubscriptionWithRole extends ISubscription {
  roles: IRole[];
  roleList: IRole[];
  roleListLoading: boolean;
}

export interface IUserApi extends IApiResponseHeader {
  name: string;
  email: string;
  mobile: string;
  activeRole: string;
  activeSubscription: string;
  hasRoles: {
    [subscription: string]: string[];
  };
  notificationChannels?: string[];
}

export interface IUserCollectionApi extends IApiResponseHeader, ICollection {
  "@context"?: string;
  member: IUserApi[];
  totalItems: number;
  page?: number;
}

export interface IUser extends ILoadable, IDetermination {
  name: string;
  email: string;
  mobile: string;
  activeRole: string;
  activeSubscription: string;
  hasRoles: ISubscriptionWithRole[];
  smsNotification: boolean;
  emailNotification: boolean;
  subscriptionList: ISubscriptionWithRole[];
  roleList: IRole[];
}

export interface IUserCollection
  extends IDetermination,
    ILoadable,
    ICollection {
  context?: string;
  members: IUser[];
  totalItems: number;
  page: number;
}

export const initialUser: IUser = {
  email: "",
  activeRole: "",
  activeSubscription: "",
  hasRoles: [],
  id: "",
  isLoading: false,
  mobile: "",
  name: "",
  smsNotification: false,
  emailNotification: false,
  shortId: "",
  type: "",
  subscriptionList: [],
  roleList: []
};

export const initialUserCollection: IUserCollection = {
  context: "",
  id: "",
  isLoading: false,
  members: [],
  page: 0,
  shortId: "",
  totalItems: 0,
  type: "",
  view: {
    "@id": "",
    "@type": "",
    first: "",
    last: "",
    next: "",
    previous: ""
  }
};
