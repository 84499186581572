import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import styled from "styled-components";
import { Snackbar, Button, Hidden } from "@material-ui/core";
import BackIcon from "@material-ui/icons/ChevronLeft";
import { withSnackbar, WithSnackbarProps } from "notistack";

import { withThemeProvider, theme } from "../util/withThemeProvider";
import { Header } from "../components/Header";
import { ICurrentUser, IGlobalState } from "../models/GlobalState";
import { Menu } from "../components/Menu";
import { IRootState } from "../store";
import {
  openMenuAction,
  updateClient,
  logIn,
  logOut,
  toggleToolbarMenuState,
  checkUserAccessLevel,
  closeExpiredSessionSnackbar,
  closeLoginErrorSnackbar
} from "../actions/globalStateAction";
import { Breadcrumbs } from "../components/Breadcrumbs";
import { Configuration } from "../config";
import { IUserProfile } from "../Profile/models/IUserProfile";
import { getUserById } from "../Profile/actions/userProfileAction";
import {
  alertMessages,
  SnackbarError,
  isSnackbarError
} from "../core/utilities/AlertUtilities";

export const Content = styled.div`
  padding-top: 60px;
  flex-grow: 1;
  background-color: ${theme.palette.background.default};
  transition-duration: ${theme.transitions.duration.leavingScreen}ms;
  transition-timing-function: ${theme.transitions.easing.sharp};
  &[data-open="true"] {
    ${theme.breakpoints.up("md")} {
      margin-left: ${Configuration.menuWidth}px;
      transition-property: margin-left;
      transition-duration: ${theme.transitions.duration.enteringScreen}ms;
      transition-timing-function: ${theme.transitions.easing.easeOut};
    }
  }
`;
Content.displayName = "Content";

export const Footer = styled.footer`
  background: ${theme.palette.secondary.main};
  font-size: 14px;
  text-align: center;
  color: white;
  padding: 7px;
  position: fixed;
  bottom: 0;
  z-index: ${theme.zIndex.drawer + 1};
  width: 100%;
  & > a {
    color: inherit;
  }
`;
Footer.displayName = "Footer";

export const BackButton = styled(Button)`
  && {
    padding-top: 10px;
    text-transform: capitalize;
  }
`;
BackButton.displayName = "BackButton";

interface IStateProps {
  globalState: IGlobalState;
  profile: IUserProfile;
}

interface IDispatchProps {
  openMenu: (currentState: boolean) => void;
  updateClient: () => void;
  signIn: (query: string) => Promise<ICurrentUser | SnackbarError>;
  logout: () => void;
  toggleToolbarMenu: (toolbarMenuState: Element | null) => void;
  getUser: (id: string) => Promise<undefined | SnackbarError[]>;
  checkUserAccessLevel: () => void;
  closeExpiredSessionSnackbar: () => void;
  closeLoginErrorSnackbar: () => void;
}

export type rootProps = RouteComponentProps &
  IStateProps &
  IDispatchProps &
  WithSnackbarProps;

export class Root extends React.Component<rootProps> {
  public async componentDidMount() {
    const {
      signIn,
      getUser,
      enqueueSnackbar,
      location: { search },
      checkUserAccessLevel
    } = this.props;

    const signInResult = await signIn(search);

    if (!isSnackbarError(signInResult) && signInResult !== undefined) {
      checkUserAccessLevel();
      const getUserResult = await getUser(signInResult.userId);
 
      if (getUserResult && getUserResult.length > 0) {
        getUserResult.forEach(getUserResult => {
          if (isSnackbarError(getUserResult)) {
            enqueueSnackbar(getUserResult.message, getUserResult.options);
          }
        });
      }
    }
  }

  public showToolbarMenu = (e: any) => {
    const { toggleToolbarMenu } = this.props;
    toggleToolbarMenu(e.target);
  };

  public closeToolbarMenu = () => {
    const { toggleToolbarMenu } = this.props;
    toggleToolbarMenu(null);
  };

  public showMenu = (): void => {
    const {
      openMenu,
      globalState: { menuOpen }
    } = this.props;
    openMenu(menuOpen);
  };

  public refreshClient = (): void => {
    const { updateClient } = this.props;
    updateClient();
  };

  public LogOut = () => {
    const {
      logout,
      closeExpiredSessionSnackbar,
      closeLoginErrorSnackbar,
      globalState: { expiredSession, loginError }
    } = this.props;
    if (expiredSession) {
      closeExpiredSessionSnackbar();
    }
    if (loginError) {
      closeLoginErrorSnackbar();
    }
    logout();
  };

  public render() {
    const {
      globalState: {
        user,
        toolbarMenuOpen,
        menuOpen,
        refreshRequired,
        expiredSession,
        loginError
      },
      children,
      logout,
      profile,
      history: { goBack },
      location: { pathname }
    } = this.props;
    return (
      <React.Fragment>
        {user && user.userId ? (
          <React.Fragment>
            <Header
              toolbarMenuOpen={toolbarMenuOpen}
              menuClick={this.showMenu}
              logout={logout}
              handleClose={this.closeToolbarMenu}
              handleOpen={this.showToolbarMenu}
              user={profile}
            />
            {profile.activeRole.name && profile.activeSubscription.name && (
              <React.Fragment>
                <Menu
                  closeMenu={this.showMenu}
                  menuOpen={menuOpen}
                  userId={profile.shortId}
                />

                <Content data-open={menuOpen}>
                  <Hidden mdUp={true}>
                    {pathname.split("/").filter(path => path.length > 0)
                      .length > 1 && (
                      <BackButton color="primary" onClick={goBack}>
                        <BackIcon />
                        Back
                      </BackButton>
                    )}
                  </Hidden>
                  <Breadcrumbs />
                  {children}
                </Content>
              </React.Fragment>
            )}
            <Footer>
              Powered by <a href="https://ellenex.com">ellenex.com</a> - All
              Rights Reserved.
            </Footer>
          </React.Fragment>
        ) : null}
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={refreshRequired}
          ContentProps={{ "aria-describedby": "message-id" }}
          onClose={this.refreshClient}
          message={<span id="message-id">{alertMessages.NEW_VERSION}</span>}
          id="newVersionSnackbar"
          action={
            <Button
              key="update"
              aria-label="Update"
              color="inherit"
              onClick={this.refreshClient}
            >
              Refresh
            </Button>
          }
        />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={expiredSession}
          ContentProps={{ "aria-describedby": "message-id" }}
          onClose={this.LogOut}
          message={<span id="message-id">{alertMessages.EXPIRED_SESSION}</span>}
          id="expiredsessionSnackbar"
          action={
            <Button
              key="signIn"
              aria-label="signIn"
              color="inherit"
              onClick={this.LogOut}
            >
              Sign In
            </Button>
          }
        />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={loginError}
          ContentProps={{ "aria-describedby": "message-id" }}
          onClose={this.LogOut}
          message={<span id="message-id">{alertMessages.LOGIN_ERROR}</span>}
          id="loginErrorSnackbar"
          action={
            <Button
              key="OK"
              aria-label="OK"
              color="inherit"
              onClick={this.LogOut}
            >
              OK
            </Button>
          }
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IRootState): IStateProps => ({
  globalState: state.globalState,
  profile: state.userProfileReducer
});

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    getUser: (id: string): Promise<undefined | SnackbarError[]> => {
      return dispatch(getUserById(id, true));
    },
    openMenu: (currentState: boolean) => {
      dispatch(openMenuAction(currentState));
    },
    updateClient: () => {
      dispatch(updateClient());
    },
    signIn: (query: string): Promise<ICurrentUser | SnackbarError> => {
      return dispatch(logIn(query));
    },
    logout: () => {
      dispatch(logOut());
    },
    toggleToolbarMenu: (toolbarMenuState: Element | null) => {
      dispatch(toggleToolbarMenuState(toolbarMenuState));
    },
    checkUserAccessLevel: () => {
      dispatch(checkUserAccessLevel());
    },
    closeExpiredSessionSnackbar: () => {
      dispatch(closeExpiredSessionSnackbar());
    },
    closeLoginErrorSnackbar: () => {
      dispatch(closeLoginErrorSnackbar());
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withThemeProvider(withSnackbar(Root)))
);
