import { IRole, initialRole } from "../models/IRole";
import { ITableDeleteCell } from "../../models/shared/IShared";
import { ActionType } from "../../models/Action.model";

export type roleReducerTypes =
  | { type: ActionType.INITIALIZE_ROLE_EDIT; payload: IRole }
  | { type: ActionType.GET_ROLE; payload: IRole }
  | { type: ActionType.CREATE_ROLE; payload: IRole }
  | { type: ActionType.UPDATE_ROLE; payload: IRole }
  | { type: ActionType.ROLE_LOADING; payload: boolean }
  | { type: ActionType.CLEAR_ROLE }
  | { type: ActionType.SET_ROLE_FORM_VALUE; payload: IRole }
  | { type: ActionType.DELETE_ROLE_DIALOG; payload: ITableDeleteCell };

export function roleReducer(
  state: IRole = { ...initialRole },
  action: roleReducerTypes
): IRole {
  switch (action.type) {
    case ActionType.GET_ROLE:
      state = {
        ...state,
        ...action.payload,
        subscriptionList: state.subscriptionList,
        policyList: state.policyList
      };
      break;
    case ActionType.CREATE_ROLE:
      state = {
        ...state,
        ...action.payload,
        subscriptionList: state.subscriptionList,
        policyList: state.policyList
      };
      break;
    case ActionType.UPDATE_ROLE:
      state = {
        ...state,
        ...action.payload,
        subscriptionList: state.subscriptionList,
        policyList: state.policyList
      };
      break;
    case ActionType.ROLE_LOADING:
      state = {
        ...state,
        isLoading: action.payload
      };
      break;
    case ActionType.INITIALIZE_ROLE_EDIT:
      state = {
        ...state,
        subscriptionList: action.payload.subscriptionList,
        policyList: action.payload.policyList
      };
      break;
    case ActionType.CLEAR_ROLE:
      state = { ...initialRole };
      break;
    case ActionType.SET_ROLE_FORM_VALUE:
      state = { ...action.payload };
      break;
    case ActionType.DELETE_ROLE_DIALOG:
      state = {
        ...state,
        roleDelete: action.payload
      };
      break;
  }
  return state;
}
