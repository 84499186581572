import React from "react";
import { connect } from "react-redux";
import {
  Grid,
  Hidden,
  Button,
  LinearProgress,
  IconButton,
  InputBase,
  Paper
} from "@material-ui/core";
import { Add as AddIcon, Search as SearchIcon } from "@material-ui/icons";
import { withSnackbar, WithSnackbarProps } from "notistack";
import styled from "styled-components";

import { IRootState } from "../store";
import { withThemeProvider } from "../util/withThemeProvider";
import { IDeviceCollection, IDevice } from "./models/IDevice";
import { ITableDeleteCell } from "../models/shared/IShared";
import {
  getDeviceCollection,
  changeDeviceCollectionPage,
  deviceSearchBySerialSetValue
} from "./actions/deviceCollectionAction";
import { DeviceTable } from "./components/DeviceTable";
import {
  NoStyleLink,
  StyledTitle,
  StyledFormWrapper,
  NewStyledPaper
} from "../components/sharedStyledComponents";
import {
  alertMessages,
  isSnackbarError,
  SnackbarError,
  getStyledSnackbarOptions
} from "../core/utilities/AlertUtilities";
import { IUserProfile } from "../Profile/models/IUserProfile";
import { DeviceDeleteDialog } from "./components/DeviceDeleteDialog";
import {
  deviceDeleteConfirmDialog,
  deleteDevice
} from "./actions/deviceAction";
import { devicesToMobileTableRow } from "./services/DeviceService";
import { MobileTable } from "../components/MobileTable/MobileTable";

interface IDevicesStateProps {
  deviceCollection: IDeviceCollection;
  device: IDevice;
  profile: IUserProfile;
}

interface IDevicesDispatchProps {
  getDeviceCollection: () => Promise<undefined | SnackbarError[]>;
  changePage: (page: string) => Promise<undefined | SnackbarError[]>;
  deviceDeleteConfirmDialog: (deviceInfo: ITableDeleteCell) => void;
  deleteDevice: (
    deviceInfo: ITableDeleteCell
  ) => Promise<number | SnackbarError>;
  deviceSearchBySerialSetValue: (searchInput: string) => void;
}

type DevicesType = IDevicesStateProps &
  IDevicesDispatchProps &
  WithSnackbarProps;

export const StyledPaper = styled(Paper)`
  && {
    margin-left: 5%;
    margin-right: 5%;
    padding: 3px 6px;
    display: flex;
    alignitems: center;
    height: 40px;
  }
`;
StyledPaper.displayName = "StyledPaper";

export const StyledIconButton = styled(IconButton)`
  && {
    width: 35px;
    height: 35px;
  }
`;
StyledIconButton.displayName = "StyledIconButton";

export const StyledInput = styled(InputBase)`
  && {
    width: 100%;
  }
`;
StyledInput.displayName = "StyledInput";

export const StyledSearchIcon = styled(SearchIcon)`
  && {
    position: absolute;
  }
`;
StyledSearchIcon.displayName = "StyledSearchIcon";

export class Devices extends React.Component<DevicesType> {
  public checkCollectionErrors = (response?: SnackbarError[]) => {
    const { enqueueSnackbar } = this.props;
    if (response && response.length > 0) {
      response.forEach(response => {
        if (isSnackbarError(response)) {
          enqueueSnackbar(response.message, response.options);
        }
      });
    }
  };

  public fetchDevices = async (page?: string) => {
    const { changePage, getDeviceCollection } = this.props;
    let collection: IDeviceCollection | SnackbarError[] | undefined;
    if (page) {
      collection = await changePage(page);
    } else {
      collection = await getDeviceCollection();
    }
    if (collection) {
      this.checkCollectionErrors(collection);
    }
  };

  public changePage = (page: string) => {
    this.fetchDevices(page);
  };

  public async componentDidMount() {
    this.fetchDevices();
  }

  public handleDeviceDeleteDialogOpen = (deviceInfo: ITableDeleteCell) => (
    _: React.MouseEvent
  ) => {
    const { deviceDeleteConfirmDialog } = this.props;
    deviceDeleteConfirmDialog(deviceInfo);
  };

  public handleDeviceDeleteDialogClose = (deviceInfo: ITableDeleteCell) => {
    const { deviceDeleteConfirmDialog } = this.props;
    deviceDeleteConfirmDialog({ ...deviceInfo, visible: false });
  };

  public deleteDevice = async (deviceInfo: ITableDeleteCell) => {
    const { deleteDevice, enqueueSnackbar } = this.props;
    const deleteDeviceResult = await deleteDevice(deviceInfo);
    if (deleteDeviceResult === 204) {
      enqueueSnackbar(
        alertMessages.DEVICE_DELETE_SUCCESS,
        getStyledSnackbarOptions("success")
      );
    }
    if (isSnackbarError(deleteDeviceResult)) {
      enqueueSnackbar(deleteDeviceResult.message, deleteDeviceResult.options);
    }
  };

  public handleDeviceSearchBySerialSetValue = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { deviceSearchBySerialSetValue } = this.props;
    deviceSearchBySerialSetValue(event.target.value);
  };

  public handleDeviceSearchBySerialSubmit = async (
    event: React.FormEvent | React.MouseEvent
  ) => {
    event.preventDefault();
    const { getDeviceCollection } = this.props;

    const getDeviceCollectionResult = await getDeviceCollection();
    this.checkCollectionErrors(getDeviceCollectionResult);
  };

  public render() {
    const {
      deviceCollection: {
        members,
        totalItems,
        isLoading,
        searchBySerial,
        view
      },
      profile: {
        activeSubscription: { name }
      },
      device: { deviceDelete, isLoading: deviceIsLoading }
    } = this.props;

    return (
      <React.Fragment>
        <StyledFormWrapper>
          <Grid container={true}>
            <Grid item={true} xs={5} sm={8} md={8}>
              <StyledTitle margintop="8px">
                Devices of {name ? '"' + name + '"' : "..."}
              </StyledTitle>
            </Grid>
            <Grid item={true} xs={3} sm={1} md={2} />
            <Grid item={true} xs={4} sm={3} md={2}>
              <StyledTitle textalign="right">
                <NoStyleLink to="/devices/create">
                  <Button color="default">
                    <AddIcon />
                    Create
                  </Button>
                </NoStyleLink>
              </StyledTitle>
            </Grid>
          </Grid>

          <NewStyledPaper>
            <br />
            <Grid container={true}>
              <Grid item={true} xs={12} sm={6} md={3}>
                <form onSubmit={this.handleDeviceSearchBySerialSubmit}>
                  <StyledPaper>
                    <StyledInput
                      type="search"
                      placeholder="Search By Serial"
                      onChange={this.handleDeviceSearchBySerialSetValue}
                      value={searchBySerial}
                    />
                    <StyledIconButton
                      onClick={this.handleDeviceSearchBySerialSubmit}>
                      <StyledSearchIcon />
                    </StyledIconButton>
                  </StyledPaper>
                </form>
              </Grid>
            </Grid>
            <br />
            <Hidden smDown={true}>
              <DeviceTable
                isLoading={isLoading}
                pagination={view}
                rows={members}
                totalItems={totalItems}
                onChangePage={this.changePage}
                handleDialogOpen={this.handleDeviceDeleteDialogOpen}
              />
            </Hidden>
            <Hidden mdUp={true}>
              <MobileTable
                rows={devicesToMobileTableRow(members)}
                pagination={view}
                totalItems={totalItems}
                onChangePage={this.changePage}
                handleDialogOpen={this.handleDeviceDeleteDialogOpen}
                isLoading={isLoading}
              />
            </Hidden>
            {isLoading && (
              <React.Fragment>
                <br /> <LinearProgress /> <br />
              </React.Fragment>
            )}
          </NewStyledPaper>
        </StyledFormWrapper>
        <br />
        <br />
        <DeviceDeleteDialog
          deviceInfo={deviceDelete}
          handleDeleteDialogClose={this.handleDeviceDeleteDialogClose}
          deleteDevice={this.deleteDevice}
          isLoading={deviceIsLoading}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IRootState): IDevicesStateProps => ({
  deviceCollection: state.deviceCollection,
  profile: state.userProfileReducer,
  device: state.device
});

const mapDispatchToProps = (dispatch: any): IDevicesDispatchProps => {
  return {
    getDeviceCollection: (): Promise<undefined | SnackbarError[]> => {
      return dispatch(getDeviceCollection());
    },
    changePage: (page: string): Promise<undefined | SnackbarError[]> => {
      return dispatch(changeDeviceCollectionPage(page));
    },
    deviceDeleteConfirmDialog: (deviceInfo: ITableDeleteCell) => {
      return dispatch(deviceDeleteConfirmDialog(deviceInfo));
    },
    deleteDevice: (
      deviceInfo: ITableDeleteCell
    ): Promise<number | SnackbarError> => {
      return dispatch(deleteDevice(deviceInfo));
    },
    deviceSearchBySerialSetValue: (searchInput: string) => {
      return dispatch(deviceSearchBySerialSetValue(searchInput));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withThemeProvider(withSnackbar(Devices)));
