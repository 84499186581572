import axios, { AxiosResponse } from "axios";

import { ActionType } from "../../models/Action.model";
import { IPolicy, IPolicyApi, initialPolicy } from "../models/IPolicy";
import { ITableDeleteCell } from "../../models/shared/IShared";
import {
  Configuration,
  eramaApiHeaders,
  esmaApiHeaders,
  policiesPrefixURL
} from "../../config";
import { Thunk } from "../../store";
import { policyReducerTypes } from "../reducers/policyReducer";
import { policyFromApi, policyToApi } from "../services/PolicyService";
import { createBlockableDispatch } from "../../core/utilities/ServiceUtilities";
import {
  subscriptionCollectionFromApi,
  subscriptionFromApi
} from "../../Subscriptions/services/SubscriptionService";
import { checkError, SnackbarError } from "../../core/utilities/AlertUtilities";
import { getPolicyCollection } from "./policyCollectionAction";

export const getPolicyById: Thunk<policyReducerTypes> = (
  id: string,
  initializedPolicy?: IPolicy
) => {
  return async (dispatch, _, opt): Promise<IPolicy | SnackbarError> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );
    dispatch({
      type: ActionType.POLICY_LOADING,
      payload: true
    });
    try {
      const response: AxiosResponse<IPolicyApi> = await axios.get(
        Configuration.EramaAPIBaseUrl + policiesPrefixURL + id,
        eramaApiHeaders
      );

      const policy = policyFromApi(response.data, initializedPolicy);

      if (policy.subscription.name === "") {
        try {
          const responseSubById = await axios.get(
            Configuration.EsmaAPIBaseUrl + policy.subscription.id,
            esmaApiHeaders
          );

          policy.subscription = subscriptionFromApi(responseSubById.data);
        } catch (err) {
          return checkError(err);
        }
      }

      blockableDispatch({
        type: ActionType.GET_POLICY,
        payload: policy
      });
      return policy;
    } catch (err) {
      return checkError(err);
    } finally {
      dispatch({
        type: ActionType.POLICY_LOADING,
        payload: false
      });
    }
  };
};

export const initializePolicyEdit: Thunk<policyReducerTypes> = (
  setLoadingState: boolean
) => {
  return async (dispatch, _, opt): Promise<IPolicy | SnackbarError> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );
    dispatch({
      type: ActionType.CLEAR_POLICY
    });
    dispatch({
      type: ActionType.POLICY_LOADING,
      payload: true
    });
    try {
      const response = await axios.get(
        Configuration.EsmaAPIUrl + "/subscriptions",
        esmaApiHeaders
      );

      const policy = { ...initialPolicy };
      policy.subscriptionList = subscriptionCollectionFromApi(
        response.data
      ).members;
      blockableDispatch({
        type: ActionType.INITIALIZE_POLICY_EDIT,
        payload: policy
      });
      return policy;
    } catch (err) {
      return checkError(err);
    } finally {
      if (setLoadingState) {
        dispatch({
          type: ActionType.POLICY_LOADING,
          payload: false
        });
      }
    }
  };
};

export const updatePolicyById: Thunk<policyReducerTypes> = (
  id: string,
  policy: IPolicy
) => {
  return async (dispatch): Promise<IPolicy | SnackbarError> => {
    dispatch({
      type: ActionType.SET_POLICY_FORM_VALUE,
      payload: policy
    });
    dispatch({
      type: ActionType.POLICY_LOADING,
      payload: true
    });
    try {
      const response: AxiosResponse<IPolicyApi> = await axios.put(
        Configuration.EramaAPIBaseUrl + id,
        policyToApi(policy),
        eramaApiHeaders
      );

      const updatedPolicy = policyFromApi(response.data, policy);

      if (updatedPolicy.subscription.name === "") {
        try {
          const responseSubById = await axios.get(
            Configuration.EsmaAPIBaseUrl + updatedPolicy.subscription.id,
            esmaApiHeaders
          );

          updatedPolicy.subscription = subscriptionFromApi(
            responseSubById.data
          );
        } catch (err) {
          return checkError(err);
        }
      }

      dispatch({
        type: ActionType.UPDATE_POLICY,
        payload: updatedPolicy
      });
      return updatedPolicy;
    } catch (err) {
      return checkError(err);
    } finally {
      dispatch({
        type: ActionType.POLICY_LOADING,
        payload: false
      });
    }
  };
};

export const createPolicy: Thunk<policyReducerTypes> = (policy: IPolicy) => {
  return async (dispatch): Promise<IPolicy | SnackbarError> => {
    dispatch({
      type: ActionType.SET_POLICY_FORM_VALUE,
      payload: policy
    });
    dispatch({
      type: ActionType.POLICY_LOADING,
      payload: true
    });
    try {
      const response: AxiosResponse<IPolicyApi> = await axios.post(
        Configuration.EramaAPIUrl + "/policies",
        policyToApi(policy),
        eramaApiHeaders
      );
      dispatch({
        type: ActionType.CREATE_POLICY,
        payload: policyFromApi(response.data, policy)
      });
      return policyFromApi(response.data);
    } catch (err) {
      return checkError(err);
    } finally {
      dispatch({
        type: ActionType.POLICY_LOADING,
        payload: false
      });
    }
  };
};

export const deletePolicy: Thunk<policyReducerTypes> = (
  policyInfo: ITableDeleteCell
) => {
  return async (dispatch, getState): Promise<number | SnackbarError> => {
    dispatch({
      type: ActionType.POLICY_LOADING,
      payload: true
    });
    try {
      const response = await axios.delete(
        Configuration.EramaAPIBaseUrl + policyInfo.id,
        eramaApiHeaders
      );

      dispatch({
        type: ActionType.DELETE_POLICY_DIALOG,
        payload: { ...policyInfo, visible: false }
      });

      const {
        policyCollection: {
          policySubscriptionSelection: { value }
        },
        globalState: { masterAdmin }
      } = getState();

      dispatch(getPolicyCollection(value, masterAdmin));

      return response.status;
    } catch (err) {
      return checkError(err);
    } finally {
      dispatch({
        type: ActionType.POLICY_LOADING,
        payload: false
      });
    }
  };
};

export const clearPolicy: Thunk<policyReducerTypes> = () => {
  return async dispatch => {
    dispatch({ type: ActionType.CLEAR_POLICY });
  };
};

export const policyDeleteConfirmDialog: Thunk<policyReducerTypes> = (
  policyInfo: ITableDeleteCell
) => {
  return async dispatch => {
    dispatch({ type: ActionType.DELETE_POLICY_DIALOG, payload: policyInfo });
  };
};
