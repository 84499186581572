import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import {
  Paper,
  Toolbar,
  Grid,
  InputLabel,
  TablePagination,
  Table,
  TableCell,
  List,
  Card,
  Typography,
  IconButton,
  DialogContentText,
  DialogTitle,
  LinearProgress
} from "@material-ui/core";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Build as BuildIcon
} from "@material-ui/icons";

import { theme } from "../util/withThemeProvider";

export const StyledToolbarIndent = styled(Toolbar)`
  visibility: hidden;
  pointer-events: none;
`;
StyledToolbarIndent.displayName = "StyledToolbarIndent";

export const NoStyleLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  font-size: inherit;
`;
NoStyleLink.displayName = "NoStyleLink";

interface IStyledBackground {
  padding?: string;
  overflow?: string;
}
export const StyledBackground = styled(Paper)<IStyledBackground>`
  width: 100%;
  padding: ${props => (props.padding ? props.padding : "25px 20px")};
  margin-bottom: 45px;
  overflow: ${props => (props.overflow ? props.overflow : "")};
`;
StyledBackground.displayName = "StyledBackground";

interface IStyledTitle {
  margintop?: string;
  marginbottom?: string;
  textalign?: "right" | "left" | "none";
  headerProps?: boolean;
}

export const StyledTitle = styled(Grid)<IStyledTitle>`
  &&& {
    color: #6f6f6f;
    padding: 25px 10px 5px;
    font-size: 15px;
    text-transform: uppercase;
    margin-top: ${props => (props.margintop ? props.margintop : "")};
    margin-bottom: ${props => (props.marginbottom ? props.marginbottom : "")};
    text-align: ${props => props.textalign};
  }
`;
StyledTitle.displayName = "StyledTitle";

export const StyledPageTitle = styled(Grid)`
  &&& {
    color: #6f6f6f;
    padding: 10px 5px 20px;
    margin-left: 2%;
    font-size: 15px;
    text-transform: uppercase;
  }
`;
StyledPageTitle.displayName = "StyledPageTitle";

export const StyledFormWrapper = styled.div`
  margin-right: 30px;
  margin-left: 30px;
`;
StyledFormWrapper.displayName = "StyledFormWrapper";

export const StyledAutocompleteSelectLabel = styled(InputLabel)`
  && {
    font-size: small;
  }
`;
StyledAutocompleteSelectLabel.displayName = "StyledAutocompleteSelectLabel";

export const StyledInputLabel = styled(InputLabel)`
  && {
    font-size: small;
  }
`;
StyledInputLabel.displayName = "StyledInputLabel";

export const StyledTableCell = styled(TableCell)`
  && {
    font-size: 0.85rem;
    padding-right: 10px;
  }
`;
StyledTableCell.displayName = "StyledTableCell";

export const ButtonTableCell = styled(TableCell)`
  && {
    text-align: center;
    max-width: 150px;
    margin-right: 20px;
    padding: 5px 6px 5px 14px;
    vertical-align: top;
  }
`;
ButtonTableCell.displayName = "ButtonTableCell";

export const StyledPaper = styled(Paper)`
  width: 100%;
  overflow-x: auto;
  margin-bottom: 20px;
`;
StyledPaper.displayName = "StyledPaper";

export const StyledTable = styled(Table)`
  min-width: 700px;
`;
StyledTable.displayName = "StyledTable";

export const SmallIcon = styled(EditIcon)`
  && {
    font-size: 18px;
    margin-left: 5px;
  }
`;
SmallIcon.displayName = "SmallIcon";

export const TablePaginationToLeft = styled(TablePagination)`
  && {
    display: flex;
    align-items: start;
  }
`;
TablePaginationToLeft.displayName = "TablePaginationToLeft";

export const StyledPayloadFormatsCard = styled(Card)`
  && {
    border: 1px solid blue;
    border-radius: 0px;
  }
`;
StyledPayloadFormatsCard.displayName = "StyledPayloadFormatsCard";

export const NewStyledPaper = styled(StyledPaper)`
  && {
    padding-top: 25px;
    overflow-x: unset;
  }
`;
NewStyledPaper.displayName = "NewStyledPaper";

export const SmallDeleteIcon = styled(DeleteIcon)`
  && {
    font-size: 20px;
    margin-left: 5px;
  }
`;
SmallDeleteIcon.displayName = "SmallDeleteIcon";

export const SmallBuildIcon = styled(BuildIcon)`
  && {
    font-size: 18px;
    margin-left: 5px;
  }
`;
SmallBuildIcon.displayName = "SmallBuildIcon";

export const StyledListIcon = styled(List)`
  && {
    margin-right: ${theme.spacing()}px;
  }
`;
StyledListIcon.displayName = "StyledListIcon";

interface IActionButton {
  visibility?: "hidden" | "visible";
}
export const ActionButton = styled(IconButton)<IActionButton>`
  && {
    height: 48px;
    color: #d3d3d3;
    visibility: ${props => props.visibility || "visible"};
  }
`;
ActionButton.displayName = "ActionButton";

export const ParameterFieldContainer = styled(Grid)`
  padding: 0 5px;
`;
ParameterFieldContainer.displayName = "ParameterFieldContainer";

export const PulseIn = keyframes`
  0% {
    transform: scale(0.7, 0.7);
    opacity: 0;
    filter: alpha(opacity=0);
  }
  100% {
    opacity: 1;
    filter: none;
  }
`;

interface IAnimatedGrid {
  width?: string;
}

export const AnimatedGrid = styled(Grid)<IAnimatedGrid>`
  animation-name: ${PulseIn};
  animation-duration: 0.3s;
  ${props => (props.width ? `max-width: ${props.width}` : "")}
`;
AnimatedGrid.displayName = "AnimatedGrid";

export const ItemValuesMobileViewGrid = styled(Grid)`
  && {
    text-align: right;
  }
  && > div {
    padding: 0px;
  }
`;
ItemValuesMobileViewGrid.displayName = "ItemValuesMobileViewGrid";

interface IMobileViewInnerGrid {
  variant: "title" | "property";
}

export const MobileViewInnerGrid = styled(Grid)<IMobileViewInnerGrid>`
  && {
    padding: ${props => (props.variant === "title" ? "5px" : "8px")};
    ${props => (props.variant === "title" ? `background-color: #F5F5F5` : "")}
  }
`;
MobileViewInnerGrid.displayName = "MobileViewInnerGrid";

interface IMobileViewTypography {
  color?: string;
}

export const MobileViewTypography = styled(Typography)<IMobileViewTypography>`
  && {
    font-size: 0.95em;
    margin-top: 3px;
    ${props => (props.color ? `color: ${props.color}` : "")}
  }
`;
MobileViewTypography.displayName = "MobileViewTypography";

export const MobileViewWrapper = styled.div`
  && {
    padding: 8px;
  }
`;
MobileViewWrapper.displayName = "MobileViewWrapper";

export const StyledDialogLinearProgress = styled(LinearProgress)`
  && {
    margin: 15px;
  }
`;
StyledDialogLinearProgress.displayName = "StyledDialogLinearProgress";

export const StyledDialogTitle = styled(DialogTitle)`
  && > h2 {
    font-size: 1.05rem;
  }
`;
StyledDialogTitle.displayName = "StyledDialogTitle";

export const StyledDialogContentText = styled(DialogContentText)`
  && > p {
    font-size: 0.95rem;
  }
`;
StyledDialogContentText.displayName = "StyledDialogContentText";
