import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions
} from "@material-ui/core";

import { ITableDeleteCell } from "../../models/shared/IShared";
import {
  StyledDialogTitle,
  StyledDialogLinearProgress,
  StyledDialogContentText
} from "../../components/sharedStyledComponents";

interface IProps {
  deviceModelInfo: ITableDeleteCell;
  handleDeleteDialogClose: (deviceModelInfo: ITableDeleteCell) => void;
  deleteDeviceModel: (deviceModelInfo: ITableDeleteCell) => void;
  isLoading: boolean;
}

export const DeviceModelDeleteDialog: React.FunctionComponent<IProps> = ({
  deviceModelInfo,
  handleDeleteDialogClose,
  deleteDeviceModel,
  isLoading
}: IProps) => {
  const DeleteDialogClose = () => {
    handleDeleteDialogClose(deviceModelInfo);
  };

  const DeleteDeviceModel = () => {
    deleteDeviceModel(deviceModelInfo);
  };

  return (
    <Dialog
      open={deviceModelInfo.visible}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <StyledDialogTitle id="alert-dialog-title">
        Are you sure you want to delete this device model?
      </StyledDialogTitle>
      <DialogContent>
        <StyledDialogContentText id="alert-dialog-description">
          {deviceModelInfo.title}
        </StyledDialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={DeleteDialogClose}
          color="primary"
          variant="contained"
          disabled={isLoading}
          autoFocus>
          No
        </Button>
        <Button
          onClick={DeleteDeviceModel}
          color="primary"
          disabled={isLoading}>
          Yes
        </Button>
      </DialogActions>

      {isLoading && <StyledDialogLinearProgress />}
    </Dialog>
  );
};
