import React from "react";
import { connect } from "react-redux";
import { Grid, Hidden, Button, LinearProgress } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { withSnackbar, WithSnackbarProps } from "notistack";

import { IRootState } from "../store";
import { withThemeProvider } from "../util/withThemeProvider";
import { MobileTable } from "../components/MobileTable/MobileTable";
import { DeviceModelTable } from "./components/DeviceModelTable";
import { IDeviceModelCollection, IDeviceModel } from "./models/IDeviceModel";
import { ITableDeleteCell } from "../models/shared/IShared";
import {
  getDeviceModelCollection,
  changeDeviceModelCollectionPage
} from "./actions/deviceModelCollectionAction";
import {
  NoStyleLink,
  StyledTitle,
  StyledFormWrapper,
  NewStyledPaper
} from "../components/sharedStyledComponents";
import {
  alertMessages,
  SnackbarError,
  isSnackbarError,
  getStyledSnackbarOptions
} from "../core/utilities/AlertUtilities";
import {
  deleteDeviceModel,
  deviceModelDeleteConfirmDialog
} from "./actions/deviceModelAction";
import { DeviceModelDeleteDialog } from "./components/DeviceModelDeleteDialog";
import { deviceModelsToMobileTableRow } from "./services/DeviceModelsService";

interface IDeviceModelsStateProps {
  deviceModelCollection: IDeviceModelCollection;
  deviceModel: IDeviceModel;
}

interface IDeviceModelsDispatchProps {
  getDeviceModelCollection: () => Promise<
    IDeviceModelCollection | SnackbarError
  >;
  changePage: (page: string) => Promise<IDeviceModelCollection | SnackbarError>;
  deviceModelDeleteConfirmDialog: (deviceModelInfo: ITableDeleteCell) => void;
  deleteDeviceModel: (
    deviceModelInfo: ITableDeleteCell
  ) => Promise<number | SnackbarError>;
}

type DeviceModelsType = IDeviceModelsStateProps &
  IDeviceModelsDispatchProps &
  WithSnackbarProps;

export class DeviceModels extends React.Component<DeviceModelsType> {
  public fetchDeviceModels = async (page?: string) => {
    const {
      changePage,
      getDeviceModelCollection,
      enqueueSnackbar
    } = this.props;
    let collection: IDeviceModelCollection | SnackbarError | undefined;
    if (page) {
      collection = await changePage(page);
    } else {
      collection = await getDeviceModelCollection();
    }
    if (collection && isSnackbarError(collection)) {
      enqueueSnackbar(collection.message, collection.options);
    }
  };

  public changePage = (page: string) => {
    this.fetchDeviceModels(page);
  };

  public async componentDidMount() {
    this.fetchDeviceModels();
  }

  public handleDeviceModelDeleteDialogOpen = (
    deviceModelInfo: ITableDeleteCell
  ) => (_: React.MouseEvent) => {
    const { deviceModelDeleteConfirmDialog } = this.props;
    deviceModelDeleteConfirmDialog(deviceModelInfo);
  };

  public handleDeviceModelDeleteDialogClose = (
    deviceModelInfo: ITableDeleteCell
  ) => {
    const { deviceModelDeleteConfirmDialog } = this.props;
    deviceModelDeleteConfirmDialog({ ...deviceModelInfo, visible: false });
  };

  public deleteDeviceModel = async (deviceModelInfo: ITableDeleteCell) => {
    const { deleteDeviceModel, enqueueSnackbar } = this.props;
    const deleteDeviceModelResult = await deleteDeviceModel(deviceModelInfo);
    if (deleteDeviceModelResult === 204) {
      enqueueSnackbar(
        alertMessages.DEVICE_MODEL_DELETE_SUCCESS,
        getStyledSnackbarOptions("success")
      );
    }
    if (isSnackbarError(deleteDeviceModelResult)) {
      enqueueSnackbar(
        deleteDeviceModelResult.message,
        deleteDeviceModelResult.options
      );
    }
  };

  public render() {
    const {
      deviceModelCollection: { members, view, totalItems, isLoading },
      deviceModel: { deviceModelDelete, isLoading: deviceModelIsLoading }
    } = this.props;

    return (
      <React.Fragment>
        <StyledFormWrapper>
          <Grid container={true}>
            <Grid item={true} xs={6} sm={4} md={3}>
              <StyledTitle margintop="8px">Device Models</StyledTitle>
            </Grid>
            <Grid item={true} xs={2} sm={5} md={7} />
            <Grid item={true} xs={4} sm={3} md={2}>
              <StyledTitle textalign="right">
                <NoStyleLink to="/deviceModels/create">
                  <Button color="default">
                    <AddIcon />
                    Create
                  </Button>
                </NoStyleLink>
              </StyledTitle>
            </Grid>
          </Grid>
          <NewStyledPaper>
            <Hidden smDown={true}>
              <DeviceModelTable
                pagination={view}
                rows={members}
                totalItems={totalItems}
                onChangePage={this.changePage}
                handleDialogOpen={this.handleDeviceModelDeleteDialogOpen}
                isLoading={isLoading}
              />
            </Hidden>
            <Hidden mdUp={true}>
              <MobileTable
                rows={deviceModelsToMobileTableRow(members)}
                pagination={view}
                totalItems={totalItems}
                onChangePage={this.changePage}
                handleDialogOpen={this.handleDeviceModelDeleteDialogOpen}
                isLoading={isLoading}
              />
            </Hidden>

            {isLoading && (
              <React.Fragment>
                <br /> <LinearProgress /> <br />
              </React.Fragment>
            )}
          </NewStyledPaper>
        </StyledFormWrapper>
        <br />
        <br />
        <DeviceModelDeleteDialog
          deviceModelInfo={deviceModelDelete}
          handleDeleteDialogClose={this.handleDeviceModelDeleteDialogClose}
          deleteDeviceModel={this.deleteDeviceModel}
          isLoading={deviceModelIsLoading}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IRootState): IDeviceModelsStateProps => ({
  deviceModelCollection: state.deviceModelCollection,
  deviceModel: state.deviceModel
});

const mapDispatchToProps = (dispatch: any): IDeviceModelsDispatchProps => {
  return {
    getDeviceModelCollection: (): Promise<
      IDeviceModelCollection | SnackbarError
    > => {
      return dispatch(getDeviceModelCollection());
    },
    changePage: (
      page: string
    ): Promise<IDeviceModelCollection | SnackbarError> => {
      return dispatch(changeDeviceModelCollectionPage(page));
    },
    deviceModelDeleteConfirmDialog: (deviceModelInfo: ITableDeleteCell) => {
      return dispatch(deviceModelDeleteConfirmDialog(deviceModelInfo));
    },
    deleteDeviceModel: (
      deviceModelInfo: ITableDeleteCell
    ): Promise<number | SnackbarError> => {
      return dispatch(deleteDeviceModel(deviceModelInfo));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withThemeProvider(withSnackbar(DeviceModels)));
