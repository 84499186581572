import React from "react";
import { FieldArray, Field, Form, FormikProps } from "formik";
import { Button, LinearProgress, NoSsr, Grid } from "@material-ui/core";

import { StyledTextField, TextField } from "../../components/TextField";
import { Validators } from "../../util/validators";
import { IPolicy, initialPolicy } from "../models/IPolicy";
import {
  AutocompleteSelect,
  StyledAutocomplete
} from "../../components/AutocompleteSelect";
import { StyledAutocompleteSelectLabel } from "../../components/sharedStyledComponents";
import { PermissionsFormArray } from "./PolicyPermissions";
import { ISubscriptionSelection } from "../../models/shared/IShared";
import { initialSubscription } from "../../Subscriptions/models/ISubscription";

interface IEditPolicyProps {
  policySubscriptionSelection: ISubscriptionSelection;
}

type CreatePolicyFormType = FormikProps<IPolicy> & IEditPolicyProps;

export const CreatePolicyForm: React.FunctionComponent<
  CreatePolicyFormType
> = ({
  submitForm,
  isSubmitting,
  values,
  policySubscriptionSelection
}: CreatePolicyFormType) => {
  const { isLoading, subscriptionList } = values;
  const { name, subscription } = Validators.editPolicy;

  values.subscription = {
    ...initialSubscription,
    name: policySubscriptionSelection.label,
    id: policySubscriptionSelection.value
  };

  return (
    <Form>
      <StyledTextField
        type="text"
        label="Name"
        name="name"
        multiline={false}
        component={TextField}
        disabled={isSubmitting || isLoading}
        value={values.name}
        validate={name}
      />
      <br />
      <br />
      <NoSsr>
        {Boolean(values.subscription.id) && !isLoading && (
          <StyledAutocompleteSelectLabel htmlFor="subscription">
            Subscription
          </StyledAutocompleteSelectLabel>
        )}

        <StyledAutocomplete>
          <Field
            name="subscription"
            options={subscriptionList}
            component={AutocompleteSelect}
            isDisabled={true}
            validate={subscription}
            labelField="name"
            identityKey="id"
            placeholder="Select Subscription"
            isClearable={true}
            clearValue={initialPolicy}
          />
        </StyledAutocomplete>
      </NoSsr>

      <br />
      <FieldArray name="permissions" render={PermissionsFormArray} />

      <br />
      {(isSubmitting || isLoading) && <LinearProgress />}
      <br />
      <Grid
        container={true}
        item={true}
        direction="row"
        justify="flex-start"
        alignItems="center"
      >
        <Button
          variant="contained"
          color="primary"
          disabled={isSubmitting || isLoading}
          onClick={submitForm}
        >
          Create
        </Button>
      </Grid>
    </Form>
  );
};
