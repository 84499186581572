import {
  IApiResponseHeader,
  IDetermination,
  ILoadable
} from "../../core/models/MixinInterfaces";
import { IRole } from "../../Roles/models/IRole";
import {
  ISubscriptionSelection,
  ITableDeleteCell,
  ICollection
} from "../../models/shared/IShared";

export interface IInviteUserBySubscription {
  "@context": string;
  "@type": string;
  email: string;
}

export interface IAddUserBySubscription extends IInviteUserBySubscription {
  roles: string[];
}

export interface IUserBySubscriptionApi extends IApiResponseHeader {
  "@context": string;
  email: string;
  roles: string[];
}

export interface IUserBySubscriptionCollectionApi extends ICollection {
  "@context"?: string;
  "@type": string;
  member: IUserBySubscriptionApi[];
  totalItems: number;
  page?: number;
}

export interface IUserBySubscription extends IDetermination, ILoadable {
  context?: string;
  email: string;
  roles: IRole[];
  subscription: ISubscriptionSelection;
  inviteUserDialogOpen: boolean;
  inviteUserInitialValue: IInviteUserBySubscription;
  subscriptionList: ISubscriptionSelection[];
  roleList: IRole[];
  isLoadingSelection: boolean;
  userBySubscriptionDelete: ITableDeleteCell;
}

export interface IUserBySubscriptionCollection extends ILoadable, ICollection {
  context?: string;
  type: string;
  members: IUserBySubscription[];
  totalItems: number;
  page: number;
}

export const initialAddUserBySubscription: IAddUserBySubscription = {
  "@context": "https://schema.ellenex.com/v1/context.jsonld",
  "@type": "Member",
  email: "",
  roles: []
};

export const initialInviteUserBySubscription: IInviteUserBySubscription = {
  "@context": "https://schema.ellenex.com/v1/context.jsonld",
  "@type": "Invitation",
  email: ""
};

export const initialUserBySubscriptionCollection: IUserBySubscriptionCollection = {
  context: "https://schema.ellenex.com/v1/context.jsonld",
  type: "Member",
  isLoading: false,
  members: [],
  totalItems: 0,
  page: 0,
  view: {
    "@id": "",
    "@type": "",
    first: "",
    last: "",
    next: "",
    previous: ""
  }
};

export const initialUserBySubscription: IUserBySubscription = {
  context: "https://schema.ellenex.com/v1/context.jsonld",
  type: "Member",
  isLoading: false,
  id: "",
  shortId: "",
  email: "",
  roles: [],
  subscription: { value: "", label: "" },
  inviteUserDialogOpen: false,
  inviteUserInitialValue: { ...initialInviteUserBySubscription },
  subscriptionList: [],
  roleList: [],
  isLoadingSelection: false,
  userBySubscriptionDelete: {
    visible: false,
    id: "",
    title: ""
  }
};
