import React from "react";
import { RouteComponentProps } from "react-router";
import { NavLink, withRouter } from "react-router-dom";
import styled from "styled-components";
import {
  ListItemIcon,
  Drawer,
  ListItem,
  ListItemText,
  Toolbar,
  Hidden
} from "@material-ui/core";
import {
  Dashboard as DashboardIcon,
  Group as UsersIcon,
  CameraFront as SubscriptionIcon,
  DevicesOther as DeviceModelIcon,
  Dns as DeviceIcon,
  HowToReg as RolesIcon,
  PlaylistAddCheck as PoliciesIcon,
  Person as PersonIcon
} from "@material-ui/icons";

import { Configuration } from "../config";
import { theme } from "../util/withThemeProvider";

export const StyledLink = styled(NavLink)`
  text-decoration: none;
  color: ${theme.palette.text.primary};
  &&.active {
    background-color: ${Configuration.menuHoverColor};
    display: inherit;
  }
`;
StyledLink.displayName = "StyledLink";

export const StyledToolbar = styled(Toolbar)`
  && {
    width: ${Configuration.menuWidth}px;
    margin-bottom: ${theme.spacing()}px;
  }
`;
StyledToolbar.displayName = "StyledToolbar";

export const StyledDrawer = styled(Drawer)`
  && {
    z-index: ${theme.zIndex.appBar - 1};
  }
  && > * {
    :first-child {
      background: none;
    }
    :last-child {
      width: ${Configuration.menuWidth}px;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
`;
StyledDrawer.displayName = "StyledDrawer";

interface IProps {
  menuOpen: boolean;
  closeMenu: () => void;
  userId?: string;
}

export const MenuComponent: React.FunctionComponent<
  RouteComponentProps & IProps
> = (props: RouteComponentProps & IProps) => (
  <div>
    <Hidden smDown={true}>
      <StyledDrawer
        variant="persistent"
        open={props.menuOpen}
        onClose={props.closeMenu}
      >
        <StyledToolbar />
        <div>
          <StyledLink activeClassName="active" to="/" exact={true}>
            <ListItem button={true}>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
          </StyledLink>

          <StyledLink activeClassName="active" to="/subscriptions">
            <ListItem button={true}>
              <ListItemIcon>
                <SubscriptionIcon />
              </ListItemIcon>
              <ListItemText primary="Owned Subscriptions" />
            </ListItem>
          </StyledLink>

          <StyledLink activeClassName="active" to="/users">
            <ListItem button={true}>
              <ListItemIcon>
                <UsersIcon />
              </ListItemIcon>
              <ListItemText primary="User Access Management" />
            </ListItem>
          </StyledLink>

          <StyledLink activeClassName="active" to="/deviceModels">
            <ListItem button={true}>
              <ListItemIcon>
                <DeviceModelIcon />
              </ListItemIcon>
              <ListItemText primary="Device Models" />
            </ListItem>
          </StyledLink>

          <StyledLink activeClassName="active" to="/devices">
            <ListItem button={true}>
              <ListItemIcon>
                <DeviceIcon />
              </ListItemIcon>
              <ListItemText primary="Devices" />
            </ListItem>
          </StyledLink>

          <StyledLink activeClassName="active" to="/roles">
            <ListItem button={true}>
              <ListItemIcon>
                <RolesIcon />
              </ListItemIcon>
              <ListItemText primary="Roles" />
            </ListItem>
          </StyledLink>

          <StyledLink activeClassName="active" to="/policies">
            <ListItem button={true}>
              <ListItemIcon>
                <PoliciesIcon />
              </ListItemIcon>
              <ListItemText primary="Policies" />
            </ListItem>
          </StyledLink>

          <StyledLink
            activeClassName={props.userId ? "active" : ""}
            to={props.userId ? "/profile/" + props.userId : ""}
          >
            <ListItem button={true} disabled={props.userId ? false : true}>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary="My Profile" />
            </ListItem>
          </StyledLink>
        </div>
      </StyledDrawer>
    </Hidden>
    <Hidden mdUp={true}>
      <StyledDrawer
        variant="temporary"
        open={props.menuOpen}
        onClose={props.closeMenu}
      >
        <StyledToolbar />
        <div>
          <StyledLink
            activeClassName="active"
            to="/"
            onClick={props.closeMenu}
            exact={true}
          >
            <ListItem button={true}>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
          </StyledLink>

          <StyledLink
            activeClassName="active"
            to="/subscriptions"
            onClick={props.closeMenu}
          >
            <ListItem button={true}>
              <ListItemIcon>
                <SubscriptionIcon />
              </ListItemIcon>
              <ListItemText primary="Owned Subscriptions" />
            </ListItem>
          </StyledLink>

          <StyledLink
            activeClassName="active"
            to="/users"
            onClick={props.closeMenu}
          >
            <ListItem button={true}>
              <ListItemIcon>
                <UsersIcon />
              </ListItemIcon>
              <ListItemText primary="User Access Management" />
            </ListItem>
          </StyledLink>

          <StyledLink
            activeClassName="active"
            to="/deviceModels"
            onClick={props.closeMenu}
          >
            <ListItem button={true}>
              <ListItemIcon>
                <DeviceModelIcon />
              </ListItemIcon>
              <ListItemText primary="Device Models" />
            </ListItem>
          </StyledLink>

          <StyledLink
            activeClassName="active"
            to="/devices"
            onClick={props.closeMenu}
          >
            <ListItem button={true}>
              <ListItemIcon>
                <DeviceIcon />
              </ListItemIcon>
              <ListItemText primary="Devices" />
            </ListItem>
          </StyledLink>

          <StyledLink
            activeClassName="active"
            to="/roles"
            onClick={props.closeMenu}
          >
            <ListItem button={true}>
              <ListItemIcon>
                <RolesIcon />
              </ListItemIcon>
              <ListItemText primary="Roles" />
            </ListItem>
          </StyledLink>

          <StyledLink
            activeClassName="active"
            to="/policies"
            onClick={props.closeMenu}
          >
            <ListItem button={true}>
              <ListItemIcon>
                <PoliciesIcon />
              </ListItemIcon>
              <ListItemText primary="Policies" />
            </ListItem>
          </StyledLink>

          <StyledLink
            activeClassName={props.userId ? "active" : ""}
            to={props.userId ? "/profile/" + props.userId : ""}
            onClick={props.closeMenu}
          >
            <ListItem button={true} disabled={props.userId ? false : true}>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary="My Profile" />
            </ListItem>
          </StyledLink>
        </div>
      </StyledDrawer>
    </Hidden>
  </div>
);

export const Menu = withRouter(MenuComponent);
