import React from "react";
import moment from "moment";
import { Typography } from "@material-ui/core";
import { Configuration } from "../../../config";

export interface ICalibrationHistoryItemTitle {
  date?: string;
  isLoading: boolean;
}
export const CalibrationHistoryItemTitle: React.FunctionComponent<
  ICalibrationHistoryItemTitle
> = ({ isLoading, date }) =>
  isLoading ? (
    <Typography>Loading...</Typography>
  ) : (
    <Typography>
      Calibration at&nbsp;
      <b>
        {date
          ? moment(date).format(
              Configuration.calibration.historyTableDateFormat
            )
          : "Unknown"}
      </b>
    </Typography>
  );
CalibrationHistoryItemTitle.displayName = "CalibrationHistoryItemTitle";
