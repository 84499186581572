import React from "react";
import { FieldArray, Field, Form, FormikProps } from "formik";
import {
  Button,
  NoSsr,
  LinearProgress,
  Grid,
  CardContent,
  Typography
} from "@material-ui/core";
import { Help } from "@material-ui/icons";

import { IDevice } from "../models/IDevice";
import { Validators } from "../../util/validators";
import { StyledTextField, TextField } from "../../components/TextField";
import {
  AutocompleteSelect,
  StyledAutocomplete
} from "../../components/AutocompleteSelect";
import {
  DevicePayloadFieldParametersFormArray,
  DeviceBasicCalculationParametersFormArray,
  DevicePayloadFormatFormArray,
  DeviceValueParametersFormArray,
  DeviceDownlinkFormatFormArray
} from "./DeviceParameters";
import {
  StyledTitle,
  StyledPayloadFormatsCard,
  StyledAutocompleteSelectLabel
} from "../../components/sharedStyledComponents";
import { initialDevice } from "../models/IDevice";
import {
  ISubscription,
  initialSubscription
} from "../../Subscriptions/models/ISubscription";
import { initialDeviceModel } from "../../DeviceModels/models/IDeviceModel";

interface IEditDeviceProps {
  onManufacturerChange: (sub: ISubscription, deviceFormValue: IDevice) => void;
}

type EditDeviceFormType = FormikProps<IDevice> & IEditDeviceProps;

export const EditDeviceForm: React.FunctionComponent<EditDeviceFormType> = ({
  isSubmitting,
  values,
  submitForm,
  setFieldValue,
  onManufacturerChange
}: EditDeviceFormType) => {
  const {
    isLoading,
    deviceModelList,
    subscriptionList,
    manufacturersList
  } = values;
  const {
    name,
    note,
    deviceModel,
    subscription,
    manufacturer
  } = Validators.editDevice;

  const manufacturerHandleChange = (selectedManufacturer: ISubscription) => {
    if (selectedManufacturer === null) {
      setFieldValue("manufacturer", initialSubscription);
      setFieldValue("deviceModel", initialDeviceModel);
      setFieldValue("deviceModelList", []);
    } else {
      onManufacturerChange(selectedManufacturer, values);
    }
  };

  return (
    <Form>
      <StyledTextField
        type="text"
        label="Serial"
        name="serial"
        multiline={false}
        disabled={true}
        component={TextField}
      />

      <StyledTextField
        type="text"
        label="Transfer Code"
        name="transferCode"
        multiline={false}
        disabled={true}
        component={TextField}
      />

      <StyledTextField
        type="text"
        label="Name"
        name="name"
        multiline={false}
        disabled={isSubmitting || isLoading}
        component={TextField}
        validate={name}
      />
      <br />
      <StyledTextField
        type="text"
        label="Note"
        name="note"
        multiline={true}
        disabled={isSubmitting || isLoading}
        component={TextField}
        validate={note}
      />

      <br />
      <br />

      <NoSsr>
        {Boolean(values.manufacturer.id) && !isLoading && (
          <StyledAutocompleteSelectLabel htmlFor="manufacturer">
            Manufacturer
          </StyledAutocompleteSelectLabel>
        )}

        <StyledAutocomplete>
          <Field
            name="manufacturer"
            options={manufacturersList}
            component={AutocompleteSelect}
            isDisabled={isSubmitting || isLoading}
            validate={manufacturer}
            labelField="name"
            identityKey="id"
            placeholder="Select Manufacturer"
            isClearable={true}
            clearValue={initialDevice}
            onChange={manufacturerHandleChange}
          />
        </StyledAutocomplete>
      </NoSsr>

      <br />
      <br />
      <NoSsr>
        {Boolean(values.deviceModel.id) && !isLoading && (
          <StyledAutocompleteSelectLabel htmlFor="deviceModel">
            Model
          </StyledAutocompleteSelectLabel>
        )}
        <StyledAutocomplete>
          <Field
            name="deviceModel"
            options={deviceModelList}
            component={AutocompleteSelect}
            isDisabled={
              isSubmitting ||
              isLoading ||
              Boolean(values.deviceModelListIsLoading) === true
            }
            validate={deviceModel}
            labelField="name"
            identityKey="id"
            placeholder="Select Model"
            isClearable={true}
            clearValue={initialDevice}
          />
          {Boolean(values.deviceModelListIsLoading) === true && (
            <LinearProgress />
          )}
        </StyledAutocomplete>
      </NoSsr>
      <br />
      <br />
      <NoSsr>
        {Boolean(values.subscription.id) && !isLoading && (
          <StyledAutocompleteSelectLabel htmlFor="subscription">
            Subscription
          </StyledAutocompleteSelectLabel>
        )}
        <StyledAutocomplete>
          <Field
            name="subscription"
            options={subscriptionList}
            component={AutocompleteSelect}
            isDisabled={isSubmitting || isLoading}
            validate={subscription}
            labelField="name"
            identityKey="id"
            placeholder="Select Subscription"
            isClearable={true}
            clearValue={initialDevice}
          />
        </StyledAutocomplete>
      </NoSsr>

      <br />
      <br />

      <StyledTitle>DEVICE PARAMETERS</StyledTitle>
      <br />
      <Typography variant="body1">Payload Formats</Typography>
      <br />

      <Grid container>
        <Grid item xs={12} sm={10}>
          <StyledPayloadFormatsCard>
            <CardContent>
              <Grid container wrap="nowrap" spacing={8}>
                <Grid item>
                  <Help color="primary" />
                </Grid>
                <Grid item xs>
                  <Typography color="primary">
                    {`Payload format contains one or more payload parameters with format: <ParameterName>:<DataType>:[OptionalValue]`}
                    <br />
                    {`For example: “PayloadType:uint8:0 PressureRaw:int16: TemperatureRaw:int16: ChargeLevelRaw:uint16_le:”`}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </StyledPayloadFormatsCard>
        </Grid>
      </Grid>

      <br />
      <FieldArray name="payloadFormats" render={DevicePayloadFormatFormArray} />
      <br />
      <br />

      <Typography variant="body1">Downlink Formats</Typography>
      <br />
      <FieldArray
        name="downlinkFormats"
        render={DeviceDownlinkFormatFormArray}
      />
      <br />
      <br />

      <Typography variant="body1">Payload Parameters</Typography>
      <br />

      <FieldArray
        name="payloadFieldProperties"
        render={DevicePayloadFieldParametersFormArray}
      />

      <br />
      <br />

      <Typography variant="body1">Value Parameters</Typography>
      <br />
      <FieldArray
        name="valueParameters"
        render={DeviceValueParametersFormArray}
      />

      <br />
      <br />

      <Typography variant="body1">Calculated Parameters</Typography>
      <br />
      <FieldArray
        name="basicCalculationProperties"
        render={DeviceBasicCalculationParametersFormArray}
      />

      {(isSubmitting || isLoading) && <LinearProgress />}

      <br />
      <Grid
        container={true}
        item={true}
        direction="row"
        justify="flex-start"
        alignItems="center">
        <Button
          variant="contained"
          color="primary"
          disabled={isSubmitting || isLoading}
          onClick={submitForm}>
          Save
        </Button>
      </Grid>
    </Form>
  );
};
