import React, { FunctionComponent, Fragment } from "react";
import {
  FieldArray,
  Field,
  Form,
  FormikProps,
  FieldArrayRenderProps
} from "formik";
import {
  Button,
  LinearProgress,
  Grid,
  InputLabel,
  MenuItem,
  Typography
} from "@material-ui/core";
import {
  IDeviceCalibration,
  IDeviceCalibrationTable
} from "../../models/IDevice";
import { CalibrationFieldArray } from "./CalibrationFieldArray";
import { Select, StyledSelect } from "../../../components/Select";
import { StyledTextField, TextField } from "../../../components/TextField";
import { StyledBackground } from "../../../components/sharedStyledComponents";
import { Validators } from "../../../util/validators";
import { checkCalibrateButtonDisabled } from "../../services/DeviceService";

interface IDeviceCalibrationFormProps {
  getDeviceCalibrationHistory: (
    deviceId: string,
    parameter: string,
    currentCalibrationTable: IDeviceCalibrationTable
  ) => void;
  getDeviceCalibrationNewFormula: (
    currentCalibrationTable: IDeviceCalibrationTable
  ) => void;
  clearDeviceCalibrationCurrentTable: () => void;
  clearDeviceCalibrationHistory: () => void;
  updateDeviceValueForCalibrate: (
    rowIndex: number,
    item: IDeviceCalibration
  ) => void;
}

type DeviceCalibrationFormType = FormikProps<IDeviceCalibration> &
  IDeviceCalibrationFormProps;

export const CalibrationForm: FunctionComponent<DeviceCalibrationFormType> = ({
  isSubmitting,
  values,
  submitForm,
  setFieldValue,
  handleChange,
  getDeviceCalibrationHistory,
  getDeviceCalibrationNewFormula,
  clearDeviceCalibrationCurrentTable,
  clearDeviceCalibrationHistory,
  updateDeviceValueForCalibrate
}: DeviceCalibrationFormType) => {
  const {
    currentCalibrationTable: {
      parameters,
      parameter,
      calibrationVariant,
      deviceId,
      rows
    },
    isLoading,
    calibrationFormulaLoading,
    calibrationDeviceValueLoading
  } = values;

  const { newFormula, parameter: paramValidate } = Validators.deviceCalibration;

  const renderCalibrationFieldArray = (
    fieldArrayRenderProps: FieldArrayRenderProps
  ) => (
    <CalibrationFieldArray
      {...fieldArrayRenderProps}
      updateDeviceValueForCalibrate={updateDeviceValueForCalibrate}
    />
  );

  const onParameterSelect = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    handleChange(event);
    const selectedParameter = parameters
      .filter(parameter => parameter.name === event.target.value)
      .pop();
    if (selectedParameter) {
      setFieldValue(
        "currentCalibrationTable.parameterUnit",
        selectedParameter.unitText
      );
      setFieldValue(
        "currentCalibrationTable.parameterExpression",
        selectedParameter.method.expression
      );

      if (selectedParameter.name !== parameter) {
        clearDeviceCalibrationHistory();
        getDeviceCalibrationHistory(deviceId, selectedParameter.name, {
          ...values.currentCalibrationTable,
          parameter: selectedParameter.name,
          parameterExpression: selectedParameter.method.expression,
          parameterUnit: selectedParameter.unitText
        });
        clearDeviceCalibrationCurrentTable();
      }
    }
  };
  const calibrateDevice = () => {
    getDeviceCalibrationNewFormula(values.currentCalibrationTable);
  };
  return (
    <Form>
      <Typography variant="subtitle1">Select parameter to calibrate</Typography>
      <StyledSelect>
        <InputLabel htmlFor="currentCalibrationTable.parameter">
          Parameter
        </InputLabel>
        <Field
          name="currentCalibrationTable.parameter"
          label="Parameter"
          component={Select}
          onChange={onParameterSelect}
          disabled={
            isSubmitting ||
            isLoading ||
            calibrationDeviceValueLoading ||
            calibrationFormulaLoading
          }
          validate={paramValidate}>
          {parameters.map((parameter, index) => (
            <MenuItem key={index} value={parameter.name}>
              {parameter.name}
            </MenuItem>
          ))}
        </Field>
      </StyledSelect>
      {parameter && (
        <Fragment>
          <StyledTextField
            type="text"
            label="Unit"
            name="currentCalibrationTable.parameterUnit"
            disabled={true}
            component={TextField}
          />
          <StyledTextField
            type="text"
            label="Current formula"
            name="currentCalibrationTable.parameterExpression"
            disabled={true}
            component={TextField}
          />
        </Fragment>
      )}
      <br />
      <br />
      <StyledBackground>
        <FieldArray
          name="currentCalibrationTable.rows"
          render={renderCalibrationFieldArray}
        />
      </StyledBackground>
      <br />
      <StyledSelect>
        <InputLabel htmlFor="currentCalibrationTable.algorithm">
          Algorithm
        </InputLabel>
        <Field
          name="currentCalibrationTable.selectedCalibrationVariant"
          label="Algorithm"
          component={Select}
          onChange={onParameterSelect}
          disabled={
            isSubmitting ||
            isLoading ||
            checkCalibrateButtonDisabled(rows) ||
            calibrationDeviceValueLoading ||
            calibrationFormulaLoading
          }>
          {calibrationVariant.map((algorithm, index) => (
            <MenuItem key={index} value={algorithm}>
              {algorithm}
            </MenuItem>
          ))}
        </Field>
      </StyledSelect>
      <br />

      <Grid
        container={true}
        item={true}
        direction="row"
        justify="flex-start"
        alignItems="center">
        <Button
          variant="contained"
          color="primary"
          disabled={
            isSubmitting ||
            isLoading ||
            checkCalibrateButtonDisabled(rows) ||
            calibrationFormulaLoading ||
            calibrationDeviceValueLoading
          }
          onClick={calibrateDevice}>
          {calibrationFormulaLoading ? "Calibrating..." : "Calibrate"}
        </Button>
      </Grid>
      <br />
      <StyledTextField
        type="text"
        label="New formula"
        name="currentCalibrationTable.formula"
        disabled={
          isSubmitting ||
          isLoading ||
          checkCalibrateButtonDisabled(rows) ||
          calibrationDeviceValueLoading ||
          calibrationFormulaLoading
        }
        component={TextField}
        validate={
          !Boolean(parameter) || checkCalibrateButtonDisabled(rows)
            ? null
            : newFormula
        }
      />
      {(isSubmitting || isLoading) && <LinearProgress />}

      <br />
      <Grid
        container={true}
        item={true}
        direction="row"
        justify="flex-start"
        alignItems="center">
        <Button
          variant="contained"
          color="primary"
          disabled={
            isSubmitting ||
            isLoading ||
            checkCalibrateButtonDisabled(rows) ||
            calibrationDeviceValueLoading ||
            calibrationFormulaLoading
          }
          onClick={submitForm}>
          Save
        </Button>
      </Grid>
    </Form>
  );
};
CalibrationForm.displayName = "CalibrationForm";