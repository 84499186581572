import React from "react";
import { TableRow, Button } from "@material-ui/core";

import { IDeviceModel } from "../../models/IDeviceModel";
import { ITableDeleteCell } from "../../../models/shared/IShared";
import {
  NoStyleLink,
  ButtonTableCell,
  SmallIcon,
  SmallDeleteIcon,
  StyledTableCell
} from "../../../components/sharedStyledComponents";

interface IDeviceModelTableRowProps {
  deviceModel: IDeviceModel;
  handleDialogOpen: (
    deviceModelInfo: ITableDeleteCell
  ) => (_: React.MouseEvent) => void;
}

export const DeviceModelTableRow: React.FunctionComponent<
  IDeviceModelTableRowProps
> = ({
  deviceModel: {
    id,
    shortId,
    name,
    description,
    subscription: { name: subName }
  },
  handleDialogOpen
}: IDeviceModelTableRowProps) => (
  <TableRow key={id}>
    <StyledTableCell> {name} </StyledTableCell>
    <StyledTableCell> {description} </StyledTableCell>
    <StyledTableCell> {subName} </StyledTableCell>
    <ButtonTableCell>
      <NoStyleLink to={"/deviceModels/" + shortId}>
        <Button color="primary">
          Edit <SmallIcon />
        </Button>
      </NoStyleLink>
      <br />
      <Button
        color="primary"
        onClick={handleDialogOpen({
          visible: true,
          id: id,
          title: name
        })}>
        Delete <SmallDeleteIcon />
      </Button>
    </ButtonTableCell>
  </TableRow>
);
