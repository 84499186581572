import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions
} from "@material-ui/core";

import { ITableDeleteCell } from "../../models/shared/IShared";
import {
  StyledDialogTitle,
  StyledDialogLinearProgress,
  StyledDialogContentText
} from "../../components/sharedStyledComponents";

interface IProps {
  roleInfo: ITableDeleteCell;
  handleDeleteDialogClose: (roleInfo: ITableDeleteCell) => void;
  deleteRole: (roleInfo: ITableDeleteCell) => void;
  isLoading: boolean;
}

export const RoleDeleteDialog: React.FunctionComponent<IProps> = ({
  roleInfo,
  handleDeleteDialogClose,
  deleteRole,
  isLoading
}: IProps) => {
  const DeleteDialogClose = () => {
    handleDeleteDialogClose(roleInfo);
  };

  const DeleteRole = () => {
    deleteRole(roleInfo);
  };

  return (
    <Dialog
      open={roleInfo.visible}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <StyledDialogTitle id="alert-dialog-title">
        Are you sure you want to delete this role?
      </StyledDialogTitle>
      <DialogContent>
        <StyledDialogContentText id="alert-dialog-description">
          {roleInfo.title}
        </StyledDialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={DeleteDialogClose}
          color="primary"
          variant="contained"
          disabled={isLoading}
          autoFocus>
          No
        </Button>
        <Button onClick={DeleteRole} color="primary" disabled={isLoading}>
          Yes
        </Button>
      </DialogActions>

      {isLoading && <StyledDialogLinearProgress />}
    </Dialog>
  );
};
