import { IRoleCollection, initialRoleCollection } from "../models/IRole";
import { ActionType } from "../../models/Action.model";
import { ISubscriptionSelection } from "../../models/shared/IShared";

export type roleCollectionReducerTypes =
  | { type: ActionType.GET_ROLE_COLLECTION; payload: IRoleCollection }
  | { type: ActionType.ROLE_COLLECTION_LOADING; payload: boolean }
  | {
      type: ActionType.SET_SUBSCRIPTION_SELECTION;
      payload: ISubscriptionSelection;
    };

export function roleCollectionReducer(
  state: IRoleCollection = { ...initialRoleCollection },
  action: roleCollectionReducerTypes
): IRoleCollection {
  switch (action.type) {
    case ActionType.GET_ROLE_COLLECTION:
      state = {
        ...action.payload,
        page: 0
      };
      break;
    case ActionType.ROLE_COLLECTION_LOADING:
      state = {
        ...state,
        ...(action.payload && initialRoleCollection),
        isLoading: action.payload,
        subscriptionSelection: state.subscriptionSelection
      };
      break;
    case ActionType.SET_SUBSCRIPTION_SELECTION:
      state = {
        ...state,
        subscriptionSelection: action.payload,
        subscriptionSelectionList: state.subscriptionSelectionList
      };
      break;
  }
  return state;
}
