import React from "react";
import { List } from "@material-ui/core";

import {
  ITableDeleteCell,
  IMobileTableRow,
  IPaginationView
} from "../../models/shared/IShared";
import { MobileTableRow } from "./MobileTableRow";
import { Pagination } from "../Pagination";

interface IMobileTableProps {
  rows: IMobileTableRow[];
  onChangePage: (page: string) => void;
  handleDialogOpen?: (
    itemInfo: ITableDeleteCell
  ) => (_: React.MouseEvent) => void;
  isLoading: boolean;
  pagination: IPaginationView;
  totalItems?: number;
}

export const MobileTable: React.FunctionComponent<IMobileTableProps> = ({
  rows,
  onChangePage,
  handleDialogOpen,
  isLoading,
  totalItems,
  pagination
}: IMobileTableProps) => {
  return (
    <React.Fragment>
      {!isLoading && (
        <React.Fragment>
          <List>
            {rows.map((item: IMobileTableRow) => {
              return (
                <MobileTableRow
                  key={item.id}
                  item={item}
                  DeleteDialog={handleDialogOpen}
                />
              );
            })}
          </List>
          <Pagination
            totalItems={totalItems || 0}
            pagination={pagination}
            onChangePage={onChangePage}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
