import {
  IRole,
  IRoleApi,
  IRoleCollection,
  IRoleCollectionApi,
  initialRole,
  initialRoleCollection,
  IAppliedPolicies
} from "../models/IRole";
import { ServiceUtilities } from "../../core/utilities/ServiceUtilities";
import {
  ISubscriptionCollectionApi,
  initialSubscription
} from "../../Subscriptions/models/ISubscription";
import { subscriptionFromApi } from "../../Subscriptions/services/SubscriptionService";
import { checkOrder } from "../../util/OrderingService";
import { IMobileTableRow } from "../../models/shared/IShared";

export const roleFromApi = (role: IRoleApi, initializedRole?: IRole): IRole => {
  const result = initializedRole ? initializedRole : { ...initialRole };

  result.id = role["@id"];
  result.shortId = ServiceUtilities.getShortId(role["@id"]);
  result.type = role["@type"];
  result.name = role.name;
  result.isLoading = false;
  result.subscription = {
    ...initialSubscription,
    id: role.subscription
  };

  if (initializedRole) {
    result.subscriptionList = initializedRole.subscriptionList;
    result.policyList = initializedRole.policyList;

    result.appliedPolicies = [];
    role.appliedPolicies &&
      role.appliedPolicies.forEach(appliedPolicy => {
        initializedRole.policyList.forEach(policy => {
          if (appliedPolicy[`policy`] === policy.id) {
            result.appliedPolicies.push({
              ...policy,
              order: appliedPolicy[`order`]
            });
          }
        });
      });
    result.appliedPolicies = checkOrder(
      "order",
      result.appliedPolicies
    ) as IAppliedPolicies[];
    const subscription = initializedRole.subscriptionList
      .filter(subscription => subscription.id === role.subscription)
      .pop();

    if (subscription) {
      result.subscription = subscription;
    }
  }

  return result;
};

export const roleToApi = (role: IRole): IRoleApi => {
  return {
    "@id": role.id,
    "@type": role.type,
    name: role.name,
    subscription: role.subscription.id,
    appliedPolicies: role.appliedPolicies.map(
      (appliedPolicy: IAppliedPolicies, index: number) => {
        return {
          policy: appliedPolicy.id,
          order: index + 1
        };
      }
    )
  };
};

export const roleCollectionFromApi = (
  collection: IRoleCollectionApi
): IRoleCollection => {
  return {
    ...initialRoleCollection,
    context: collection["@context"],
    id: collection["@id"],
    isLoading: false,
    members: collection.member.map(apiRole => roleFromApi(apiRole)),
    shortId: ServiceUtilities.getShortId(collection["@id"]),
    totalItems: collection.totalItems,
    type: collection["@type"],
    view: collection.view
  };
};

export const addRoleSubscription = (
  role: IRole,
  subscriptions: ISubscriptionCollectionApi
): IRole => {
  const filteredSubscription = subscriptions.member
    .filter(subs => subs["@id"] === role.subscription.id)
    .pop();
  return {
    ...role,
    subscription: filteredSubscription
      ? subscriptionFromApi(filteredSubscription)
      : role.subscription
  };
};

export const addRoleCollectionSubscription = (
  collection: IRoleCollection,
  subscriptions: ISubscriptionCollectionApi
) => {
  return {
    ...collection,
    members: collection.members.map(model =>
      addRoleSubscription(model, subscriptions)
    )
  };
};

export const rolesToMobileTableRow = (members: IRole[]): IMobileTableRow[] => {
  const result = members.map(item => {
    return {
      id: item.id,
      title: item.name,
      fields: [{ label: "Subscription", value: item.subscription.name }],
      editPageLink: `/roles/${ServiceUtilities.getShortId(item.id)}`
    };
  });
  return result;
};
