import {
  createStore,
  combineReducers,
  applyMiddleware,
  Action,
  ActionCreator
} from "redux";
import thunk, { ThunkAction } from "redux-thunk";
import { History } from "history";

import { Configuration } from "./config";
import {
  IDevice,
  IDeviceCalibration,
  IDeviceCollection,
  IDeviceCalibrationHistory
} from "./Devices/models/IDevice";
import { unwrapAction } from "./core/middleware/unwrapAction.middleware";
import { logger } from "./core/middleware/logger.middleware";
import { blocker } from "./core/middleware/blocker.middleware";
import { history } from "./core/history";
import { deviceCollectionReducer } from "./Devices/reducers/deviceCollectionReducer";
import { IGlobalState } from "./models/GlobalState";
import {
  globalStateReducer,
  globalStateReducerTypes
} from "./reducers/globalStateReducer";
import { deviceReducer } from "./Devices/reducers/deviceReducer";
import {
  IDeviceModel,
  IDeviceModelCollection
} from "./DeviceModels/models/IDeviceModel";
import { deviceModelCollectionReducer } from "./DeviceModels/reducers/deviceModelCollectionReducer";
import { deviceModelReducer } from "./DeviceModels/reducers/deviceModelReducer";
import {
  ISubscription,
  ISubscriptionCollection
} from "./Subscriptions/models/ISubscription";
import { subscriptionCollectionReducer } from "./Subscriptions/reducers/subscriptionCollectionReducer";
import { subscriptionReducer } from "./Subscriptions/reducers/subscriptionReducer";
import { IUserCollection, IUser } from "./Users/models/IUser";
import { userCollectionReducer } from "./Users/reducers/userCollectionReducer";
import { userReducer } from "./Users/reducers/userReducer";
import { IUserProfile } from "./Profile/models/IUserProfile";
import { userProfileReducer } from "./Profile/reducers/userProfileReducer";
import { IRoleCollection, IRole } from "./Roles/models/IRole";
import { roleCollectionReducer } from "./Roles/reducers/roleCollectionReducer";
import { roleReducer } from "./Roles/reducers/roleReducer";
import { IPolicyCollection, IPolicy } from "./Policies/models/IPolicy";
import { policyCollectionReducer } from "./Policies/reducers/policyCollectionReducer";
import { policyReducer } from "./Policies/reducers/policyReducer";
import {
  IUserBySubscription,
  IUserBySubscriptionCollection
} from "./UsersBySubscription/models/IUserBySubscription";
import { userBySubscriptionReducer } from "./UsersBySubscription/reducers/userBySubscriptionReducer";
import { userBySubscriptionCollectionReducer } from "./UsersBySubscription/reducers/userBySubscriptionCollectionReducer";
import { deviceCalibrationReducer } from "./Devices/reducers/deviceCalibrationReducer";
import { deviceCalibrationHistoryReducer } from "./Devices/reducers/deviceCalibrationHistoryReducer";

export interface IRootState {
  deviceCollection: IDeviceCollection;
  globalState: IGlobalState;
  device: IDevice;
  deviceCalibration: IDeviceCalibration;
  deviceCalibrationHistory: IDeviceCalibrationHistory;
  deviceModel: IDeviceModel;
  deviceModelCollection: IDeviceModelCollection;
  subscription: ISubscription;
  subscriptionCollection: ISubscriptionCollection;
  userCollection: IUserCollection;
  userReducer: IUser;
  userProfileReducer: IUserProfile;
  roleCollection: IRoleCollection;
  role: IRole;
  policyCollection: IPolicyCollection;
  policy: IPolicy;
  userBySubscription: IUserBySubscription;
  userBySubscriptionCollection: IUserBySubscriptionCollection;
}

const rootReducer = combineReducers<IRootState>({
  deviceCollection: deviceCollectionReducer,
  globalState: globalStateReducer,
  device: deviceReducer,
  deviceCalibration: deviceCalibrationReducer,
  deviceCalibrationHistory: deviceCalibrationHistoryReducer,
  deviceModelCollection: deviceModelCollectionReducer,
  deviceModel: deviceModelReducer,
  subscriptionCollection: subscriptionCollectionReducer,
  subscription: subscriptionReducer,
  userCollection: userCollectionReducer,
  userReducer,
  userProfileReducer,
  roleCollection: roleCollectionReducer,
  role: roleReducer,
  policyCollection: policyCollectionReducer,
  policy: policyReducer,
  userBySubscription: userBySubscriptionReducer,
  userBySubscriptionCollection: userBySubscriptionCollectionReducer
});

export const store = createStore(
  rootReducer,
  {},
  Configuration.development
    ? applyMiddleware(
        thunk.withExtraArgument({ history }),
        blocker,
        unwrapAction,
        logger
      )
    : applyMiddleware(
        thunk.withExtraArgument({ history }),
        blocker,
        unwrapAction
      )
);

export type Thunk<T extends Action<any>> = ActionCreator<
  ThunkAction<
    void,
    IRootState,
    { history: History },
    T | globalStateReducerTypes
  >
>;
