import axios, { AxiosResponse } from "axios";
import JwtDecode from "jwt-decode";

import { ActionType } from "../models/Action.model";
import { ICurrentUser } from "../models/GlobalState";
import {
  Configuration,
  authApiHeaders,
  esmaApiHeaders,
  rolesPrefixURL,
  subscriptionsPrefixURL
} from "../config";
import { history } from "../core/history";
import { globalStateReducerTypes } from "../reducers/globalStateReducer";
import { Thunk } from "../store";
import { checkError, SnackbarError } from "../core/utilities/AlertUtilities";

export const openMenuAction = (
  currentState: boolean
): globalStateReducerTypes => {
  return { type: ActionType.OPEN_MENU_CHANGE, payload: !currentState };
};

export const updateClient = (): globalStateReducerTypes => {
  sessionStorage.removeItem("cachedIndexHTML");
  window.location.reload(true);
  return { type: ActionType.CLIENT_UPDATE };
};

export const logIn: Thunk<globalStateReducerTypes> = (query: string) => {
  return async (dispatch): Promise<ICurrentUser | SnackbarError> => {
    if (query) {
      const code = query
        .substring(1)
        .split("&")
        .map(queryParameter => queryParameter.split("="))
        .filter(parameterDict => parameterDict[0] === "code");
      if (code.length === 1) {
        try {
          const response: AxiosResponse<{ token: string }> = await axios.post(
            Configuration.AuthApiUrl +
              `?${"code=" +
                code[0].pop() +
                "&client_id=" +
                Configuration.auth.clientId +
                "&redirect_uri=" +
                Configuration.auth.redirectUri}`,
            null,
            authApiHeaders
          );
          localStorage.setItem("authToken", response.data.token);
          history.replace("/");
        } catch (err) {
          dispatch({ type: ActionType.LOGIN_ERROR, payload: true });
          return checkError(err);
        }
      }
    }

    const token = localStorage.getItem("authToken");
    if (token) {
      axios.defaults.headers.common["x-lnx-token"] = token;
      try {
        const user: ICurrentUser = JwtDecode(token);
        dispatch({ type: ActionType.AUTHENTICATION, payload: user });
        return user;
      } catch (_) {
        logOut();
      }
    } else {
      logOut();
    }
  };
};

export const logOut = (): globalStateReducerTypes => {
  localStorage.removeItem("authToken");
  window.location.assign(
    Configuration.auth.cognitoRoot +
      Configuration.auth.logout +
      "?response_type=code&client_id=" +
      Configuration.auth.clientId +
      "&redirect_uri=" +
      Configuration.auth.redirectUri
  );

  return { type: ActionType.LOGOUT };
};

export const toggleToolbarMenuState = (
  toolbarMenuState: Element | null
): globalStateReducerTypes => {
  return {
    type: ActionType.TOOLBAR_MENU_CHANGE_STATE,
    payload: toolbarMenuState
  };
};

export const checkUserAccessLevel: Thunk<globalStateReducerTypes> = () => {
  return async dispatch => {
    try {
      await axios.get(Configuration.EsmaAPIUrl + "/users", esmaApiHeaders);
      dispatch({
        type: ActionType.IS_MASTER_ADMIN,
        payload: true
      });
    } catch (error) {
      dispatch({
        type: ActionType.IS_MASTER_ADMIN,
        payload: false
      });

      const { response } = error;
      if (response && response.status === 403) {
        try {
          const {
            data: { member }
          } = await axios.get(
            Configuration.EsmaAPIUrl + "/subscriptions",
            esmaApiHeaders
          );

          if (member.length > 0) {
            dispatch({
              type: ActionType.CUSTOMER_ADMIN,
              payload: true
            });
          } else {
            dispatch({
              type: ActionType.NORMAL_USER,
              payload: true
            });
          }
        } catch (err) {}
      }
    }
  };
};

export const closeExpiredSessionSnackbar = () => {
  return { type: ActionType.EXPIRED_SESSION, payload: false };
};

export const closeLoginErrorSnackbar = () => {
  return { type: ActionType.LOGIN_ERROR, payload: false };
};

export const refreshToken: Thunk<globalStateReducerTypes> = (
  activeSubscription: string,
  activeRole: string
) => async (dispatch, getState) => {
  const {
    globalState: {
      user: { roleId, subId }
    }
  } = getState();

  if (
    activeRole !== rolesPrefixURL + roleId ||
    activeSubscription !== subscriptionsPrefixURL + subId
  ) {
    try {
      const refreshToken: AxiosResponse<{
        token: string;
      }> = await axios.post(
        Configuration.AuthApiUrl + `/refresh`,
        null,
        authApiHeaders
      );

      const {
        data: { token }
      } = refreshToken;

      localStorage.setItem("authToken", token);
      axios.defaults.headers.common["x-lnx-token"] = token;

      const currentUser: ICurrentUser = JwtDecode(token);
      dispatch({ type: ActionType.AUTHENTICATION, payload: currentUser });

      dispatch({
        type: ActionType.RESET_USER_ACCESS_LEVEL
      });

      dispatch(checkUserAccessLevel());
    } catch (err) {
      dispatch({ type: ActionType.LOGIN_ERROR, payload: true });
    }
  }
};
