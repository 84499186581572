import moment from "moment";
import { AxiosResponse } from "axios";

import {
  IDevice,
  IDeviceApi,
  IDeviceCollection,
  IDeviceCollectionApi,
  initialDevice,
  initialDeviceCollection,
  IPosition,
  IDeviceCalibrationTable,
  IDeviceCalibrationProcessToApi,
  IDeviceCalibrationHistoryTable,
  IDeviceCalibrationHistoryApi,
  initialDeviceCalibration,
  IDeviceCalibration,
  IDeviceCalibrationApi,
  IDeviceCalibrationTableRow,
  IDeviceCalibrationValueToApi
} from "../models/IDevice";
import { ServiceUtilities } from "../../core/utilities/ServiceUtilities";
import {
  IDeviceModelCollectionApi,
  initialDeviceModel
} from "../../DeviceModels/models/IDeviceModel";
import { IUserCollectionApi } from "../../Users/models/IUser";
import { userCollectionFromApi } from "../../Users/services/UserService";
import { initialSubscription } from "../../Subscriptions/models/ISubscription";
import { deviceModelFromApi } from "../../DeviceModels/services/DeviceModelsService";
import {
  IParameters,
  ITemplate,
  IMobileTableRow
} from "../../models/shared/IShared";
import { devicesPrefixURL } from "../../config";

export const deviceFromApi = (
  device: IDeviceApi,
  initializedDevice?: IDevice
): IDevice => {
  const result = initializedDevice ? initializedDevice : { ...initialDevice };
  result.deviceModel = { ...initialDeviceModel, id: device.deviceModel };
  result.id = device["@id"];
  result.isLoading = false;
  result.name = device.name;
  result.note = device.note || "";
  result.owner = {
    ...initialDevice.owner,
    id: device.owner
  };
  result.serial = device.serial;
  result.networks = device.networks;
  result.autoLocation = device.autoLocation;
  result.manualLocation = (device.manualLocation as IPosition) || ({} as any);
  result.shortId = ServiceUtilities.getShortId(device["@id"]);
  result.subscription = {
    ...initialSubscription,
    id: device.subscription,
    shortId: ServiceUtilities.getShortId(device.subscription)
  };
  result.type = device["@type"];
  result.transferCode = device.transferCode;

  if (initializedDevice) {
    result.deviceModelList = initializedDevice.deviceModelList;
    result.subscriptionList = initializedDevice.subscriptionList;
    const model = initializedDevice.deviceModelList
      .filter(model => model.id === device.deviceModel)
      .pop();
    const subscription = initializedDevice.subscriptionList
      .filter(subscription => subscription.id === device.subscription)
      .pop();
    if (model) {
      result.deviceModel = model;
    }
    if (subscription) {
      result.subscription = subscription;
    }
  }

  result.template = device.template;
  result.payloadFormats = result.template.payloadFormats || [];
  result.downlinkFormats = result.template.downlinkFormats || [];
  result.payloadFieldProperties = [];
  result.basicCalculationProperties = [];
  result.valueParameters = [];

  if (result.template.parameters && result.template.parameters.length > 0) {
    result.payloadFieldProperties = [];
    result.basicCalculationProperties = [];
    result.template.parameters.forEach((property: IParameters) => {
      if (property.method.kind === "PayloadField") {
        result.payloadFieldProperties = [
          ...result.payloadFieldProperties,
          property
        ];
      } else if (property.method.kind === "BasicCalculation") {
        result.basicCalculationProperties = [
          ...result.basicCalculationProperties,
          property
        ];
      } else if (property.method.kind === "Value") {
        result.valueParameters = [...result.valueParameters, property];
      }
    });
  }

  result.weather = device.weather;

  return result;
};

export const deviceToApi = (device: IDevice): IDeviceApi => {
  const newParameters = [
    ...device.payloadFieldProperties,
    ...device.basicCalculationProperties,
    ...device.valueParameters
  ];

  const newTemplate: ITemplate = {};

  if (newParameters.length !== 0) {
    newTemplate.parameters = newParameters;
  }

  if (device.payloadFormats.length !== 0) {
    newTemplate.payloadFormats = device.payloadFormats;
  }

  if (device.downlinkFormats.length !== 0) {
    newTemplate.downlinkFormats = device.downlinkFormats;
  }

  return {
    "@id": device.id,
    "@type": device.type,
    autoLocation: device.autoLocation,
    deviceModel: device.deviceModel.id,
    manualLocation: device.manualLocation,
    name: device.name,
    networks: device.networks || [],
    note: device.note,
    owner: device.owner.id,
    serial: device.serial,
    subscription: device.subscription.id,
    template: newTemplate,
    transferCode: device.transferCode,
    weather: device.weather
  };
};

export const deviceCollectionFromApi = (
  collection: IDeviceCollectionApi
): IDeviceCollection => ({
  ...initialDeviceCollection,
  context: collection["@context"],
  id: collection["@id"],
  isLoading: false,
  members: collection.member.map(device => {
    return deviceFromApi(device);
  }),
  shortId: ServiceUtilities.getShortId(collection["@id"]),
  totalItems: collection.totalItems,
  type: collection["@type"],
  view: collection.view
});

export const addDeviceCollectionModelNames = (
  collection: IDeviceCollection,
  models: IDeviceModelCollectionApi
): IDeviceCollection => {
  return {
    ...collection,
    members: collection.members.map(device =>
      addDeviceModelName(device, models)
    )
  };
};
export const addDeviceModelName = (
  device: IDevice,
  models: IDeviceModelCollectionApi
): IDevice => {
  const filteredModel = models.member
    .filter(model => model["@id"] === device.deviceModel.id)
    .pop();
  return {
    ...device,
    deviceModel: filteredModel
      ? deviceModelFromApi(filteredModel)
      : { ...device.deviceModel, name: "<INACCESSIBLE>" }
  };
};

export const addDeviceOwner = (
  device: IDevice,
  userList: IUserCollectionApi
): IDevice => {
  const users = userCollectionFromApi(userList).members;
  const deviceOwner = users.filter(user => user.id === device.owner.id).pop();
  return {
    ...device,
    owner: deviceOwner ? deviceOwner : device.owner,
    userList: users
  };
};

export const devicesToMobileTableRow = (
  members: IDevice[]
): IMobileTableRow[] => {
  return members.map(item => {
    return {
      id: item.id,
      title: item.name,
      fields: [
        { label: "Serial", value: item.serial },
        { label: "Device Model", value: item.deviceModel.name }
      ],
      editPageLink: `/devices/${ServiceUtilities.getShortId(item.id)}`
    };
  });
};

export const getDeviceModelName = (
  devices: IDevice[],
  models: (void | AxiosResponse<any>)[]
): IDevice[] =>
  devices.map(device => ({
    ...device,
    deviceModel: models
      .filter(model => model)
      .map(model => model && deviceModelFromApi(model.data))
      .filter(model => model[`id`] === device.deviceModel.id)
      .pop() || { ...device.deviceModel, name: "<INACCESSIBLE>" }
  }));

export const calibrationProcesstoGetNewFormula = (
  calibrationTable: IDeviceCalibrationTable
): IDeviceCalibrationProcessToApi => ({
  "@context": "https://schema.ellenex.com/v1/context.jsonld",
  "@type": "Calibration",
  device: devicesPrefixURL + calibrationTable.deviceId,
  parameter: calibrationTable.parameter,
  algorithm: calibrationTable.selectedCalibrationVariant,
  dataSet: calibrationTable.rows
});

export const deviceCalibrationHistoryCollectionFromApi = (
  collection: IDeviceCalibrationHistoryApi
): IDeviceCalibrationHistoryTable[] =>
  collection.calibrationMember.map(history => {
    return {
      ...initialDeviceCalibration.currentCalibrationTable,
      historyId: history["@id"],
      date: history.dateCreated,
      isLoading: false,
      createdItem: false
    };
  });

export const deviceCalibrationToApi = ({
  currentCalibrationTable: {
    deviceId,
    parameter,
    parameterExpression,
    formula,
    rows,
    selectedCalibrationVariant
  }
}: IDeviceCalibration): IDeviceCalibrationApi => ({
  "@context": "",
  "@id": "",
  "@type": "",
  device: devicesPrefixURL + deviceId,
  parameter,
  currentFormula: parameterExpression,
  newFormula: formula,
  algorithm: selectedCalibrationVariant,
  dataSet: rows
});

export const deviceCalibrationFromApi = (
  calibration: IDeviceCalibrationApi
): IDeviceCalibrationTable => ({
  ...initialDeviceCalibration.currentCalibrationTable,
  id: calibration["@id"],
  date: calibration.dateCreated,
  deviceId: calibration.device,
  formula: calibration.newFormula,
  parameterExpression: calibration.currentFormula,
  parameter: calibration.parameter,
  rows: calibration.dataSet
});

export const checkCalibrateButtonDisabled = (
  rows: IDeviceCalibrationTableRow[]
): boolean => {
  if (rows.length === 0) {
    return true;
  }

  return rows.some(
    row => !Boolean(row.actualValue) || !Boolean(row.deviceValue)
  );
};

export const sortDeviceCalibrationHistoryByDate = (
  collection: IDeviceCalibrationHistoryTable[]
): IDeviceCalibrationHistoryTable[] =>
  collection.sort((calibrationHistoryA, calibrationHistoryB) => {
    return moment
      .utc(calibrationHistoryB.date)
      .diff(moment.utc(calibrationHistoryA.date));
  });

export const updateDeviceCalibrationValueToApi = (
  calibrationTable: IDeviceCalibrationTable
): IDeviceCalibrationValueToApi => ({
  "@context": "https://schema.ellenex.com/v1/context.jsonld",
  "@type": "QueryObject",
  kind: "Raw",
  limit: 1,
  timeOrder: "DESC",
  granularity: "all",
  dimensions: [calibrationTable.parameter],
  filter: {
    "@type": "Filter",
    kind: "and",
    fields: [
      {
        "@type": "Field",
        kind: "eq",
        dimension: "device",
        value: "/v1/edi/devices/" + calibrationTable.deviceId
      }
    ]
  },
  intervals: "2019-01-01T00:00:00.000/2022-01-01T00:00:00.000"
});
