import {
  initialSubscriptionCollection,
  ISubscriptionCollection
} from "../models/ISubscription";
import { ActionType } from "../../models/Action.model";

export type subscriptionCollectionReducerTypes =
  | {
      type: ActionType.GET_SUBSCRIPTION_COLLECTION;
      payload: ISubscriptionCollection;
    }
  | { type: ActionType.SUBSCRIPTION_COLLECTION_LOADING; payload: boolean };

export function subscriptionCollectionReducer(
  state: ISubscriptionCollection = { ...initialSubscriptionCollection },
  action: subscriptionCollectionReducerTypes
): ISubscriptionCollection {
  switch (action.type) {
    case ActionType.GET_SUBSCRIPTION_COLLECTION:
      state = {
        ...state,
        ...action.payload
      };
      break;
    case ActionType.SUBSCRIPTION_COLLECTION_LOADING:
      state = {
        ...state,
        isLoading: action.payload
      };
      break;
  }
  return state;
}
