import * as React from "react";

import { TableRow, Button } from "@material-ui/core";

import { IUser } from "../../models/IUser";
import {
  NoStyleLink,
  ButtonTableCell,
  SmallIcon,
  StyledTableCell
} from "../../../components/sharedStyledComponents";

export const UserTableRow: React.FunctionComponent<IUser> = ({
  id,
  shortId,
  name,
  email,
  mobile
}: IUser) => (
  <TableRow key={id}>
    <StyledTableCell> {name} </StyledTableCell>
    <StyledTableCell> {email} </StyledTableCell>
    <StyledTableCell> {mobile} </StyledTableCell>
    <ButtonTableCell>
      <NoStyleLink to={"/users/" + shortId}>
        <Button color="primary">
          Edit <SmallIcon />
        </Button>
      </NoStyleLink>
    </ButtonTableCell>
  </TableRow>
);
