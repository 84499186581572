import { IDeviceModel, initialDeviceModel } from "../models/IDeviceModel";
import { ITableDeleteCell } from "../../models/shared/IShared";
import { ActionType } from "../../models/Action.model";

export type deviceModelReducerTypes =
  | { type: ActionType.INITIALIZE_DEVICEMODEL_EDIT; payload: IDeviceModel }
  | { type: ActionType.GET_DEVICEMODEL; payload: IDeviceModel }
  | { type: ActionType.CREATE_DEVICEMODEL; payload: IDeviceModel }
  | { type: ActionType.UPDATE_DEVICEMODEL; payload: IDeviceModel }
  | { type: ActionType.DEVICEMODEL_LOADING; payload: boolean }
  | { type: ActionType.CLEAR_DEVICEMODEL }
  | { type: ActionType.SET_DEVICEMODEL_FORM_VALUE; payload: IDeviceModel }
  | {
      type: ActionType.DELETE_DEVICE_MODEL_DIALOG;
      payload: ITableDeleteCell;
    };

export function deviceModelReducer(
  state: IDeviceModel = { ...initialDeviceModel },
  action: deviceModelReducerTypes
): IDeviceModel {
  switch (action.type) {
    case ActionType.GET_DEVICEMODEL:
      state = {
        ...state,
        ...action.payload,
        subscriptionList: state.subscriptionList
      };
      break;
    case ActionType.CREATE_DEVICEMODEL:
      state = {
        ...state,
        ...action.payload,
        subscriptionList: state.subscriptionList
      };
      break;
    case ActionType.UPDATE_DEVICEMODEL:
      state = {
        ...state,
        ...action.payload,
        subscriptionList: state.subscriptionList
      };
      break;
    case ActionType.DEVICEMODEL_LOADING:
      state = {
        ...state,
        isLoading: action.payload
      };
      break;
    case ActionType.INITIALIZE_DEVICEMODEL_EDIT:
      state = {
        ...state,
        subscriptionList: action.payload.subscriptionList,
        subscription: action.payload.subscription
      };
      break;
    case ActionType.CLEAR_DEVICEMODEL:
      state = { ...initialDeviceModel };
      break;
    case ActionType.SET_DEVICEMODEL_FORM_VALUE:
      state = { ...action.payload };
      break;
    case ActionType.DELETE_DEVICE_MODEL_DIALOG:
      state = {
        ...state,
        deviceModelDelete: action.payload
      };
      break;
  }
  return state;
}
