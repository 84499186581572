import React from "react";
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from "react-sortable-hoc";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton
} from "@material-ui/core";
import { DragIndicator, Delete as DeleteIcon } from "@material-ui/icons/";
import styled from "styled-components";

interface ISortableElement {
  removeItem(item: any): void;
  itemIdentityKey: string;
  key: string;
  lastItem: boolean;
  value: string;
  index: string;
}

interface ISortableItems {
  removeItem(item: any): void;
  items: any[];
  sortableKey: string;
  valueKey: string;
  identityKey: string;
}

const DragHandle = SortableHandle(() => (
  <ListItemIcon>
    <DragIndicator />
  </ListItemIcon>
));

interface IStyledOrderedItem {
  last: number;
}
export const StyledOrderedItem = styled(ListItem)<IStyledOrderedItem>`
  margin-bottom: ${props => (props.last ? "0" : "5px")};
  background: #ffffff;
`;
StyledOrderedItem.displayName = "StyledOrderedItem";

const SortableItem = SortableElement(
  ({ value, removeItem, itemIdentityKey, lastItem }: ISortableElement) => {
    const handleRemove = () => {
      removeItem(itemIdentityKey);
    };
    return (
      <StyledOrderedItem button={true} last={lastItem ? 1 : 0}>
        <DragHandle />
        <ListItemText>{value}</ListItemText>
        <ListItemSecondaryAction>
          <IconButton aria-label="Delete" onClick={handleRemove}>
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </StyledOrderedItem>
    );
  }
);

export const StyledList = styled(List)`
  background: #f7f7f7;
  && {
    margin-top: 20px;
    padding: 5px;
  }
`;
StyledList.displayName = "StyledList";

export const SortableList = SortableContainer(
  ({
    items,
    sortableKey,
    valueKey,
    removeItem,
    identityKey
  }: ISortableItems) => {
    return (
      <StyledList>
        {items.map((value, index, arr) => (
          <SortableItem
            itemIdentityKey={value[identityKey]}
            removeItem={removeItem}
            key={`item-${index}`}
            index={value[sortableKey]}
            lastItem={index === arr.length - 1}
            value={value[valueKey]}
          />
        ))}
      </StyledList>
    );
  }
);
