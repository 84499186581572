import React from "react";
import { FieldArrayRenderProps } from "formik";
import {
  TableCell,
  TableRow,
  IconButton,
  TableHead,
  TableBody,
  Table,
  Button
} from "@material-ui/core";
import { Sync, Delete, Add } from "@material-ui/icons";
import styled from "styled-components";

import { StyledTextField, TextField } from "../../../components/TextField";
import {
  IDeviceCalibrationTableRow,
  IDeviceCalibration
} from "../../models/IDevice";
import { Validators } from "../../../util/validators";

export const StyledControlTableCell = styled(TableCell)`
  width: 70px;
  && {
    padding: 0;
  }
`;
StyledControlTableCell.displayName = "StyledControlTableCell";
export const StyledButtonDiv = styled.div`
  && {
    padding: 10px 5px;
  }
`;
StyledButtonDiv.displayName = "StyledButtonDiv";

interface IDeviceCalibrationFieldArrayProps {
  updateDeviceValueForCalibrate: (
    rowIndex: number,
    item: IDeviceCalibration
  ) => void;
}

type CalibrationFieldArrayType = FieldArrayRenderProps &
  IDeviceCalibrationFieldArrayProps;

export const CalibrationFieldArray: React.FunctionComponent<
  CalibrationFieldArrayType
> = ({
  form: {
    values,
    values: {
      isLoading,
      currentCalibrationTable: { rows, parameter },
      calibrationDeviceValueLoading,
      calibrationFormulaLoading
    },
    isSubmitting
  },
  push,
  remove,
  updateDeviceValueForCalibrate
}: CalibrationFieldArrayType) => {
  const { actualValue, deviceValue } = Validators.deviceCalibration;

  const updateDeviceValue = (index: number) => () => {
    updateDeviceValueForCalibrate(index, values);
  };
  const removeRow = (index: number) => () => {
    remove(index);
  };

  const addRow = () => {
    push({ actualValue: "", deviceValue: "" });
  };

  return (
    <React.Fragment>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Actual Value</TableCell>
            <TableCell>Device Value</TableCell>
            <StyledControlTableCell>Get Data</StyledControlTableCell>
            <StyledControlTableCell>Delete</StyledControlTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row: IDeviceCalibrationTableRow, index: number) => (
            <TableRow key={index}>
              <TableCell>
                <StyledTextField
                  type="number"
                  name={`currentCalibrationTable.rows[${index}].actualValue`}
                  component={TextField}
                  disabled={
                    isLoading ||
                    isSubmitting ||
                    calibrationDeviceValueLoading ||
                    calibrationFormulaLoading
                  }
                  validate={actualValue(rows, index)}
                />
              </TableCell>
              <TableCell>
                <StyledTextField
                  type="number"
                  name={`currentCalibrationTable.rows[${index}].deviceValue`}
                  component={TextField}
                  disabled={
                    isLoading ||
                    isSubmitting ||
                    calibrationDeviceValueLoading ||
                    calibrationFormulaLoading
                  }
                  validate={deviceValue(rows, index)}
                />
              </TableCell>

              <StyledControlTableCell>
                <IconButton
                  color="primary"
                  onClick={updateDeviceValue(index)}
                  disabled={
                    isLoading ||
                    isSubmitting ||
                    calibrationDeviceValueLoading ||
                    calibrationFormulaLoading
                  }
                >
                  <Sync />
                </IconButton>
              </StyledControlTableCell>

              <StyledControlTableCell>
                <IconButton
                  color="primary"
                  onClick={removeRow(index)}
                  disabled={
                    isLoading ||
                    isSubmitting ||
                    calibrationDeviceValueLoading ||
                    calibrationFormulaLoading
                  }
                >
                  <Delete />
                </IconButton>
              </StyledControlTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <StyledButtonDiv>
        <Button
          onClick={addRow}
          disabled={
            isLoading ||
            isSubmitting ||
            !Boolean(parameter) ||
            calibrationDeviceValueLoading ||
            calibrationFormulaLoading
          }
        >
          Add
          <Add />
        </Button>
      </StyledButtonDiv>
    </React.Fragment>
  );
};
CalibrationFieldArray.displayName = "CalibrationFieldArray";
