import { ActionType } from "../../models/Action.model";
import {
  IUserBySubscription,
  initialUserBySubscription,
  IInviteUserBySubscription
} from "../models/IUserBySubscription";
import {
  ISubscriptionSelection,
  ITableDeleteCell
} from "../../models/shared/IShared";

export type userBySubscriptionReducerType =
  | {
      type: ActionType.CURRENT_SUBSCRIPTION_CUSTOMER_ADMIN;
      payload: ISubscriptionSelection;
    }
  | { type: ActionType.INVITE_USER_DIALOG_OPEN; payload: boolean }
  | {
      type: ActionType.INVITE_USER_INITIAL_VALUE;
      payload: IInviteUserBySubscription;
    }
  | {
      type: ActionType.GET_SUBSCRIPTION_COLLECTION_USER_BY_SUBSCRIPTION;
      payload: ISubscriptionSelection[];
    }
  | {
      type: ActionType.INITIALIZE_USER_BY_SUBSCRIPTION;
      payload: IUserBySubscription;
    }
  | { type: ActionType.USER_BY_SUBSCRIPTION_LOADING; payload: boolean }
  | {
      type: ActionType.USER_BY_SUBSCRIPTION_SELECTION_LOADING;
      payload: boolean;
    }
  | { type: ActionType.SET_ADD_USER_FORM_VALUE; payload: IUserBySubscription }
  | { type: ActionType.CLEAR_USER_BY_SUBSCRIPTION }
  | { type: ActionType.CLEAR_CURRENT_SUB_USER_BY_SUBSCRIPTION }
  | {
      type: ActionType.SET_USER_BY_SUBSCRIPTION_STATE_FOR_EDIT;
      payload: IUserBySubscription;
    }
  | {
      type: ActionType.UPDATE_USER_BY_SUBSCRIPTION;
      payload: IUserBySubscription;
    }
  | { type: ActionType.DELETE_USER_BY_SUB_DIALOG; payload: ITableDeleteCell };

export function userBySubscriptionReducer(
  state: IUserBySubscription = { ...initialUserBySubscription },
  action: userBySubscriptionReducerType
): IUserBySubscription {
  switch (action.type) {
    case ActionType.CURRENT_SUBSCRIPTION_CUSTOMER_ADMIN:
      state = {
        ...state,
        subscription: action.payload
      };
      break;
    case ActionType.INVITE_USER_DIALOG_OPEN:
      state = {
        ...state,
        inviteUserDialogOpen: action.payload
      };
      break;
    case ActionType.INVITE_USER_INITIAL_VALUE:
      state = {
        ...state,
        inviteUserInitialValue: action.payload
      };
      break;
    case ActionType.GET_SUBSCRIPTION_COLLECTION_USER_BY_SUBSCRIPTION:
      state = {
        ...state,
        subscriptionList: action.payload
      };
      break;
    case ActionType.USER_BY_SUBSCRIPTION_LOADING:
      state = {
        ...state,
        isLoading: action.payload
      };
      break;
    case ActionType.USER_BY_SUBSCRIPTION_SELECTION_LOADING:
      state = {
        ...state,
        isLoadingSelection: action.payload
      };
      break;
    case ActionType.INITIALIZE_USER_BY_SUBSCRIPTION: {
      state = {
        ...state,
        roleList: action.payload.roleList
      };
      break;
    }
    case ActionType.SET_ADD_USER_FORM_VALUE: {
      state = {
        ...action.payload,
        subscription: state.subscription,
        subscriptionList: state.subscriptionList
      };
      break;
    }
    case ActionType.CLEAR_USER_BY_SUBSCRIPTION:
      state = {
        ...initialUserBySubscription,
        subscription: state.subscription,
        subscriptionList: state.subscriptionList
      };
      break;
    case ActionType.CLEAR_CURRENT_SUB_USER_BY_SUBSCRIPTION:
      state = {
        ...initialUserBySubscription
      };
      break;
    case ActionType.SET_USER_BY_SUBSCRIPTION_STATE_FOR_EDIT:
      state = { ...action.payload, subscription: state.subscription };
      break;
    case ActionType.UPDATE_USER_BY_SUBSCRIPTION:
      state = {
        ...state,
        ...action.payload,
        subscription: state.subscription,
        subscriptionList: state.subscriptionList,
        inviteUserDialogOpen: state.inviteUserDialogOpen,
        roleList: state.roleList
      };
      break;
    case ActionType.DELETE_USER_BY_SUB_DIALOG:
      state = {
        ...state,
        userBySubscriptionDelete: action.payload
      };
      break;
  }
  return state;
}
