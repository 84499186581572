import React from "react";
import { TableBody } from "@material-ui/core";

import { IUser } from "../models/IUser";
import { StyledTable } from "../../components/sharedStyledComponents";
import { IPaginationView } from "../../models/shared/IShared";
import { UserTableHeader } from "./UserTable/UserTableHeader";
import { UserTableFooter } from "./UserTable/UserTableFooter";
import { UserTableRow } from "./UserTable/UserTableRow";

export interface IUserTableProps {
  pagination?: IPaginationView;
  isLoading?: boolean;
  rows: IUser[];
  totalItems?: number;
  onChangePage?: (page: string) => void;
}

export const UserTable: React.FunctionComponent<IUserTableProps> = props => (
  <React.Fragment>
    <StyledTable>
      <UserTableHeader />
      {!props.isLoading && (
        <React.Fragment>
          <TableBody>
            {props.rows.map(row => (
              <UserTableRow key={row.id} {...row} />
            ))}
          </TableBody>
          <UserTableFooter {...props} />
        </React.Fragment>
      )}
    </StyledTable>
  </React.Fragment>
);
