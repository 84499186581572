import React from "react";
import { OptionsObject, VariantType, useSnackbar } from "notistack";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { store } from "../../store";
import { ActionType } from "../../models/Action.model";
import { Configuration } from "../../config";
import { UUID } from "../../util/validators";
import { ServiceUtilities } from "./ServiceUtilities";
import { CustomAccessDeniedMessage } from "./CustomAccessDeniedMessage";

export enum alertMessages {
  EXPIRED_SESSION = "Your session has expired. Please, sign in again!",
  NEW_VERSION = "New version available!",
  ERROR_403 = "You don't have required permission to access ",
  ERROR_404 = "Not Found",
  SERVER_ERROR = "Server Error. Please try again later",
  DEVICE_UPDATE_SUCCESS = "Device Updated Successfully",
  DEVICE_CREATE_SUCCESS = "Device Created Successfully",
  DEVICEMODEL_UPDATE_SUCCESS = "Device Model Updated Successfully",
  DEVICEMODEL_CREATE_SUCCESS = "Device Model Created Successfully",
  POLICY_UPDATE_SUCCESS = "Policy Updated Successfully",
  POLICY_CREATE_SUCCESS = "Policy Created Successfully",
  ROLE_UPDATE_SUCCESS = "Role Updated Successfully",
  ROLE_CREATE_SUCCESS = "Role Created Successfully",
  USER_UPDATE_SUCCESS = "User Updated Successfully",
  USER_CREATE_SUCCESS = "User Created Successfully",
  SUBSCRIPTION_UPDATE_SUCCESS = "Subscription Updated Successfully",
  SUBSCRIPTION_CREATE_SUCCESS = "Subscription Created Successfully",
  USERPROFILE_UPDATE_SUCCESS = "User Profile Updated Successfully",
  ADD_USER_SUCCESS = "User Added Successfully",
  UPDATE_USER_SUCCESS = "User updated Successfully",
  INVITE_USER_SUCCESS = "User Invited and Added Successfully",
  CUSTOMER_USER_REMOVE_SUCCESS = "User Removed Successfully",
  DEVICE_DELETE_SUCCESS = "Device Deleted Successfully",
  DEVICE_MODEL_DELETE_SUCCESS = "Device Model Deleted Successfully",
  ROLE_DELETE_SUCCESS = "Role Deleted Successfully",
  POLICY_DELETE_SUCCESS = "Policy Deleted Successfully",
  LOGIN_ERROR = "User does not have any access. Please, contact support.",
  DEVICE_CALIBRATE_SUCCESS = "Device Calibrated Successfully"
}

interface ISnackbarCloseProps {
  snackbarKey: string | number | undefined;
}

export const SnackbarCloseButton: React.FunctionComponent<
  ISnackbarCloseProps
> = ({ snackbarKey }) => {
  const { closeSnackbar } = useSnackbar();
  const close = () => {
    closeSnackbar(snackbarKey);
  };
  return (
    <IconButton key="close" aria-label="Close" color="inherit" onClick={close}>
      <CloseIcon />
    </IconButton>
  );
};
SnackbarCloseButton.displayName = "SnackbarCloseButton";

export const snackbarCloseButtonRenderer = (
  key: string | number | undefined
) => <SnackbarCloseButton snackbarKey={key} />;

export const getStyledSnackbarOptions = (
  variant: VariantType = "default"
): OptionsObject => {
  const { success, error } = Configuration.snackbarAutohideDuration;
  return {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center"
    },
    variant,
    autoHideDuration:
      variant === "warning" || variant === "error" ? error : success,
    action: snackbarCloseButtonRenderer
  };
};

export interface ISnackbarError {
  message: string;
  options: OptionsObject;
}
export type SnackbarError = ISnackbarError | undefined;

export function checkError(err: any, resource?: string): SnackbarError {
  if (!err.response) {
    return {
      message: alertMessages.SERVER_ERROR,
      options: getStyledSnackbarOptions("warning")
    };
  } else {
    const {
      response: {
        status,
        request: { responseURL }
      }
    } = err;

    const {
      userProfileReducer: {
        activeSubscription: { name: activeSubName },
        activeRole: { name: activeRoleName }
      }
    } = store.getState();

    switch (true) {
      case status === 401:
        store.dispatch({
          type: ActionType.EXPIRED_SESSION,
          payload: true
        });
        return;
      case status === 403:
        if (resource === "users") {
          store.dispatch({
            type: ActionType.CUSTOMER_ADMIN,
            payload: true
          });
          return;
        } else {
          return {
            message: "",
            options: {
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center"
              },
              autoHideDuration: 15000,
              children: key => (
                <CustomAccessDeniedMessage
                  id={key}
                  errorResource={errorResource(responseURL, false)}
                  errorURL={errorResource(responseURL, true)}
                  activeSub={activeSubName}
                  activeRole={activeRoleName}
                />
              )
            }
          };
        }
      case status >= 400 && status <= 499:
        if (resource === "addUser" && status === 404) {
          return {
            message: "404",
            options: getStyledSnackbarOptions("warning")
          };
        }
        return {
          message: err.response.data.description,
          options: getStyledSnackbarOptions("warning")
        };
      default:
        return {
          message: alertMessages.SERVER_ERROR,
          options: getStyledSnackbarOptions("warning")
        };
    }
  }
}

export function isSnackbarError<E>(
  response: E | SnackbarError
): response is ISnackbarError {
  return Boolean(response && (response as ISnackbarError).options);
}

export function convertErrorNewLines(message: string): React.ReactNode {
  const messageLinesArray = message.split("\n");

  return (
    <div>
      {messageLinesArray.map((line, index) => (
        <React.Fragment key={index}>
          <span key={"span_" + index}>{line}</span>
          {index < messageLinesArray.length - 1 && <br key={"br_" + index} />}
        </React.Fragment>
      ))}
    </div>
  );
}

export const errorResource = (
  responseURL: string,
  errorURL: boolean
): string => {
  const splittedResponseURL = responseURL
    .split("://")[1]
    .split("/")
    .filter((item, index) => index !== 0 && item !== "dev");

  if (errorURL) {
    return "/" + splittedResponseURL.join("/");
  } else {
    return Boolean(
      UUID.test(splittedResponseURL[splittedResponseURL.length - 1])
    )
      ? ServiceUtilities.capitalizeFirstLetter(
          checkQueryString(splittedResponseURL[splittedResponseURL.length - 2])
        )
      : ServiceUtilities.capitalizeFirstLetter(
          checkQueryString(splittedResponseURL[splittedResponseURL.length - 1])
        );
  }
};

export const checkQueryString = (Resource: string): string =>
  Resource.split("?")[0] || Resource;
