import React from "react";
import styled from "styled-components";
import { TableRow, Button } from "@material-ui/core";

import { IUserBySubscription } from "../../models/IUserBySubscription";
import { ITableDeleteCell } from "../../../models/shared/IShared";
import {
  NoStyleLink,
  ButtonTableCell,
  SmallIcon,
  SmallDeleteIcon,
  StyledTableCell
} from "../../../components/sharedStyledComponents";

export const StyledDeleteButton = styled(Button)`
  && {
    font-size: 12px;
  }
`;
StyledDeleteButton.displayName = "StyledDeleteButton";

interface IUserBySubTableRowProps {
  user: IUserBySubscription;
  handleDialogOpen: (
    userInfo: ITableDeleteCell
  ) => (_: React.MouseEvent) => void;
}

export const UserBySubTableRow: React.FunctionComponent<
  IUserBySubTableRowProps
> = ({
  user: { id, email, roles, shortId },
  handleDialogOpen
}: IUserBySubTableRowProps) => (
  <TableRow key={id}>
    <StyledTableCell> {email} </StyledTableCell>
    <StyledTableCell>{roles.map(role => role.name).join(", ")}</StyledTableCell>
    <ButtonTableCell>
      <NoStyleLink
        to={{
          pathname: "/users/" + shortId,
          state: {
            userBySubscription: true
          }
        }}>
        <Button color="primary">
          Edit <SmallIcon />
        </Button>
      </NoStyleLink>
      <br />
      <StyledDeleteButton
        color="primary"
        onClick={handleDialogOpen({
          visible: true,
          id: id,
          title: email
        })}>
        Remove from subscription
        <SmallDeleteIcon />
      </StyledDeleteButton>
    </ButtonTableCell>
  </TableRow>
);
