import axios, { AxiosResponse } from "axios";

import { ActionType } from "../../models/Action.model";
import {
  IDeviceModel,
  IDeviceModelApi,
  initialDeviceModel
} from "../models/IDeviceModel";
import { ITableDeleteCell } from "../../models/shared/IShared";
import {
  Configuration,
  ediApiHeaders,
  esmaApiHeaders,
  deviceModelsPrefixURL,
  subscriptionsPrefixURL
} from "../../config";
import { Thunk } from "../../store";
import { deviceModelReducerTypes } from "../reducers/deviceModelReducer";
import {
  deviceModelFromApi,
  deviceModelToApi
} from "../services/DeviceModelsService";
import { createBlockableDispatch } from "../../core/utilities/ServiceUtilities";
import {
  subscriptionCollectionFromApi,
  subscriptionFromApi
} from "../../Subscriptions/services/SubscriptionService";
import { checkError, SnackbarError } from "../../core/utilities/AlertUtilities";
import { getDeviceModelCollection } from "./deviceModelCollectionAction";
import { initialSubscription } from "../../Subscriptions/models/ISubscription";

export const getDeviceModelById: Thunk<deviceModelReducerTypes> = (
  id: string,
  initializedDeviceModel?: IDeviceModel
) => {
  return async (dispatch, _, opt): Promise<IDeviceModel | SnackbarError> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );
    dispatch({
      type: ActionType.DEVICEMODEL_LOADING,
      payload: true
    });
    try {
      const response: AxiosResponse<IDeviceModelApi> = await axios.get(
        Configuration.EdiAPIBaseUrl + deviceModelsPrefixURL + id,
        ediApiHeaders
      );

      const deviceModel = deviceModelFromApi(
        response.data,
        initializedDeviceModel
      );

      blockableDispatch({
        type: ActionType.GET_DEVICEMODEL,
        payload: deviceModel
      });
      return deviceModel;
    } catch (err) {
      return checkError(err);
    } finally {
      dispatch({
        type: ActionType.DEVICEMODEL_LOADING,
        payload: false
      });
    }
  };
};

export const initializeDeviceModelEdit: Thunk<deviceModelReducerTypes> = (
  setLoadingState: boolean,
  subId: string
) => {
  return async (dispatch, _, opt): Promise<IDeviceModel | SnackbarError> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );
    dispatch({
      type: ActionType.CLEAR_DEVICEMODEL
    });
    dispatch({
      type: ActionType.DEVICEMODEL_LOADING,
      payload: true
    });
    try {
      const response = await axios.get(
        Configuration.EsmaAPIUrl + "/subscriptions",
        esmaApiHeaders
      );

      let subscriptionList = subscriptionCollectionFromApi(response.data)
        .members;

      let activeSubscription =
        subscriptionList
          .filter(sub => sub.id === subscriptionsPrefixURL + subId)
          .pop() || initialSubscription;

      if (!Boolean(activeSubscription.id)) {
        const activeSubResult = await axios.get(
          Configuration.EsmaAPIBaseUrl + subscriptionsPrefixURL + subId,
          esmaApiHeaders
        );

        activeSubscription = subscriptionFromApi(activeSubResult.data);

        subscriptionList = [...subscriptionList, activeSubscription];
      }

      const deviceModel = { ...initialDeviceModel };
      deviceModel.subscriptionList = subscriptionList;
      if (setLoadingState) {
        deviceModel.subscription = activeSubscription;
      }

      blockableDispatch({
        type: ActionType.INITIALIZE_DEVICEMODEL_EDIT,
        payload: deviceModel
      });
      return deviceModel;
    } catch (err) {
      return checkError(err);
    } finally {
      if (setLoadingState) {
        dispatch({
          type: ActionType.DEVICEMODEL_LOADING,
          payload: false
        });
      }
    }
  };
};

export const updateDeviceModelById: Thunk<deviceModelReducerTypes> = (
  id: string,
  deviceModel: IDeviceModel
) => {
  return async (dispatch): Promise<IDeviceModel | SnackbarError> => {
    dispatch({
      type: ActionType.SET_DEVICEMODEL_FORM_VALUE,
      payload: deviceModel
    });
    dispatch({
      type: ActionType.DEVICEMODEL_LOADING,
      payload: true
    });
    try {
      const response: AxiosResponse<IDeviceModelApi> = await axios.put(
        Configuration.EdiAPIBaseUrl + id,
        deviceModelToApi(deviceModel),
        ediApiHeaders
      );

      const updatedDeviceModel = deviceModelFromApi(response.data, deviceModel);

      dispatch({
        type: ActionType.UPDATE_DEVICEMODEL,
        payload: updatedDeviceModel
      });
      return updatedDeviceModel;
    } catch (err) {
      return checkError(err);
    } finally {
      dispatch({
        type: ActionType.DEVICEMODEL_LOADING,
        payload: false
      });
    }
  };
};

export const createDeviceModel: Thunk<deviceModelReducerTypes> = (
  deviceModel: IDeviceModel
) => {
  return async (dispatch): Promise<IDeviceModel | SnackbarError> => {
    dispatch({
      type: ActionType.SET_DEVICEMODEL_FORM_VALUE,
      payload: deviceModel
    });
    dispatch({
      type: ActionType.DEVICEMODEL_LOADING,
      payload: true
    });
    try {
      const response: AxiosResponse<IDeviceModelApi> = await axios.post(
        Configuration.EdiAPIUrl + "/devicemodels",
        deviceModelToApi(deviceModel),
        ediApiHeaders
      );
      dispatch({
        type: ActionType.CREATE_DEVICEMODEL,
        payload: deviceModelFromApi(response.data, deviceModel)
      });
      return deviceModelFromApi(response.data);
    } catch (err) {
      return checkError(err);
    } finally {
      dispatch({
        type: ActionType.DEVICEMODEL_LOADING,
        payload: false
      });
    }
  };
};

export const deleteDeviceModel: Thunk<deviceModelReducerTypes> = (
  deviceModelInfo: ITableDeleteCell
) => {
  return async (dispatch): Promise<number | SnackbarError> => {
    dispatch({
      type: ActionType.DEVICEMODEL_LOADING,
      payload: true
    });
    try {
      const response = await axios.delete(
        Configuration.EdiAPIBaseUrl + deviceModelInfo.id,
        ediApiHeaders
      );

      dispatch({
        type: ActionType.DELETE_DEVICE_MODEL_DIALOG,
        payload: { ...deviceModelInfo, visible: false }
      });

      dispatch(getDeviceModelCollection());

      return response.status;
    } catch (err) {
      return checkError(err);
    } finally {
      dispatch({
        type: ActionType.DEVICEMODEL_LOADING,
        payload: false
      });
    }
  };
};

export const clearDeviceModel = (): deviceModelReducerTypes => {
  return { type: ActionType.CLEAR_DEVICEMODEL };
};

export const deviceModelDeleteConfirmDialog: Thunk<deviceModelReducerTypes> = (
  deviceModelInfo: ITableDeleteCell
) => {
  return async dispatch => {
    dispatch({
      type: ActionType.DELETE_DEVICE_MODEL_DIALOG,
      payload: deviceModelInfo
    });
  };
};
