import React from "react";
import styled from "styled-components";
import { Field, Form, FormikProps } from "formik";
import {
  Button,
  LinearProgress,
  Grid,
  NoSsr,
  Typography
} from "@material-ui/core";

import { StyledTextField, TextField } from "../../components/TextField";
import { StyledAutocompleteSelectLabel } from "../../components/sharedStyledComponents";
import { Validators } from "../../util/validators";
import {
  AutocompleteSelect,
  StyledAutocomplete
} from "../../components/AutocompleteSelect";
import { IUser, ISubscriptionWithRole } from "../models/IUser";
import { CheckboxWithLabel } from "../../components/CheckboxWithLabel";
import { IRole } from "../../Roles/models/IRole";

export const CheckboxSegmentLabel = styled(Typography)`
  && {
    margin-top: 10px;
    font-size: 17px;
  }
`;
CheckboxSegmentLabel.displayName = "CheckboxSegmentLabel";

export const UserCheckbox = styled(Field)`
  height: 35px;
`;
UserCheckbox.displayName = "UserCheckbox";

interface IEditUserProps {
  onSubChange: (
    selectedSub: ISubscriptionWithRole[],
    userFormValue: IUser
  ) => void;
}

type EditUserFormType = FormikProps<IUser> & IEditUserProps;

export const EditUserForm: React.FunctionComponent<EditUserFormType> = ({
  submitForm,
  isSubmitting,
  values,
  setFieldValue,
  onSubChange
}: EditUserFormType) => {
  const {
    isLoading,
    subscriptionList,
    name: userName,
    email: userEmail,
    mobile: userMobile
  } = values;
  const {
    editUser: { name, email }
  } = Validators;

  const subscriptionSelectionHandleChange = (
    selectedSub: ISubscriptionWithRole[]
  ) => {
    if (selectedSub !== null) {
      setFieldValue("hasRoles", selectedSub);
      onSubChange(selectedSub, values);
    } else {
      setFieldValue("hasRoles", []);
    }
  };

  const roleSelectionHandleChange = (index: number) => (
    selectedRole: IRole[]
  ) => {
    if (selectedRole !== null) {
      setFieldValue(`hasRoles[${index}].roles`, selectedRole);
    } else {
      setFieldValue(`hasRoles[${index}].roles`, []);
    }
  };

  return (
    <Form>
      <StyledTextField
        type="text"
        label="Name"
        name="name"
        multiline={false}
        component={TextField}
        value={userName}
        validate={name}
        disabled={isSubmitting || isLoading}
      />
      <br />
      <StyledTextField
        type="text"
        label="Email"
        name="email"
        multiline={false}
        component={TextField}
        value={userEmail}
        validate={email}
        disabled={true}
      />
      <br />

      <NoSsr>
        {values.hasRoles[0] && Boolean(values.hasRoles[0].id) && !isLoading && (
          <StyledAutocompleteSelectLabel htmlFor="subscription">
            Subscriptions
          </StyledAutocompleteSelectLabel>
        )}

        <StyledAutocomplete>
          <Field
            name="hasRoles"
            options={subscriptionList}
            component={AutocompleteSelect}
            isDisabled={isSubmitting || isLoading}
            labelField="name"
            identityKey="id"
            placeholder="Select Subscriptions"
            isMulti={true}
            isClearable={true}
            clearValue={[]}
            onChange={subscriptionSelectionHandleChange}
          />
        </StyledAutocomplete>
      </NoSsr>

      {values.hasRoles.map((sub: ISubscriptionWithRole, index: number) => {
        return (
          <div key={index}>
            <br />
            <NoSsr>
              {Boolean(values.hasRoles[index].roles.length > 0) &&
                !isLoading && (
                  <StyledAutocompleteSelectLabel htmlFor="roles">
                    Roles of {sub.name}
                  </StyledAutocompleteSelectLabel>
                )}

              <StyledAutocomplete>
                <Field
                  name={`hasRoles[${index}].roles`}
                  options={sub.roleList}
                  component={AutocompleteSelect}
                  isDisabled={
                    isSubmitting ||
                    isLoading ||
                    Boolean(values.hasRoles[index].roleListLoading === false)
                  }
                  labelField="name"
                  identityKey="id"
                  placeholder={`Select Roles of ${sub.name}`}
                  isMulti={true}
                  isClearable={true}
                  clearValue={[]}
                  onChange={roleSelectionHandleChange(index)}
                />
                {Boolean(values.hasRoles[index].roleListLoading === false) && (
                  <LinearProgress />
                )}
              </StyledAutocomplete>
            </NoSsr>
          </div>
        );
      })}

      <br />
      <StyledTextField
        type="text"
        label="Mobile"
        name="mobile"
        multiline={false}
        component={TextField}
        value={userMobile}
        disabled={isSubmitting || isLoading}
      />
      <br />
      <br />
      <CheckboxSegmentLabel align="left" variant="caption">
        How do you prefer to receive alerts?
      </CheckboxSegmentLabel>
      <br />
      <UserCheckbox
        color="primary"
        Label={{ label: "SMS" }}
        name="smsNotification"
        component={CheckboxWithLabel}
        disabled={isLoading || isSubmitting}
      />
      <br />
      <UserCheckbox
        color="primary"
        Label={{ label: "Email" }}
        name="emailNotification"
        component={CheckboxWithLabel}
        disabled={isLoading || isSubmitting}
      />
      <br />

      <br />
      {(isSubmitting || isLoading) && <LinearProgress />}
      <br />
      <Grid
        container={true}
        item={true}
        direction="row"
        justify="flex-start"
        alignItems="center">
        <Button
          variant="contained"
          color="primary"
          disabled={isSubmitting || isLoading}
          onClick={submitForm}>
          Save
        </Button>
      </Grid>
    </Form>
  );
};
