import axios, { AxiosResponse } from "axios";

import { ActionType } from "../../models/Action.model";
import {
  ISubscription,
  ISubscriptionApi,
  initialSubscription
} from "../models/ISubscription";
import {
  Configuration,
  esmaApiHeaders,
  subscriptionsPrefixURL,
  usersPrefixURL
} from "../../config";
import { Thunk } from "../../store";
import { subscriptionReducerTypes } from "../reducers/subscriptionReducer";
import {
  subscriptionFromApi,
  subscriptionToApi
} from "../services/SubscriptionService";
import { createBlockableDispatch } from "../../core/utilities/ServiceUtilities";
import {
  userCollectionFromApi,
  userFromApi
} from "../../Users/services/UserService";
import { checkError, SnackbarError } from "../../core/utilities/AlertUtilities";

export const getSubscriptionById: Thunk<subscriptionReducerTypes> = (
  id: string,
  initializedSubscription?: ISubscription
) => {
  return async (dispatch, _, opt): Promise<ISubscription | SnackbarError> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );
    dispatch({
      type: ActionType.SUBSCRIPTION_LOADING,
      payload: true
    });
    try {
      const response: AxiosResponse<ISubscriptionApi> = await axios.get(
        Configuration.EsmaAPIBaseUrl + subscriptionsPrefixURL + id,
        esmaApiHeaders
      );

      const subscription = subscriptionFromApi(
        response.data,
        initializedSubscription
      );

      blockableDispatch({
        type: ActionType.GET_SUBSCRIPTION,
        payload: subscription
      });
      return subscription;
    } catch (err) {
      return checkError(err);
    } finally {
      dispatch({
        type: ActionType.SUBSCRIPTION_LOADING,
        payload: false
      });
    }
  };
};

export const initializeSubscriptionEdit: Thunk<subscriptionReducerTypes> = (
  setLoadingState: boolean
) => {
  return async (
    dispatch,
    getState,
    opt
  ): Promise<ISubscription | SnackbarError> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );
    dispatch({
      type: ActionType.CLEAR_SUBSCRIPTION
    });
    dispatch({
      type: ActionType.SUBSCRIPTION_LOADING,
      payload: true
    });
    try {
      const response = await axios.get(
        Configuration.EsmaAPIUrl + "/users",
        esmaApiHeaders
      );

      const subscription = {
        ...initialSubscription,
        userList: userCollectionFromApi(response.data).members
      };

      blockableDispatch({
        type: ActionType.INITIALIZE_SUBSCRIPTION_EDIT,
        payload: subscription
      });
      return subscription;
    } catch (err) {
      if (err.response.status === 403) {
        const {
          globalState: {
            user: { userId }
          }
        } = getState();

        const responseUser = await axios.get(
          Configuration.EsmaAPIBaseUrl + usersPrefixURL + userId,
          esmaApiHeaders
        );

        const subscription = {
          ...initialSubscription,
          userList: [userFromApi(responseUser.data)]
        };

        blockableDispatch({
          type: ActionType.INITIALIZE_SUBSCRIPTION_EDIT,
          payload: subscription
        });
        return subscription;
      } else {
        return checkError(err);
      }
    } finally {
      if (setLoadingState) {
        dispatch({
          type: ActionType.SUBSCRIPTION_LOADING,
          payload: false
        });
      }
    }
  };
};

export const updateSubscriptionById: Thunk<subscriptionReducerTypes> = (
  id: string,
  subscription: ISubscription
) => {
  return async (dispatch): Promise<ISubscription | SnackbarError> => {
    dispatch({
      type: ActionType.SET_SUBSCRIPTION_FORM_VALUE,
      payload: subscription
    });
    dispatch({
      type: ActionType.SUBSCRIPTION_LOADING,
      payload: true
    });
    try {
      const response: AxiosResponse<ISubscriptionApi> = await axios.put(
        Configuration.EsmaAPIBaseUrl + id,
        subscriptionToApi(subscription),
        esmaApiHeaders
      );

      const updatedSubscription = subscriptionFromApi(
        response.data,
        subscription
      );

      dispatch({
        type: ActionType.UPDATE_SUBSCRIPTION,
        payload: updatedSubscription
      });
      return updatedSubscription;
    } catch (err) {
      return checkError(err);
    } finally {
      dispatch({
        type: ActionType.SUBSCRIPTION_LOADING,
        payload: false
      });
    }
  };
};

export const createSubscription: Thunk<subscriptionReducerTypes> = (
  subscription: ISubscription
) => {
  return async (dispatch): Promise<ISubscription | SnackbarError> => {
    dispatch({
      type: ActionType.SET_SUBSCRIPTION_FORM_VALUE,
      payload: subscription
    });
    dispatch({
      type: ActionType.SUBSCRIPTION_LOADING,
      payload: true
    });
    try {
      const response: AxiosResponse<ISubscriptionApi> = await axios.post(
        Configuration.EsmaAPIUrl + "/subscriptions",
        subscriptionToApi(subscription),
        esmaApiHeaders
      );
      dispatch({
        type: ActionType.CREATE_SUBSCRIPTION,
        payload: subscriptionFromApi(response.data)
      });
      return subscriptionFromApi(response.data);
    } catch (err) {
      return checkError(err);
    } finally {
      dispatch({
        type: ActionType.SUBSCRIPTION_LOADING,
        payload: false
      });
    }
  };
};

export const clearSubscription: Thunk<subscriptionReducerTypes> = () => {
  return async dispatch => {
    dispatch({ type: ActionType.CLEAR_SUBSCRIPTION });
  };
};
