import React, { FunctionComponent } from "react";
import { TableCell, TableHead, TableRow } from "@material-ui/core";

export const CalibrationTableHead: FunctionComponent = () => (
  <TableHead>
    <TableRow>
      <TableCell>Actual Value</TableCell>
      <TableCell>Device Value</TableCell>
    </TableRow>
  </TableHead>
);
CalibrationTableHead.displayName = "CalibrationTableHead";
