import {
  IDeviceCalibrationDetailsLoading,
  IDeviceCalibrationHistory,
  initialDeviceCalibrationHistory,
  IDeviceCalibrationApi,
  IDeviceCalibrationHistoryTable,
  initialDeviceCalibration
} from "../models/IDevice";
import { ActionType } from "../../models/Action.model";

export type deviceCalibrationHistoryReducerTypes =
  | {
      type: ActionType.GET_CALIBRATION_HISTORY;
      payload: { calibrationData: IDeviceCalibrationApi; historyId: string };
    }
  | {
      type: ActionType.GET_INITIAL_CALIBRATION_HISTORY;
      payload: IDeviceCalibrationHistoryTable[];
    }
  | { type: ActionType.CALIBRATION_HISTORY_LOADING; payload: boolean }
  | {
      type: ActionType.CALIBRATION_HISTORY_DETAILS_LOADING;
      payload: IDeviceCalibrationDetailsLoading;
    }
  | { type: ActionType.CLEAR_CALIBRATION_HISTORY }
  | {
      type: ActionType.ADD_CREATED_CALIBRATION_TO_HISTORY;
      payload: {
        id: string;
        date: string | undefined;
        historyData: IDeviceCalibrationHistoryTable[];
      };
    };

export const deviceCalibrationHistoryReducer = (
  state: IDeviceCalibrationHistory = { ...initialDeviceCalibrationHistory },
  action: deviceCalibrationHistoryReducerTypes
): IDeviceCalibrationHistory => {
  switch (action.type) {
    case ActionType.CALIBRATION_HISTORY_LOADING:
      state = {
        ...state,
        calibrationHistoryLoading: action.payload
      };
      break;
    case ActionType.GET_INITIAL_CALIBRATION_HISTORY:
      state = {
        ...state,
        calibrationHistory: action.payload
      };
      break;
    case ActionType.GET_CALIBRATION_HISTORY:
      state = {
        ...state,
        calibrationHistory: state.calibrationHistory.map(historyItem => {
          if (historyItem.historyId === action.payload.historyId) {
            return {
              ...historyItem,
              deviceId: action.payload.calibrationData.device,
              formula: action.payload.calibrationData.newFormula,
              parameterExpression:
                action.payload.calibrationData.currentFormula,
              parameter: action.payload.calibrationData.parameter,
              rows: action.payload.calibrationData.dataSet
            };
          }
          return historyItem;
        })
      };
      break;

    case ActionType.CALIBRATION_HISTORY_DETAILS_LOADING:
      state = {
        ...state,
        calibrationHistory: state.calibrationHistory.map((item, index) => {
          return item.historyId === action.payload.historyId
            ? { ...item, isLoading: action.payload.state }
            : item;
        })
      };
      break;
    case ActionType.CLEAR_CALIBRATION_HISTORY:
      state = {
        ...initialDeviceCalibrationHistory
      };
      break;

    case ActionType.ADD_CREATED_CALIBRATION_TO_HISTORY:
      state = {
        ...state,
        calibrationHistory: [
          {
            ...initialDeviceCalibration.currentCalibrationTable,
            historyId: action.payload.id,
            date: action.payload.date,
            isLoading: false,
            createdItem: true
          },
          ...action.payload.historyData.map(item => ({
            ...item,
            createdItem: false
          }))
        ]
      };
      break;
  }
  return state;
};
