import axios, { AxiosResponse, AxiosError } from "axios";

import { ActionType } from "../../models/Action.model";
import {
  IUser,
  IUserApi,
  initialUser,
  ISubscriptionWithRole
} from "../models/IUser";
import {
  Configuration,
  esmaApiHeaders,
  eramaApiHeaders,
  usersPrefixURL,
  subscriptionsPrefixURL
} from "../../config";
import { Thunk } from "../../store";
import { userReducerType } from "../reducers/userReducer";
import {
  createBlockableDispatch,
  ServiceUtilities
} from "../../core/utilities/ServiceUtilities";
import {
  userFromApi,
  userToApi,
  getUserHasRoles,
  getNonEmptyHasRoles
} from "../services/UserService";
import {
  subscriptionCollectionFromApi,
  subscriptionFromApi
} from "../../Subscriptions/services/SubscriptionService";
import {
  roleCollectionFromApi,
  roleFromApi
} from "../../Roles/services/RoleService";
import { ISubscription } from "../../Subscriptions/models/ISubscription";
import { checkError, SnackbarError } from "../../core/utilities/AlertUtilities";
import { IRoleApi } from "../../Roles/models/IRole";

export const getUserById: Thunk<userReducerType> = (id: string) => {
  return async (dispatch, _, opt): Promise<undefined | SnackbarError[]> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );
    dispatch({
      type: ActionType.USER_LOADING,
      payload: true
    });
    const error: (AxiosError | Error)[] = [];
    try {
      const response: AxiosResponse<IUserApi> = await axios.get(
        Configuration.EsmaAPIBaseUrl + usersPrefixURL + id,
        esmaApiHeaders
      );

      const user = userFromApi(response.data);

      const subscriptionQueries = Object.keys(response.data.hasRoles).map(
        subscriptionId =>
          axios
            .get(Configuration.EsmaAPIBaseUrl + subscriptionId, {
              ...esmaApiHeaders
            })
            .catch(err => {
              if (err.response.status !== 404) {
                error.push(err);
              }
            })
      );

      const rolesBySubscriptionQueries = Object.keys(
        response.data.hasRoles
      ).map(subscriptionId =>
        axios
          .get(Configuration.EramaAPIUrl + "/roles", {
            ...eramaApiHeaders,
            params: {
              subscription: subscriptionId
            }
          })
          .catch(err => {
            if (err.response.status !== 404) {
              error.push(err);
            }
          })
      );

      let [subscriptions, rolesBySubscription] = await Promise.all([
        Promise.all(subscriptionQueries),
        Promise.all(rolesBySubscriptionQueries)
      ]);

      subscriptions = subscriptions.filter(sub => sub);
      rolesBySubscription = rolesBySubscription.filter(role => role);

      const rolesBySubscriptionArray =
        Object.keys(response.data.hasRoles).length > 0
          ? rolesBySubscription
              .map(item => item && item.data.member)
              .reduce((flatten, arr) => [...flatten, ...arr])
              .map((role: IRoleApi) => roleFromApi(role))
          : [];

      user.hasRoles = getNonEmptyHasRoles(
        getUserHasRoles(
          response.data.hasRoles,
          subscriptions.map(subscription =>
            subscriptionFromApi(subscription && subscription.data)
          ),
          rolesBySubscriptionArray
        )
      );

      blockableDispatch({
        type: ActionType.GET_USER,
        payload: user
      });

      if (error.length > 0) {
        return error.map(error => checkError(error));
      }
    } catch (err) {
      return [checkError(err)];
    } finally {
      dispatch({
        type: ActionType.USER_LOADING,
        payload: false
      });
    }
  };
};

export const initializeUser: Thunk<userReducerType> = (
  setLoadingState: boolean
) => {
  return async (dispatch, _, opt): Promise<IUser | SnackbarError> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );
    dispatch({
      type: ActionType.CLEAR_USER
    });
    dispatch({
      type: ActionType.USER_LOADING,
      payload: true
    });
    try {
      const response = await axios.all([
        axios.get(Configuration.EsmaAPIUrl + "/subscriptions", esmaApiHeaders)
      ]);

      const user = { ...initialUser };
      let subscriptions = subscriptionCollectionFromApi(response[0].data)
        .members;
      user.subscriptionList = [];
      subscriptions.forEach((item: ISubscription) => {
        user.subscriptionList.push({
          ...item,
          roles: [],
          roleList: [],
          roleListLoading: false
        });
      });

      blockableDispatch({
        type: ActionType.INITIALIZE_USER_EDIT,
        payload: user
      });
      return user;
    } catch (err) {
      return checkError(err);
    } finally {
      if (setLoadingState) {
        dispatch({
          type: ActionType.USER_LOADING,
          payload: false
        });
      }
    }
  };
};

export const updateUserById: Thunk<userReducerType> = (
  id: string,
  user: IUser
) => {
  return async (dispatch): Promise<undefined | SnackbarError[]> => {
    dispatch({
      type: ActionType.SET_USER_FORM_VALUE,
      payload: user
    });
    dispatch({
      type: ActionType.USER_LOADING,
      payload: true
    });
    const error: (AxiosError | Error)[] = [];
    try {
      const response: AxiosResponse<IUserApi> = await axios.put(
        Configuration.EsmaAPIBaseUrl + id,
        userToApi(user),
        esmaApiHeaders
      );

      const updatedUser = userFromApi(response.data);

      const subscriptionQueries = Object.keys(response.data.hasRoles).map(
        subscriptionId =>
          axios
            .get(Configuration.EsmaAPIBaseUrl + subscriptionId, {
              ...esmaApiHeaders
            })
            .catch(err => {
              if (err.response.status !== 404) {
                error.push(err);
              }
            })
      );

      const rolesBySubscriptionQueries = Object.keys(
        response.data.hasRoles
      ).map(subscriptionId =>
        axios
          .get(Configuration.EramaAPIUrl + "/roles", {
            ...eramaApiHeaders,
            params: {
              subscription: subscriptionId
            }
          })
          .catch(err => {
            if (err.response.status !== 404) {
              error.push(err);
            }
          })
      );

      let [subscriptions, rolesBySubscription] = await Promise.all([
        Promise.all(subscriptionQueries),
        Promise.all(rolesBySubscriptionQueries)
      ]);

      subscriptions = subscriptions.filter(sub => sub);
      rolesBySubscription = rolesBySubscription.filter(role => role);

      const rolesBySubscriptionArray =
        Object.keys(response.data.hasRoles).length > 0
          ? rolesBySubscription
              .map(item => item && item.data.member)
              .reduce((flatten, arr) => [...flatten, ...arr])
              .map((role: IRoleApi) => roleFromApi(role))
          : [];

      updatedUser.hasRoles = getNonEmptyHasRoles(
        getUserHasRoles(
          response.data.hasRoles,
          subscriptions.map(subscription =>
            subscriptionFromApi(subscription && subscription.data)
          ),
          rolesBySubscriptionArray
        )
      );

      dispatch({
        type: ActionType.UPDATE_USER,
        payload: updatedUser
      });

      if (error.length > 0) {
        return error.map(error => checkError(error));
      }
    } catch (err) {
      return [checkError(err)];
    } finally {
      dispatch({
        type: ActionType.USER_LOADING,
        payload: false
      });
    }
  };
};

export const createUser: Thunk<userReducerType> = (user: IUser) => {
  return async (dispatch): Promise<IUser | SnackbarError> => {
    dispatch({
      type: ActionType.SET_USER_FORM_VALUE,
      payload: user
    });
    dispatch({
      type: ActionType.USER_LOADING,
      payload: true
    });
    try {
      const response: AxiosResponse<IUserApi> = await axios.post(
        Configuration.EsmaAPIUrl + "/users",
        userToApi(user),
        esmaApiHeaders
      );
      dispatch({
        type: ActionType.CREATE_USER,
        payload: userFromApi(response.data)
      });
      return userFromApi(response.data);
    } catch (err) {
      return checkError(err);
    } finally {
      dispatch({
        type: ActionType.USER_LOADING,
        payload: false
      });
    }
  };
};

export const getRolesBySubForUserHasRoles: Thunk<userReducerType> = (
  selectedSub: ISubscriptionWithRole[],
  userFormValue: IUser
) => {
  return async (dispatch, _, opt): Promise<IUser | SnackbarError> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );

    try {
      userFormValue.hasRoles = selectedSub;

      for (let subWithRole in userFormValue.hasRoles) {
        if (userFormValue.hasRoles.hasOwnProperty(subWithRole)) {
          if (userFormValue.hasRoles[subWithRole].roleListLoading === false) {
            const response = await axios.get(
              Configuration.EramaAPIUrl + "/roles",
              {
                ...eramaApiHeaders,
                params: {
                  subscription:
                    subscriptionsPrefixURL +
                    ServiceUtilities.getShortId(
                      userFormValue.hasRoles[subWithRole].id
                    )
                }
              }
            );

            userFormValue.hasRoles[
              subWithRole
            ].roleList = roleCollectionFromApi(response.data).members;

            userFormValue.hasRoles[subWithRole].roleListLoading = true;

            blockableDispatch({
              type: ActionType.UPDATE_USERS_HASROLES_ROLELIST,
              payload: userFormValue
            });
          }
        }
      }

      return userFormValue;
    } catch (err) {
      return checkError(err);
    }
  };
};

export const clearUser: Thunk<userReducerType> = () => {
  return async dispatch => {
    dispatch({ type: ActionType.CLEAR_USER });
  };
};
