import {
  IUserProfile,
  IUserProfileAPI,
  initialUserProfile
} from "../models/IUserProfile";
import { ServiceUtilities } from "../../core/utilities/ServiceUtilities";
import {
  initialSubscription,
  ISubscription
} from "../../Subscriptions/models/ISubscription";
import { initialRole, IRole } from "../../Roles/models/IRole";
import { ISubscriptionWithRole } from "../../Users/models/IUser";

export const fromApiFormat = (profile: IUserProfileAPI): IUserProfile => {
  const result = { ...initialUserProfile };
  result.id = profile["@id"];
  result.type = profile["@type"];
  result.shortId = ServiceUtilities.getShortId(profile["@id"]);
  result.email = profile.email;
  result.mobile = profile.mobile;
  result.name = profile.name;
  result.activeSubscription = {
    ...initialSubscription,
    id: profile.activeSubscription
  };
  result.activeRole = {
    ...initialRole,
    id: profile.activeRole
  };
  result.uiPreferences = profile.uiPreferences;
  if (profile.notificationChannels && profile.notificationChannels.length > 0) {
    profile.notificationChannels.forEach((channel: string) => {
      switch (channel) {
        case "sms":
          result.smsNotification = true;
          break;
        case "email":
          result.emailNotification = true;
          break;
        default:
          break;
      }
    });
  }

  return result;
};

export const getSubscriptionsWithRoles = (
  hasRoles: { [subscriptoin: string]: string[] },
  subscriptions: ISubscription[],
  roles: IRole[]
): ISubscriptionWithRole[] =>
  Object.keys(hasRoles).map(subscriptionId => ({
    ...(subscriptions.filter(sub => sub.id === subscriptionId)[0] ||
      initialSubscription),
    roles: roles.filter(role => hasRoles[subscriptionId].indexOf(role.id) >= 0),
    roleList: [],
    roleListLoading: false
  }));

export const getUserActiveSubRole = (user: IUserProfile): IUserProfile => {
  return {
    ...user,
    activeSubscription: user.hasRoles
      .filter(sub => sub.id === user.activeSubscription.id)
      .pop() || { ...initialSubscription },
    activeRole: user.hasRoles
      .filter(sub => sub.id === user.activeSubscription.id)[0]
      .roles.filter(role => role.id === user.activeRole.id)
      .pop() || { ...initialRole }
  };
};

export const getNonEmptyHasRoles = (
  userHasRoles: ISubscriptionWithRole[]
): ISubscriptionWithRole[] =>
  userHasRoles
    .map(item => ({
      ...item,
      roles: item.roles.filter(role => role.name !== "")
    }))
    .filter(item => item.name !== "" && item.roles.length > 0);

export const toApiFormat = (profile: IUserProfile): IUserProfileAPI => {
  const notifications = [];
  if (profile.smsNotification) {
    notifications.push("sms");
  }
  if (profile.emailNotification) {
    notifications.push("email");
  }

  const newHasRoles = {};
  profile.hasRoles.forEach(hasRole => {
    newHasRoles[hasRole.id] = hasRole.roles.map(role => role.id);
  });

  return {
    "@id": profile.id as string,
    "@type": profile.type as string,
    email: profile.email,
    activeRole: profile.activeRole.id,
    activeSubscription: profile.activeSubscription.id,
    hasRoles: newHasRoles,
    mobile: profile.mobile,
    name: profile.name,
    notificationChannels: notifications,
    uiPreferences: profile.uiPreferences
  };
};
