import { theme } from "./util/withThemeProvider";
import { AxiosRequestConfig } from "axios";

const development = process.env.NODE_ENV === "development";
const heramesApiUrl = "https://api.herames.net";

export const usersPrefixURL = "/v1/esma/users/";
export const subscriptionsPrefixURL = "/v1/esma/subscriptions/";
export const rolesPrefixURL = "/v1/erama/roles/";
export const policiesPrefixURL = "/v1/erama/policies/";
export const deviceModelsPrefixURL = "/v1/edi/devicemodels/";
export const devicesPrefixURL = "/v1/edi/devices/";

export const Configuration = {
  development,
  EdiAPIBaseUrl: development
    ? "https://zgw860m7j4.execute-api.ap-southeast-2.amazonaws.com/dev"
    : heramesApiUrl,

  EdiAPIUrl: development
    ? "https://zgw860m7j4.execute-api.ap-southeast-2.amazonaws.com/dev/v1/edi"
    : heramesApiUrl + "/v1/edi",

  EsmaAPIBaseUrl: development
    ? "https://x99oimnc68.execute-api.ap-southeast-2.amazonaws.com/dev"
    : heramesApiUrl,

  EsmaAPIUrl: development
    ? "https://x99oimnc68.execute-api.ap-southeast-2.amazonaws.com/dev/v1/esma"
    : heramesApiUrl + "/v1/esma",

  EramaAPIBaseUrl: development
    ? "https://vaxsuaptwb.execute-api.ap-southeast-2.amazonaws.com/dev"
    : heramesApiUrl,

  EramaAPIUrl: development
    ? "https://vaxsuaptwb.execute-api.ap-southeast-2.amazonaws.com/dev/v1/erama"
    : heramesApiUrl + "/v1/erama",

  AuthApiUrl: development
    ? "https://r2fypacxjf.execute-api.ap-southeast-2.amazonaws.com/dev/v1/auth/token"
    : heramesApiUrl + "/v1/auth/token",

  EcaAPIBaseUrl: development
    ? "https://k4ecd40g1d.execute-api.ap-southeast-2.amazonaws.com/dev"
    : heramesApiUrl,

  EcaAPIUrl: development
    ? "https://k4ecd40g1d.execute-api.ap-southeast-2.amazonaws.com/dev/v1/eca"
    : heramesApiUrl + "/v1/eca",

  EdsaAPIBaseUrl: development
    ? "https://6o9vlm6gfl.execute-api.ap-southeast-2.amazonaws.com/dev"
    : heramesApiUrl,

  EdsaAPIUrl: development
    ? "https://6o9vlm6gfl.execute-api.ap-southeast-2.amazonaws.com/dev/v1/edsa"
    : heramesApiUrl + "/v1/edsa",

  menuWidth: 300,
  menuHoverColor: "rgba(0, 0, 0, 0.12)",
  rowsPerTablePage: 30,
  SubscriptionsRowsPerTablePage: 100,
  RolesRowsPerTablePage: 100,
  PoliciesRowsPerTablePage: 100,
  isMobile: window.innerWidth > theme.breakpoints.values.md,
  clientVersionCheckIntervalMs: 10000,
  snackbarAutohideDuration: {
    success: 4000,
    error: 15000
  },

  auth: development
    ? {
        cognitoRoot: "https://nimausers.auth.ap-southeast-2.amazoncognito.com/",
        login: "login",
        logout: "logout",
        redirectUri: "http://localhost:3000/",
        clientId: "56475e2uadjv4jae48ls3p5gdg"
      }
    : {
        cognitoRoot:
          "https://beta-ellenex-net.auth.ap-southeast-2.amazoncognito.com/",
        login: "login",
        logout: "logout",
        redirectUri: document.location ? document.location.origin : "",
        clientId: "ibq5s4341ha3iscp5qk9v5i10"
      },
  permissions: {
    access: [
      { text: "Allow", value: "Allow" },
      { text: "Deny", value: "Deny" }
    ],
    methods: [
      { text: "GET", value: "GET" },
      { text: "POST", value: "POST" },
      { text: "DELETE", value: "DELETE" },
      { text: "PUT", value: "PUT" }
    ]
  },
  units: [
    { unitCode: "", unitText: "" },
    { unitCode: "MTR", unitText: "m" },
    { unitCode: "CEL", unitText: "°C" },
    { unitCode: "FAH", unitText: "°F" },
    { unitCode: "VLT", unitText: "V" },
    { unitCode: "BAR", unitText: "bar" },
    { unitCode: "PAL", unitText: "Pa" },
    { unitCode: "UGA", unitText: "U.S. Gallon" },
    { unitCode: "L", unitText: "L" },
    { unitCode: "MAL", unitText: "ML" },
    { unitCode: "G/ML", unitText: "g/mL" },
    { unitCode: "%", unitText: "%" }
  ],
  calibration: {
    historyTableDateFormat: "LL"
  }
};

export const ediApiHeaders: AxiosRequestConfig = {
  headers: development
    ? {
        "x-api-key": "3HUp0NiyTLpslBkrhVEt7pNMvh9oSul4WGKgbrl4"
      }
    : {
        "x-api-key": "6QYFxhuG7e8EASKxD6BpF2SFGv1WyEZx2i6BQGr4"
      }
};

export const esmaApiHeaders: AxiosRequestConfig = {
  headers: development
    ? {
        "x-api-key": "wuO5LFbN7c7HyiKMStpAO2AdyaqYJv9mDduvz6Z0"
      }
    : {
        "x-api-key": "lHXlMHYfC39dFUqkHcBdralH7fggUNRuSGOnzZTa"
      }
};

export const authApiHeaders: AxiosRequestConfig = {
  headers: development
    ? {
        "x-api-key": "J6i8kwTLcfamIeC2aXZOc5oBcU19yjYQuyZ8nBnc"
      }
    : {
        "x-api-key": "Nmgs3RYCB2zi9wLfFMvR1BU8qEtf3QiaMdVALocj"
      }
};

export const eramaApiHeaders: AxiosRequestConfig = {
  headers: development
    ? {
        "x-api-key": "DpIUFJ7KpzaDr96kIedRM440mRJq2DTO9L0e9hQq"
      }
    : {
        "x-api-key": "CVhPZkMS538XDP8ELbGzuaEU063cMain790tk38W"
      }
};

export const ecaApiHeaders: AxiosRequestConfig = {
  headers: development
    ? {
        "x-api-key": "2gwzF765zhxVxgqTS7TrP4JVrMfsYyHAxA8dc9eN"
      }
    : {
        "x-api-key": "soosaKo6Bu5ofoZoaHauhie9phoo9Xeeop1Feiz7"
      }
};

export const edsaApiHeaders: AxiosRequestConfig = {
  headers: development
    ? {
        "x-api-key": "7uGegXGEq310z9KCEdLCiaTf3RWZKHQZ8ZqgwWIV"
      }
    : {
        "x-api-key": "7uGegXGEq310z9KCEdLCiaTf3RWZKHQZ8ZqgwWIV"
      }
};
