import React, { FunctionComponent } from "react";
import { IDeviceCalibrationTable } from "../../../models/IDevice";
import { CalibrationTableRow } from "./CalibrationTableRow";
import { Table, TableBody } from "@material-ui/core";
import { CalibrationTableHead } from "./CalibrationTableHead";
import { CalibrationTableFooter } from "./CalibrationTableFooter";

export const DeviceCalibrationTable: FunctionComponent<{
  calibration: IDeviceCalibrationTable;
}> = ({ calibration: { rows, parameterExpression, formula } }) => {
  return (
    <Table>
      <CalibrationTableHead />
      <TableBody>
        {rows.map(({ actualValue, deviceValue }, index) => (
          <CalibrationTableRow
            key={index}
            actualValue={actualValue}
            deviceValue={deviceValue}
          />
        ))}
      </TableBody>
      <CalibrationTableFooter
        currentFormula={parameterExpression}
        newFormula={formula}
      />
    </Table>
  );
};
DeviceCalibrationTable.displayName = "DeviceCalibrationTable";
