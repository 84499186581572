import React from "react";
import styled from "styled-components";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Menu,
  MenuItem
} from "@material-ui/core";
import { ArrowDropDown, Menu as MenuIcon } from "@material-ui/icons";

import { NoStyleLink } from "./sharedStyledComponents";
import { theme } from "../util/withThemeProvider";
import { IUserProfile } from "../Profile/models/IUserProfile";

export const StyledHeaderTitle = styled(Typography)`
  flex-grow: 1;

  @media (max-width: 450px) {
    && {
      font-size: 15px;
    }
  }
`;
StyledHeaderTitle.displayName = "StyledHeaderTitle";

export const SyledMenuButton = styled(IconButton)`
  && {
    margin-left: -12px;
    margin-right: 20px;
  }
`;
SyledMenuButton.displayName = "SyledMenuButton";

export const StyledAppBar = styled(AppBar)`
  &&{z-index: ${theme.zIndex.drawer + 1}
    position: fixed;
  };
`;
StyledAppBar.displayName = "StyledAppBar";

export const StyledImage = styled.img`
  height: 30px;
  margin-right: 10px;
`;
StyledImage.displayName = "StyledImage";

interface IProps {
  toolbarMenuOpen: any;
  handleClose: () => void;
  handleOpen: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  logout: () => void;
  menuClick: () => void;
  user: IUserProfile;
}

export const Header: React.FunctionComponent<IProps> = ({
  menuClick,
  toolbarMenuOpen,
  handleOpen,
  user: {
    name,
    shortId,
    activeSubscription: { name: activeSubName },
    activeRole: { name: activeRoleName }
  },
  handleClose,
  logout
}: IProps) => (
  <StyledAppBar position="static">
    <Toolbar>
      <SyledMenuButton color="inherit" aria-label="Menu" onClick={menuClick}>
        <MenuIcon />
      </SyledMenuButton>
      <StyledImage src="/img/logo-white.png" />
      <StyledHeaderTitle variant="h6" color="inherit">
        Ellenex Administration
      </StyledHeaderTitle>

      <Button
        aria-owns={toolbarMenuOpen ? "toolbar-menu" : undefined}
        aria-haspopup={true}
        color="inherit"
        data-type="toolbar-menu"
        onClick={handleOpen}
      >
        {name}
        {name && <ArrowDropDown />}
      </Button>
      <Menu
        id="toolbar-menu"
        anchorEl={toolbarMenuOpen}
        open={Boolean(toolbarMenuOpen)}
        onClose={handleClose}
        disableAutoFocusItem={true}
      >
        <MenuItem onClick={logout}>Sign Out</MenuItem>
        <NoStyleLink
          hidden={!Boolean(shortId && activeSubName && activeRoleName)}
          to={shortId ? "/profile/" + shortId : ""}
          id="switchRole"
        >
          <MenuItem onClick={handleClose}>Switch Role</MenuItem>
        </NoStyleLink>
      </Menu>
    </Toolbar>
  </StyledAppBar>
);
