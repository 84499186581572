import axios, { AxiosResponse, AxiosError } from "axios";

import { ActionType } from "../../models/Action.model";
import {
  Configuration,
  esmaApiHeaders,
  eramaApiHeaders,
  usersPrefixURL,
  subscriptionsPrefixURL
} from "../../config";
import { Thunk } from "../../store";
import { checkError, SnackbarError } from "../../core/utilities/AlertUtilities";
import {
  addUserBySubscriptionToApi,
  inviteUserBySubscriptionToApi,
  getSubscriptionObject,
  removeUserBySubscriptionToApi,
  uniqueSubCollection
} from "../services/UsersBySubscriptionService";
import {
  IAddUserBySubscription,
  IInviteUserBySubscription,
  initialUserBySubscription,
  IUserBySubscription,
  IUserBySubscriptionCollection
} from "../models/IUserBySubscription";
import { userBySubscriptionReducerType } from "../reducers/userBySubscriptionReducer";
import {
  ISubscriptionCollection,
  ISubscriptionCollectionApi
} from "../../Subscriptions/models/ISubscription";
import { subscriptionCollectionFromApi } from "../../Subscriptions/services/SubscriptionService";
import {
  createBlockableDispatch,
  ServiceUtilities
} from "../../core/utilities/ServiceUtilities";
import {
  roleCollectionFromApi,
  roleFromApi
} from "../../Roles/services/RoleService";
import { IRole } from "../../Roles/models/IRole";
import { getUserCollection } from "./UsersBySubscriptionCollectionAction";
import { ITableDeleteCell } from "../../models/shared/IShared";

export const initializeUserBySubscription: Thunk<
  userBySubscriptionReducerType
> = (subId: string) => {
  return async (
    dispatch,
    _,
    opt
  ): Promise<IUserBySubscription | SnackbarError> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );
    dispatch({
      type: ActionType.USER_BY_SUBSCRIPTION_LOADING,
      payload: true
    });

    try {
      const response = await axios.get(Configuration.EramaAPIUrl + "/roles", {
        ...eramaApiHeaders,
        params: {
          subscription:
            subscriptionsPrefixURL + ServiceUtilities.getShortId(subId)
        }
      });

      const userBySubscription = { ...initialUserBySubscription };
      userBySubscription.roleList = roleCollectionFromApi(
        response.data
      ).members;

      blockableDispatch({
        type: ActionType.INITIALIZE_USER_BY_SUBSCRIPTION,
        payload: userBySubscription
      });
      return userBySubscription;
    } catch (err) {
      return checkError(err);
    } finally {
      dispatch({
        type: ActionType.USER_BY_SUBSCRIPTION_LOADING,
        payload: false
      });
    }
  };
};

export const addUser: Thunk<userBySubscriptionReducerType> = (
  user: IUserBySubscription,
  subId: string
) => {
  return async (dispatch): Promise<IAddUserBySubscription | SnackbarError> => {
    dispatch({
      type: ActionType.SET_ADD_USER_FORM_VALUE,
      payload: user
    });
    dispatch({
      type: ActionType.USER_BY_SUBSCRIPTION_LOADING,
      payload: true
    });
    try {
      const response: AxiosResponse<IAddUserBySubscription> = await axios.patch(
        Configuration.EsmaAPIBaseUrl + subId + "/members",
        addUserBySubscriptionToApi(user),
        esmaApiHeaders
      );

      return response.data;
    } catch (err) {
      return checkError(err, "addUser");
    } finally {
      dispatch({
        type: ActionType.USER_BY_SUBSCRIPTION_LOADING,
        payload: false
      });
    }
  };
};

export const updateUser: Thunk<userBySubscriptionReducerType> = (
  user: IUserBySubscription,
  subId: string
) => {
  return async (dispatch): Promise<undefined | SnackbarError[]> => {
    dispatch({
      type: ActionType.SET_ADD_USER_FORM_VALUE,
      payload: user
    });
    dispatch({
      type: ActionType.USER_BY_SUBSCRIPTION_LOADING,
      payload: true
    });
    const error: (AxiosError | Error)[] = [];
    try {
      const response: AxiosResponse<IUserBySubscription> = await axios.put(
        Configuration.EsmaAPIBaseUrl + subId + "/members",
        addUserBySubscriptionToApi(user),
        esmaApiHeaders
      );

      let roles: IRole[] = [];

      try {
        const roleQueries = response.data.roles.map(roleId =>
          axios
            .get(Configuration.EramaAPIBaseUrl + roleId, {
              ...eramaApiHeaders
            })
            .catch(err => {
              if (err.response.status !== 404) {
                error.push(err);
              }
            })
        );

        let roleQueriesResult = await Promise.all(roleQueries);

        roles = roleQueriesResult
          .filter(role => role)
          .map(role => roleFromApi(role && role.data));
      } catch (err) {
        return [checkError(err)];
      }

      dispatch({
        type: ActionType.UPDATE_USER_BY_SUBSCRIPTION,
        payload: { ...response.data, roles: roles }
      });
    } catch (err) {
      return [checkError(err)];
    } finally {
      dispatch({
        type: ActionType.USER_BY_SUBSCRIPTION_LOADING,
        payload: false
      });
    }
  };
};

export const inviteUser: Thunk<userBySubscriptionReducerType> = (
  user: IInviteUserBySubscription,
  subId: string,
  roles: IRole[]
) => {
  return async (
    dispatch
  ): Promise<IInviteUserBySubscription | SnackbarError> => {
    try {
      const response: AxiosResponse<
        IInviteUserBySubscription
      > = await axios.post(
        Configuration.EsmaAPIBaseUrl + usersPrefixURL + "invite",
        inviteUserBySubscriptionToApi(user.email),
        esmaApiHeaders
      );

      dispatch(
        addUser(
          { ...initialUserBySubscription, email: user.email, roles: roles },
          subId
        )
      );

      return response.data;
    } catch (err) {
      return checkError(err);
    }
  };
};

export const removeUserBySubscription: Thunk<userBySubscriptionReducerType> = (
  userInfo: ITableDeleteCell,
  subId: string
) => {
  return async (dispatch): Promise<ITableDeleteCell | SnackbarError> => {
    dispatch({
      type: ActionType.USER_BY_SUBSCRIPTION_LOADING,
      payload: true
    });
    try {
      const response = await axios.put(
        Configuration.EsmaAPIBaseUrl + subId + "/members",
        removeUserBySubscriptionToApi(userInfo),
        esmaApiHeaders
      );

      dispatch({
        type: ActionType.DELETE_USER_BY_SUB_DIALOG,
        payload: { ...userInfo, visible: false }
      });

      dispatch(getUserCollection(subId));

      return response.data;
    } catch (err) {
      return checkError(err);
    } finally {
      dispatch({
        type: ActionType.USER_BY_SUBSCRIPTION_LOADING,
        payload: false
      });
    }
  };
};

export const currentSubscriptionCustomerAdmin: Thunk<
  userBySubscriptionReducerType
> = (subId: string, initial?: boolean) => {
  return async (dispatch, getState) => {
    dispatch({
      type: ActionType.USER_BY_SUBSCRIPTION_SELECTION_LOADING,
      payload: true
    });

    const {
      userProfileReducer: { activeSubscription },
      userBySubscription: { subscriptionList }
    } = getState();

    if (initial) {
      try {
        const responseSubs = await axios.get(
          Configuration.EsmaAPIUrl + "/subscriptions",
          esmaApiHeaders
        );
        const subscriptions: ISubscriptionCollection = subscriptionCollectionFromApi(
          responseSubs.data as ISubscriptionCollectionApi
        );

        const uniqueSubCollectionResult = uniqueSubCollection(
          subscriptions,
          activeSubscription
        );

        dispatch({
          type: ActionType.GET_SUBSCRIPTION_COLLECTION_USER_BY_SUBSCRIPTION,
          payload: uniqueSubCollectionResult
        });

        let sub = getSubscriptionObject(subId, uniqueSubCollectionResult);
        if (!sub.value) {
          sub = getSubscriptionObject(
            uniqueSubCollectionResult[0].value,
            uniqueSubCollectionResult
          );
        }

        dispatch({
          type: ActionType.CURRENT_SUBSCRIPTION_CUSTOMER_ADMIN,
          payload: sub
        });
        dispatch(getUserCollection(sub.value));
      } catch (err) {
        return checkError(err);
      }
    } else {
      const sub = getSubscriptionObject(subId, subscriptionList);

      dispatch({
        type: ActionType.CURRENT_SUBSCRIPTION_CUSTOMER_ADMIN,
        payload: sub
      });
      dispatch(getUserCollection(sub.value));
    }

    dispatch({
      type: ActionType.USER_BY_SUBSCRIPTION_SELECTION_LOADING,
      payload: false
    });
  };
};

export const inviteUserDialogOpen: Thunk<userBySubscriptionReducerType> = (
  status: boolean
) => {
  return async dispatch => {
    dispatch({ type: ActionType.INVITE_USER_DIALOG_OPEN, payload: status });
  };
};

export const inviteUserInitialValue: Thunk<userBySubscriptionReducerType> = (
  user: IInviteUserBySubscription
) => {
  return async dispatch => {
    dispatch({ type: ActionType.INVITE_USER_INITIAL_VALUE, payload: user });
  };
};

export const clearUserBySubscription: Thunk<
  userBySubscriptionReducerType
> = () => {
  return async dispatch => {
    dispatch({ type: ActionType.CLEAR_USER_BY_SUBSCRIPTION });
  };
};

export const clearCurrentSubUserBySubscription: Thunk<
  userBySubscriptionReducerType
> = () => {
  return dispatch => {
    dispatch({ type: ActionType.CLEAR_CURRENT_SUB_USER_BY_SUBSCRIPTION });
  };
};

export const setUserBySubEditFormValue: Thunk<userBySubscriptionReducerType> = (
  userId: string,
  usersList: IUserBySubscriptionCollection,
  userBySub: IUserBySubscription
) => {
  const member = usersList.members
    .filter(item => ServiceUtilities.getShortId(item.id) === userId)
    .pop() || { ...initialUserBySubscription };

  return async dispatch => {
    dispatch({
      type: ActionType.SET_USER_BY_SUBSCRIPTION_STATE_FOR_EDIT,
      payload: { ...member, roleList: userBySub.roleList }
    });
  };
};

export const userBySubscriptionDeleteConfirmDialog: Thunk<
  userBySubscriptionReducerType
> = (userInfo: ITableDeleteCell) => {
  return async dispatch => {
    dispatch({ type: ActionType.DELETE_USER_BY_SUB_DIALOG, payload: userInfo });
  };
};
