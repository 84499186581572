import React from "react";
import { connect } from "react-redux";
import { Grid, Button } from "@material-ui/core";
import { Formik } from "formik";
import { FormikActions, FormikProps } from "formik/dist/types";
import { RouteComponentProps } from "react-router";

import { withThemeProvider } from "../util/withThemeProvider";
import { IRootState } from "../store";
import { IPolicy, IPolicyCollection } from "./models/IPolicy";
import { CreatePolicyForm } from "./components/CreatePolicyForm";
import { EditPolicyForm } from "./components/EditPolicyForm";
import {
  clearPolicy,
  createPolicy,
  getPolicyById,
  initializePolicyEdit,
  updatePolicyById
} from "./actions/policyAction";
import {
  NoStyleLink,
  StyledTitle,
  StyledBackground,
  StyledFormWrapper,
  StyledListIcon
} from "../components/sharedStyledComponents";
import { withSnackbar, WithSnackbarProps } from "notistack";
import {
  alertMessages,
  SnackbarError,
  isSnackbarError,
  getStyledSnackbarOptions
} from "../core/utilities/AlertUtilities";

interface IPathParamsType {
  id: string;
}

interface IEditPolicyStateProps {
  policyCollection: IPolicyCollection;
  policy: IPolicy;
}

interface IEditPolicyDispatchProps {
  initializePolicy: (
    setLoadingState: boolean
  ) => Promise<IPolicy | SnackbarError>;
  createPolicy: (policy: IPolicy) => Promise<IPolicy | SnackbarError>;
  getPolicy: (
    id: string,
    initializedPolicy?: IPolicy
  ) => Promise<IPolicy | SnackbarError>;
  updatePolicy: (
    id: string,
    policy: IPolicy
  ) => Promise<IPolicy | SnackbarError>;
  clearPolicy: () => void;
}

type EditPolicyType = IEditPolicyStateProps &
  IEditPolicyDispatchProps &
  RouteComponentProps<IPathParamsType> &
  WithSnackbarProps;

export class EditPolicies extends React.Component<EditPolicyType> {
  public async componentDidMount() {
    const {
      initializePolicy,
      getPolicy,
      enqueueSnackbar,
      match: {
        params: { id }
      },
      policyCollection: {
        policySubscriptionSelection: { value: policySubscriptionSelectionValue }
      },
      history
    } = this.props;

    if (policySubscriptionSelectionValue) {
      const result = await initializePolicy(!Boolean(id));

      if (isSnackbarError(result)) {
        enqueueSnackbar(result.message, result.options);
      } else if (id) {
        const getPolicyResult = await getPolicy(id, result);

        if (isSnackbarError(getPolicyResult)) {
          enqueueSnackbar(getPolicyResult.message, getPolicyResult.options);
        }
      }
    } else {
      history.push("/policies");
    }
  }

  public componentWillUnmount() {
    const {
      clearPolicy,
      match: {
        params: { id }
      }
    } = this.props;

    if (id) {
      clearPolicy();
    }
  }

  public renderCreatePolicyForm = (formikProps: FormikProps<IPolicy>) => {
    const {
      policyCollection: { policySubscriptionSelection }
    } = this.props;

    return (
      <CreatePolicyForm
        {...formikProps}
        policySubscriptionSelection={policySubscriptionSelection}
      />
    );
  };

  public onSubmit = async (
    values: IPolicy,
    { setSubmitting }: FormikActions<IPolicy>
  ) => {
    const {
      createPolicy,
      updatePolicy,
      history,
      enqueueSnackbar,
      match: {
        params: { id }
      }
    } = this.props;
    if (values) {
      if (id) {
        const updateResult = await updatePolicy(values.id, values);

        if (!isSnackbarError(updateResult) && updateResult !== undefined) {
          enqueueSnackbar(
            alertMessages.POLICY_UPDATE_SUCCESS,
            getStyledSnackbarOptions("success")
          );
        } else if (isSnackbarError(updateResult)) {
          enqueueSnackbar(updateResult.message, updateResult.options);
        }
      } else {
        const createResult = await createPolicy(values);

        if (!isSnackbarError(createResult) && createResult !== undefined) {
          enqueueSnackbar(
            alertMessages.POLICY_CREATE_SUCCESS,
            getStyledSnackbarOptions("success")
          );
          history.push("/policies/" + createResult.shortId);
        } else if (isSnackbarError(createResult)) {
          enqueueSnackbar(createResult.message, createResult.options);
        }
      }
      setSubmitting(false);
    }
  };

  public render() {
    const {
      policy,
      match: {
        params: { id }
      }
    } = this.props;

    return (
      <React.Fragment>
        <StyledFormWrapper>
          <StyledTitle marginbottom="10px">
            {id
              ? policy.isLoading
                ? "Loading..."
                : policy.name
              : "New Policy"}
          </StyledTitle>
          <StyledBackground>
            <Grid container={true} item={true} xs={12} justify="flex-end">
              <NoStyleLink to="/policies">
                <Button color="default">
                  <StyledListIcon />
                  List
                </Button>
              </NoStyleLink>
            </Grid>
            {id ? (
              <Formik
                enableReinitialize={true}
                initialValues={policy}
                onSubmit={this.onSubmit}
                render={EditPolicyForm}
              />
            ) : (
              <Formik
                enableReinitialize={true}
                initialValues={policy}
                onSubmit={this.onSubmit}
                render={this.renderCreatePolicyForm}
              />
            )}
          </StyledBackground>
        </StyledFormWrapper>

        <br />
        <br />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IRootState): IEditPolicyStateProps => ({
  policyCollection: state.policyCollection,
  policy: state.policy
});

const mapDispatchToProps = (dispatch: any): IEditPolicyDispatchProps => {
  return {
    initializePolicy: (
      setLoadingState: boolean
    ): Promise<IPolicy | SnackbarError> => {
      return dispatch(initializePolicyEdit(setLoadingState));
    },
    createPolicy: (policy: IPolicy): Promise<IPolicy | SnackbarError> => {
      return dispatch(createPolicy(policy));
    },
    getPolicy: (
      id: string,
      initializedPolicy?: IPolicy
    ): Promise<IPolicy | SnackbarError> => {
      return dispatch(getPolicyById(id, initializedPolicy));
    },
    updatePolicy: (
      id: string,
      policy: IPolicy
    ): Promise<IPolicy | SnackbarError> => {
      return dispatch(updatePolicyById(id, policy));
    },
    clearPolicy: () => {
      dispatch(clearPolicy());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withThemeProvider(withSnackbar(EditPolicies)));
