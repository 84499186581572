import React from "react";
import styled, { keyframes } from "styled-components";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";

import { IDeviceCalibrationHistory } from "../../models/IDevice";
import { DeviceCalibrationTable } from "./table/CalibrationTable";
import { CalibrationHistoryItemTitle } from "./ExpansionPanelTitle";

interface IProps {
  historyList: IDeviceCalibrationHistory;
  getDeviceCalibrationDetails: (id: string) => void;
}

interface IExpansionPanelProps {
  panel: string;
  id: string;
}

export const Highlight = keyframes`
  0% {
    background-color: #ddd
  }
  100% {
    background-color: #fff
  }
`;

export const PanelSummary = styled(ExpansionPanelSummary)`
  animation-name: ${Highlight};
  animation-duration: 6s;
`;
PanelSummary.displayName = "PanelSummary";

export const ExpansionPanelComponent: React.FunctionComponent<IProps> = ({
  historyList: { calibrationHistory, calibrationHistoryState },
  getDeviceCalibrationDetails
}: IProps) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const expansionPanelHandleChange = ({ panel, id }: IExpansionPanelProps) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false);
    if (isExpanded) {
      getDeviceCalibrationDetails(id);
    }
  };

  return (
    <React.Fragment>
      {calibrationHistory.map((calibrationTable, index) => (
        <ExpansionPanel
          key={index}
          expanded={expanded === `panel${index}`}
          onChange={expansionPanelHandleChange({
            panel: `panel${index}`,
            id: calibrationTable.historyId
          })}
        >
          {calibrationTable.createdItem ? (
            <PanelSummary expandIcon={<ExpandMore />}>
              <CalibrationHistoryItemTitle
                isLoading={calibrationTable.isLoading}
                date={calibrationTable.date}
              />
            </PanelSummary>
          ) : (
            <ExpansionPanelSummary expandIcon={<ExpandMore />}>
              <CalibrationHistoryItemTitle
                isLoading={calibrationTable.isLoading}
                date={calibrationTable.date}
              />
            </ExpansionPanelSummary>
          )}
          <ExpansionPanelDetails>
            <DeviceCalibrationTable calibration={calibrationTable} />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
    </React.Fragment>
  );
};
ExpansionPanelComponent.displayName = "ExpansionPanelComponent";