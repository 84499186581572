import {
  initialUser,
  initialUserCollection,
  IUser,
  IUserApi,
  IUserCollection,
  IUserCollectionApi,
  ISubscriptionWithRole
} from "../models/IUser";
import { ServiceUtilities } from "../../core/utilities/ServiceUtilities";
import {
  initialSubscription,
  ISubscription
} from "../../Subscriptions/models/ISubscription";
import { store } from "../../store";
import { IRole } from "../../Roles/models/IRole";
import { IMobileTableRow } from "../../models/shared/IShared";

export const userFromApi = (user: IUserApi): IUser => {
  const result = { ...initialUser };
  result.id = user["@id"];
  result.shortId = ServiceUtilities.getShortId(user["@id"]);
  result.type = user["@type"];
  result.email = user.email;
  result.activeRole = user.activeRole;
  result.activeSubscription = user.activeSubscription;
  result.mobile = user.mobile;
  result.name = user.name;
  if (user.notificationChannels && user.notificationChannels.length > 0) {
    user.notificationChannels.forEach((channel: string) => {
      switch (channel) {
        case "sms":
          result.smsNotification = true;
          break;
        case "email":
          result.emailNotification = true;
          break;
      }
    });
  }

  return result;
};

export const userToApi = (user: IUser): IUserApi => {
  const notifications = [];
  if (user.smsNotification) {
    notifications.push("sms");
  }
  if (user.emailNotification) {
    notifications.push("email");
  }

  const newHasRoles = {};
  user.hasRoles.forEach(hasRole => {
    newHasRoles[hasRole.id] = hasRole.roles.map(role => role.id);
  });

  if (
    !newHasRoles.hasOwnProperty(user.activeSubscription) ||
    !newHasRoles[user.activeSubscription].includes(user.activeRole)
  ) {
    user.activeSubscription = "";
    user.activeRole = "";
  }

  return {
    "@id": user.id,
    "@type": user.type,
    name: user.name,
    email: user.email,
    activeRole: user.activeRole,
    activeSubscription: user.activeSubscription,
    hasRoles: newHasRoles,
    mobile: user.mobile,
    notificationChannels: notifications
  };
};

export const userCollectionFromApi = (
  collection: IUserCollectionApi
): IUserCollection => ({
  ...initialUserCollection,
  id: collection["@id"],
  shortId: ServiceUtilities.getShortId(collection["@id"]),
  type: collection["@type"],
  context: collection["@context"],
  totalItems: collection.totalItems,
  members: collection.member.map(user => userFromApi(user)),
  view: collection.view
});

export const checkCustomerAdmin = (): boolean =>
  store.getState().globalState.customerAdmin;

export const usersToMobileTableRow = (members: IUser[]): IMobileTableRow[] => {
  const result = members.map(item => {
    return {
      id: item.id,
      title: item.name,
      fields: [
        { label: "Email", value: item.email, noWrap: true },
        { label: "Mobile", value: item.mobile }
      ],
      editPageLink: `/users/${ServiceUtilities.getShortId(item.id)}`
    };
  });

  return result;
};

export const getUserHasRoles = (
  hasRoles: { [subscriptoin: string]: string[] },
  subscriptions: ISubscription[],
  roleList: IRole[]
): ISubscriptionWithRole[] =>
  Object.keys(hasRoles).map(subscriptionId => ({
    ...(subscriptions.filter(sub => sub.id === subscriptionId)[0] ||
      initialSubscription),
    roles: roleList.filter(
      role => hasRoles[subscriptionId].indexOf(role.id) >= 0
    ),
    roleList: roleList.filter(role => role.subscription.id === subscriptionId),
    roleListLoading: true
  }));

export const getNonEmptyHasRoles = (
  userHasRoles: ISubscriptionWithRole[]
): ISubscriptionWithRole[] =>
  userHasRoles
    .map(item => ({
      ...item,
      roles: item.roles.filter(role => role.name !== "")
    }))
    .filter(item => item.name !== "");
