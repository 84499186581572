import React from "react";
import { Form, FormikProps } from "formik";
import { Button, LinearProgress, Grid } from "@material-ui/core";

import { StyledTextField, TextField } from "../../components/TextField";
import { IInviteUserBySubscription } from "../models/IUserBySubscription";

export const InviteUser: React.FunctionComponent<
  FormikProps<IInviteUserBySubscription>
> = ({ submitForm, isSubmitting }: FormikProps<IInviteUserBySubscription>) => {
  return (
    <Form>
      <StyledTextField
        type="text"
        label="Email"
        name="email"
        multiline={false}
        component={TextField}
        disabled={true}
      />
      <br />
      {isSubmitting && <LinearProgress />}
      <br />

      <Grid
        container={true}
        item={true}
        direction="row"
        justify="flex-start"
        alignItems="center"
      >
        <Button
          variant="contained"
          color="primary"
          disabled={isSubmitting}
          onClick={submitForm}
        >
          Invite & Add
        </Button>
      </Grid>
    </Form>
  );
};
