import React from "react";
import { connect } from "react-redux";
import { Button, Grid } from "@material-ui/core";
import { Formik } from "formik";
import { FormikActions, FormikProps } from "formik/dist/types";
import { RouteComponentProps } from "react-router";

import { withThemeProvider } from "../util/withThemeProvider";
import { IRootState } from "../store";
import { IDevice } from "./models/IDevice";
import { IGlobalState } from "../models/GlobalState";
import { CreateDeviceForm } from "./components/CreateDeviceForm";
import { EditDeviceForm } from "./components/EditDeviceForm";
import {
  clearDevice,
  createDevice,
  getDeviceById,
  initializeDeviceEdit,
  updateDeviceById,
  getDeviceModelsBySub
} from "./actions/deviceAction";
import {
  NoStyleLink,
  StyledTitle,
  StyledBackground,
  StyledFormWrapper,
  StyledListIcon,
  SmallBuildIcon
} from "../components/sharedStyledComponents";
import { withSnackbar, WithSnackbarProps } from "notistack";
import {
  alertMessages,
  isSnackbarError,
  SnackbarError,
  getStyledSnackbarOptions
} from "../core/utilities/AlertUtilities";
import { ISubscription } from "../Subscriptions/models/ISubscription";

interface IPathParamsType {
  id: string;
}

interface IEditDeviceStateProps {
  device: IDevice;
  global: IGlobalState;
}

interface IEditDeviceDispatchProps {
  initializeDevice: (
    setLoadingState: boolean,
    subId: string
  ) => Promise<IDevice | SnackbarError>;
  createDevice: (device: IDevice) => Promise<IDevice | SnackbarError>;
  getDevice: (
    id: string,
    initializedDevice?: IDevice
  ) => Promise<IDevice | SnackbarError>;
  updateDevice: (
    id: string,
    device: IDevice
  ) => Promise<IDevice | SnackbarError>;
  getDeviceModelsBySub: (
    sub: ISubscription,
    deviceFormValue: IDevice
  ) => Promise<IDevice | SnackbarError>;
  clearDevice: () => void;
}

type EditDeviceType = IEditDeviceStateProps &
  IEditDeviceDispatchProps &
  RouteComponentProps<IPathParamsType> &
  WithSnackbarProps;

export class EditDevice extends React.Component<EditDeviceType> {
  public async componentDidMount() {
    const {
      initializeDevice,
      getDevice,
      enqueueSnackbar,
      match: {
        params: { id }
      },
      global: {
        user: { subId }
      }
    } = this.props;

    const result = await initializeDevice(!Boolean(id), subId);

    if (isSnackbarError(result)) {
      enqueueSnackbar(result.message, result.options);
    } else if (id) {
      const getDeviceResult = await getDevice(id, result);

      if (isSnackbarError(getDeviceResult)) {
        enqueueSnackbar(getDeviceResult.message, getDeviceResult.options);
      }
    }
  }

  public componentWillUnmount() {
    const {
      device: { subscription },
      clearDevice,
      match: {
        params: { id }
      },
      global: {
        user: { subId }
      }
    } = this.props;

    if (id || subscription.shortId !== subId) {
      clearDevice();
    }
  }

  public onSubmit = async (
    values: IDevice,
    { setSubmitting }: FormikActions<IDevice>
  ) => {
    const {
      createDevice,
      updateDevice,
      history,
      enqueueSnackbar,
      global: {
        user: { subId }
      },
      match: {
        params: { id }
      }
    } = this.props;
    if (values) {
      if (id) {
        const updateResult = await updateDevice(values.id, values);

        if (!isSnackbarError(updateResult) && updateResult !== undefined) {
          enqueueSnackbar(
            alertMessages.DEVICE_UPDATE_SUCCESS,
            getStyledSnackbarOptions("success")
          );
          if (updateResult.subscription.shortId !== subId) {
            history.push("/devices");
          }
        } else if (isSnackbarError(updateResult)) {
          enqueueSnackbar(updateResult.message, updateResult.options);
        }
      } else {
        const createResult = await createDevice(values);

        if (!isSnackbarError(createResult) && createResult !== undefined) {
          enqueueSnackbar(
            alertMessages.DEVICE_CREATE_SUCCESS,
            getStyledSnackbarOptions("success")
          );

          if (createResult.subscription.shortId === subId) {
            history.push("/devices/" + createResult.shortId);
          } else {
            history.push("/devices");
          }
        } else if (isSnackbarError(createResult)) {
          enqueueSnackbar(createResult.message, createResult.options);
        }
      }
      setSubmitting(false);
    }
  };

  public handleManufacturerChange = async (
    sub: ISubscription,
    deviceFormValue: IDevice
  ) => {
    const { getDeviceModelsBySub, enqueueSnackbar } = this.props;
    const result = await getDeviceModelsBySub(sub, deviceFormValue);
    if (isSnackbarError(result)) {
      enqueueSnackbar(result.message, result.options);
    }
  };

  public renderCreateDeviceForm = (formikProps: FormikProps<IDevice>) => {
    return (
      <CreateDeviceForm
        {...formikProps}
        onManufacturerChange={this.handleManufacturerChange}
      />
    );
  };

  public renderEditDeviceForm = (formikProps: FormikProps<IDevice>) => {
    return (
      <EditDeviceForm
        {...formikProps}
        onManufacturerChange={this.handleManufacturerChange}
      />
    );
  };

  public render() {
    const {
      device,
      match: {
        params: { id }
      }
    } = this.props;
    return (
      <React.Fragment>
        <StyledFormWrapper>
          <StyledTitle marginbottom="10px">
            {id
              ? device.isLoading
                ? "Loading..."
                : device.name
              : "New Device"}
          </StyledTitle>
          <StyledBackground>
            <Grid container={true} item={true} xs={12} justify="flex-end">
              {id && (
                <NoStyleLink to={`/devices/${id}/calibrate`}>
                  <Button>
                    Calibrate <SmallBuildIcon />
                  </Button>
                </NoStyleLink>
              )}
              <NoStyleLink to="/devices">
                <Button color="default">
                  <StyledListIcon />
                  List
                </Button>
              </NoStyleLink>
            </Grid>
            {id ? (
              <Formik
                enableReinitialize={true}
                initialValues={device}
                onSubmit={this.onSubmit}
                render={this.renderEditDeviceForm}
              />
            ) : (
              <Formik
                enableReinitialize={true}
                initialValues={device}
                onSubmit={this.onSubmit}
                render={this.renderCreateDeviceForm}
              />
            )}
          </StyledBackground>
        </StyledFormWrapper>

        <br />
        <br />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IRootState): IEditDeviceStateProps => ({
  device: state.device,
  global: state.globalState
});

const mapDispatchToProps = (dispatch: any): IEditDeviceDispatchProps => {
  return {
    initializeDevice: (
      setLoadingState: boolean,
      subId: string
    ): Promise<IDevice | SnackbarError> => {
      return dispatch(initializeDeviceEdit(setLoadingState, subId));
    },
    createDevice: (device: IDevice): Promise<IDevice | SnackbarError> => {
      return dispatch(createDevice(device));
    },
    getDevice: (
      id: string,
      initializedDevice?: IDevice
    ): Promise<IDevice | SnackbarError> => {
      return dispatch(getDeviceById(id, initializedDevice));
    },
    updateDevice: (
      id: string,
      device: IDevice
    ): Promise<IDevice | SnackbarError> => {
      return dispatch(updateDeviceById(id, device));
    },
    getDeviceModelsBySub: (
      sub: ISubscription,
      deviceFormValue: IDevice
    ): Promise<IDevice | SnackbarError> => {
      return dispatch(getDeviceModelsBySub(sub, deviceFormValue));
    },
    clearDevice: () => {
      dispatch(clearDevice());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withThemeProvider(withSnackbar(EditDevice)));
