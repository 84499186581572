import { IDevice, initialDevice } from "../models/IDevice";
import { ITableDeleteCell } from "../../models/shared/IShared";
import { ActionType } from "../../models/Action.model";

export type deviceReducerTypes =
  | { type: ActionType.INITIALIZE_DEVICE_EDIT; payload: IDevice }
  | { type: ActionType.GET_DEVICE; payload: IDevice }
  | { type: ActionType.CREATE_DEVICE; payload: IDevice }
  | { type: ActionType.UPDATE_DEVICE; payload: IDevice }
  | { type: ActionType.DEVICE_LOADING; payload: boolean }
  | { type: ActionType.CLEAR_DEVICE }
  | { type: ActionType.SET_DEVICE_FORM_VALUE; payload: IDevice }
  | { type: ActionType.DELETE_DEVICE_DIALOG; payload: ITableDeleteCell }
  | { type: ActionType.GET_DEVICEMODEL_BY_SUB_FOR_DEVICE; payload: IDevice }
  | { type: ActionType.DEVICEMODEL_LIST_LOADING_FOR_DEVICE; payload: IDevice };

export function deviceReducer(
  state: IDevice = { ...initialDevice },
  action: deviceReducerTypes
): IDevice {
  switch (action.type) {
    case ActionType.GET_DEVICE:
      state = {
        ...state,
        ...action.payload,
        userList: state.userList,
        subscriptionList: state.subscriptionList
      };
      break;
    case ActionType.CREATE_DEVICE:
      state = {
        ...state,
        ...action.payload,
        userList: state.userList,
        subscriptionList: state.subscriptionList
      };
      break;
    case ActionType.UPDATE_DEVICE:
      state = {
        ...state,
        ...action.payload,
        userList: state.userList,
        subscriptionList: state.subscriptionList,
        deviceModelList: state.deviceModelList
      };
      break;
    case ActionType.DEVICE_LOADING:
      state = {
        ...state,
        isLoading: action.payload
      };
      break;
    case ActionType.INITIALIZE_DEVICE_EDIT:
      state = {
        ...state,
        userList: action.payload.userList,
        subscriptionList: action.payload.subscriptionList,
        deviceModelList: action.payload.deviceModelList,
        manufacturersList: action.payload.manufacturersList,
        subscription: action.payload.subscription
      };
      break;
    case ActionType.CLEAR_DEVICE:
      state = { ...initialDevice };
      break;
    case ActionType.SET_DEVICE_FORM_VALUE:
      state = { ...action.payload };
      break;
    case ActionType.DELETE_DEVICE_DIALOG:
      state = {
        ...state,
        deviceDelete: action.payload
      };
      break;
    case ActionType.GET_DEVICEMODEL_BY_SUB_FOR_DEVICE:
      state = { ...action.payload };
      break;
    case ActionType.DEVICEMODEL_LIST_LOADING_FOR_DEVICE:
      state = { ...action.payload };
      break;
  }
  return state;
}
