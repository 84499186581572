import * as React from "react";

import { TableHead, TableRow } from "@material-ui/core";

import {
  ButtonTableCell,
  StyledTableCell
} from "../../../components/sharedStyledComponents";

export const SubscriptionTableHeader: React.FunctionComponent = () => (
  <TableHead>
    <TableRow>
      <StyledTableCell>Name</StyledTableCell>
      <StyledTableCell>Owner</StyledTableCell>
      <ButtonTableCell />
    </TableRow>
  </TableHead>
);
