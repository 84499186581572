import React from "react";
import { connect } from "react-redux";
import { Grid, Button } from "@material-ui/core";
import { Formik } from "formik";
import { FormikActions } from "formik/dist/types";
import { RouteComponentProps } from "react-router";
import { withSnackbar, WithSnackbarProps } from "notistack";

import { withThemeProvider } from "../util/withThemeProvider";
import { IRootState } from "../store";
import { ISubscription } from "./models/ISubscription";
import { CreateSubForm } from "./components/CreateSubForm";
import { EditSubForm } from "./components/EditSubForm";
import {
  clearSubscription,
  createSubscription,
  getSubscriptionById,
  initializeSubscriptionEdit,
  updateSubscriptionById
} from "./actions/SubscriptionAction";
import {
  NoStyleLink,
  StyledTitle,
  StyledBackground,
  StyledFormWrapper,
  StyledListIcon
} from "../components/sharedStyledComponents";
import {
  alertMessages,
  isSnackbarError,
  SnackbarError,
  getStyledSnackbarOptions
} from "../core/utilities/AlertUtilities";

interface IPathParamsType {
  id: string;
}

interface IEditSubscriptionStateProps {
  subscription: ISubscription;
}

interface IEditSubscriptionDispatchProps {
  initializeSubscription: (
    setLoadingState: boolean
  ) => Promise<ISubscription | SnackbarError>;
  createSubscription: (
    subscription: ISubscription
  ) => Promise<ISubscription | SnackbarError>;
  getSubscription: (
    id: string,
    initializedSubscription?: ISubscription
  ) => Promise<ISubscription | SnackbarError>;
  updateSubscription: (
    id: string,
    subscription: ISubscription
  ) => Promise<ISubscription | SnackbarError>;
  clearSubscription: () => void;
}

type EditSubscriptionType = IEditSubscriptionStateProps &
  IEditSubscriptionDispatchProps &
  RouteComponentProps<IPathParamsType> &
  WithSnackbarProps;

export class EditSub extends React.Component<EditSubscriptionType> {
  public async componentDidMount() {
    const {
      initializeSubscription,
      getSubscription,
      enqueueSnackbar,
      match: {
        params: { id }
      }
    } = this.props;

    const result = await initializeSubscription(!Boolean(id));

    if (isSnackbarError(result)) {
      enqueueSnackbar(result.message, result.options);
    } else if (id) {
      const getSubscriptionResult = await getSubscription(id, result);

      if (isSnackbarError(getSubscriptionResult)) {
        enqueueSnackbar(
          getSubscriptionResult.message,
          getSubscriptionResult.options
        );
      }
    }
  }

  public componentWillUnmount() {
    const {
      clearSubscription,
      match: {
        params: { id }
      }
    } = this.props;

    if (id) {
      clearSubscription();
    }
  }

  public onSubmit = async (
    values: ISubscription,
    { setSubmitting }: FormikActions<ISubscription>
  ) => {
    const {
      createSubscription,
      updateSubscription,
      history,
      enqueueSnackbar,
      match: {
        params: { id }
      }
    } = this.props;
    if (values) {
      if (id) {
        const updateResult = await updateSubscription(values.id, values);

        if (!isSnackbarError(updateResult) && updateResult !== undefined) {
          enqueueSnackbar(
            alertMessages.SUBSCRIPTION_UPDATE_SUCCESS,
            getStyledSnackbarOptions("success")
          );
        } else if (isSnackbarError(updateResult)) {
          enqueueSnackbar(updateResult.message, updateResult.options);
        }
      } else {
        const createResult = await createSubscription(values);

        if (!isSnackbarError(createResult) && createResult !== undefined) {
          enqueueSnackbar(
            alertMessages.SUBSCRIPTION_CREATE_SUCCESS,
            getStyledSnackbarOptions("success")
          );
          history.push("/subscriptions");
        } else if (isSnackbarError(createResult)) {
          enqueueSnackbar(createResult.message, createResult.options);
        }
      }
      setSubmitting(false);
    }
  };

  public render() {
    const {
      subscription,
      match: {
        params: { id }
      }
    } = this.props;

    return (
      <React.Fragment>
        <StyledFormWrapper>
          <StyledTitle marginbottom="10px">
            {id
              ? subscription.isLoading
                ? "Loading..."
                : subscription.name
              : "New Subscription"}
          </StyledTitle>
          <StyledBackground>
            <Grid container={true} item={true} xs={12} justify="flex-end">
              <NoStyleLink to="/subscriptions">
                <Button color="default">
                  <StyledListIcon />
                  List
                </Button>
              </NoStyleLink>
            </Grid>
            {id ? (
              <Formik
                enableReinitialize={true}
                initialValues={subscription}
                onSubmit={this.onSubmit}
                render={EditSubForm}
              />
            ) : (
              <Formik
                enableReinitialize={true}
                initialValues={subscription}
                onSubmit={this.onSubmit}
                render={CreateSubForm}
              />
            )}
          </StyledBackground>
        </StyledFormWrapper>

        <br />
        <br />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IRootState): IEditSubscriptionStateProps => ({
  subscription: state.subscription
});

const mapDispatchToProps = (dispatch: any): IEditSubscriptionDispatchProps => {
  return {
    initializeSubscription: (
      setLoadingState: boolean
    ): Promise<ISubscription | SnackbarError> => {
      return dispatch(initializeSubscriptionEdit(setLoadingState));
    },
    createSubscription: (
      Subscription: ISubscription
    ): Promise<ISubscription | SnackbarError> => {
      return dispatch(createSubscription(Subscription));
    },
    getSubscription: (
      id: string,
      initializedSubscription?: ISubscription
    ): Promise<ISubscription | SnackbarError> => {
      return dispatch(getSubscriptionById(id, initializedSubscription));
    },
    updateSubscription: (
      id: string,
      Subscription: ISubscription
    ): Promise<ISubscription | SnackbarError> => {
      return dispatch(updateSubscriptionById(id, Subscription));
    },
    clearSubscription: () => {
      dispatch(clearSubscription());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withThemeProvider(withSnackbar(EditSub)));
