import React from "react";
import { connect } from "react-redux";
import { Grid, Button } from "@material-ui/core";
import { Formik } from "formik";
import { FormikActions } from "formik/dist/types";
import { RouteComponentProps } from "react-router";
import { withSnackbar, WithSnackbarProps } from "notistack";

import { withThemeProvider } from "../util/withThemeProvider";
import { IRootState } from "../store";
import {
  IUserBySubscription,
  IInviteUserBySubscription,
  IUserBySubscriptionCollection
} from "./models/IUserBySubscription";
import { AddUserForm } from "./components/AddUserForm";
import { EditUserForm } from "./components/EditUserForm";
import {
  initializeUserBySubscription,
  addUser,
  inviteUser,
  inviteUserDialogOpen,
  inviteUserInitialValue,
  setUserBySubEditFormValue,
  updateUser
} from "./actions/UsersBySubscriptionAction";
import {
  NoStyleLink,
  StyledBackground,
  StyledTitle,
  StyledFormWrapper,
  StyledListIcon
} from "../components/sharedStyledComponents";
import {
  alertMessages,
  SnackbarError,
  isSnackbarError,
  getStyledSnackbarOptions
} from "../core/utilities/AlertUtilities";
import { UsersBySubInviteDialog } from "./components/UserBySubInviteDialog";
import { getSubscriptionName } from "./services/UsersBySubscriptionService";
import { clearUserBySubscription } from "./actions/UsersBySubscriptionAction";
import { IRole } from "../Roles/models/IRole";

interface IPathParamsType {
  id: string;
}

interface IEditUserBySubStateProps {
  userBySubscription: IUserBySubscription;
  userBySubscriptionCollection: IUserBySubscriptionCollection;
}

interface IEditUserBySubDispatchProps {
  initializeUserBySubscription: (
    subId: string
  ) => Promise<IUserBySubscription | SnackbarError>;
  addUser: (
    user: IUserBySubscription,
    subId: string
  ) => Promise<IUserBySubscription | SnackbarError>;
  inviteUser: (
    user: IInviteUserBySubscription,
    subId: string,
    roles: IRole[]
  ) => Promise<IInviteUserBySubscription | SnackbarError>;
  inviteUserDialogOpen: (status: boolean) => void;
  inviteUserInitialValue: (user: IUserBySubscription) => void;
  clearUserBySubscription: () => void;
  setUserBySubEditFormValue: (
    userId: string,
    membersList: IUserBySubscriptionCollection,
    userBuSub: IUserBySubscription
  ) => void;
  updateUser: (
    user: IUserBySubscription,
    subId: string
  ) => Promise<undefined | SnackbarError[]>;
}

type EditUserBySubType = IEditUserBySubStateProps &
  IEditUserBySubDispatchProps &
  WithSnackbarProps &
  RouteComponentProps<IPathParamsType>;

export class EditUserBySubscription extends React.Component<EditUserBySubType> {
  public checkCollectionErrors = (response?: SnackbarError[]) => {
    const { enqueueSnackbar } = this.props;
    if (response && response.length > 0) {
      response.forEach(response => {
        if (isSnackbarError(response)) {
          enqueueSnackbar(response.message, response.options);
        }
      });
    }
  };

  public async componentDidMount() {
    const {
      initializeUserBySubscription,
      enqueueSnackbar,
      userBySubscription: {
        subscription: { value }
      },
      userBySubscriptionCollection,
      history,
      match: {
        params: { id }
      },
      setUserBySubEditFormValue
    } = this.props;

    if (value) {
      const result = await initializeUserBySubscription(value);
      if (isSnackbarError(result)) {
        enqueueSnackbar(result.message, result.options);
      } else if (id && result !== undefined) {
        setUserBySubEditFormValue(id, userBySubscriptionCollection, result);
      }
    } else {
      history.push("/users");
    }
  }

  public componentWillUnmount() {
    const { clearUserBySubscription } = this.props;
    clearUserBySubscription();
  }

  public handleInviteUserDialogOpen = () => {
    const { inviteUserDialogOpen } = this.props;
    inviteUserDialogOpen(true);
  };

  public handleInviteUserDialogClose = () => {
    const { inviteUserDialogOpen } = this.props;
    inviteUserDialogOpen(false);
  };

  public onSubmit = async (
    values: IUserBySubscription,
    { setSubmitting }: FormikActions<IUserBySubscription>
  ) => {
    const {
      addUser,
      enqueueSnackbar,
      inviteUserInitialValue,
      userBySubscription: { subscription },
      history,
      match: {
        params: { id }
      },
      updateUser
    } = this.props;

    if (values) {
      if (id) {
        const updateResult = await updateUser(values, subscription.value);

        if (updateResult) {
          this.checkCollectionErrors(updateResult);
        } else {
          enqueueSnackbar(
            alertMessages.UPDATE_USER_SUCCESS,
            getStyledSnackbarOptions("success")
          );
        }
      } else {
        const addResult = await addUser(values, subscription.value);

        if (!isSnackbarError(addResult)) {
          enqueueSnackbar(
            alertMessages.ADD_USER_SUCCESS,
            getStyledSnackbarOptions("success")
          );
          history.push("/users");
        } else {
          if (addResult.message === "404") {
            inviteUserInitialValue(values);
            this.handleInviteUserDialogOpen();
          } else {
            enqueueSnackbar(addResult.message, addResult.options);
          }
        }
      }

      setSubmitting(false);
    }
  };

  public onInviteSubmit = async (
    values: IInviteUserBySubscription,
    { setSubmitting }: FormikActions<IInviteUserBySubscription>
  ) => {
    const {
      inviteUser,
      enqueueSnackbar,
      userBySubscription: {
        subscription: { value: subId },
        roles
      },
      history
    } = this.props;

    if (values) {
      const result = await inviteUser(values, subId, roles);

      if (!isSnackbarError(result)) {
        this.handleInviteUserDialogClose();
        enqueueSnackbar(
          alertMessages.INVITE_USER_SUCCESS,
          getStyledSnackbarOptions("success")
        );
        history.push("/users");
      } else {
        enqueueSnackbar(result.message, result.options);
      }
      setSubmitting(false);
    }
  };

  public render() {
    const {
      userBySubscription: {
        subscription,
        subscriptionList,
        inviteUserDialogOpen,
        inviteUserInitialValue
      },
      userBySubscription,
      match: {
        params: { id }
      }
    } = this.props;

    return (
      <React.Fragment>
        <StyledFormWrapper>
          <StyledTitle marginbottom="10px">
            {id
              ? userBySubscription.email
                ? `${userBySubscription.email}`
                : "Loading..."
              : `Add User to "${getSubscriptionName(
                  subscription.value,
                  subscriptionList
                )}"`}
          </StyledTitle>
          <StyledBackground>
            <Grid container={true} item={true} xs={12} justify="flex-end">
              <NoStyleLink to="/users">
                <Button color="default">
                  <StyledListIcon />
                  List
                </Button>
              </NoStyleLink>
            </Grid>
            {id ? (
              <Formik
                enableReinitialize={true}
                initialValues={userBySubscription}
                onSubmit={this.onSubmit}
                render={EditUserForm}
              />
            ) : (
              <Formik
                enableReinitialize={true}
                initialValues={userBySubscription}
                onSubmit={this.onSubmit}
                render={AddUserForm}
              />
            )}
          </StyledBackground>
        </StyledFormWrapper>

        <UsersBySubInviteDialog
          inviteUserDialogOpen={inviteUserDialogOpen}
          handleInviteUserDialogClose={this.handleInviteUserDialogClose}
          subscription={subscription.value}
          subscriptionList={subscriptionList}
          onInviteSubmit={this.onInviteSubmit}
          inviteUserInitialValue={inviteUserInitialValue}
        />

        <br />
        <br />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IRootState): IEditUserBySubStateProps => ({
  userBySubscription: state.userBySubscription,
  userBySubscriptionCollection: state.userBySubscriptionCollection
});

const mapDispatchToProps = (dispatch: any): IEditUserBySubDispatchProps => {
  return {
    initializeUserBySubscription: (
      subId: string
    ): Promise<IUserBySubscription | SnackbarError> => {
      return dispatch(initializeUserBySubscription(subId));
    },
    addUser: (
      user: IUserBySubscription,
      subId: string
    ): Promise<IUserBySubscription | SnackbarError> => {
      return dispatch(addUser(user, subId));
    },
    inviteUser: (
      user: IInviteUserBySubscription,
      subId: string,
      roles: IRole[]
    ): Promise<IInviteUserBySubscription | SnackbarError> => {
      return dispatch(inviteUser(user, subId, roles));
    },
    inviteUserDialogOpen: (status: boolean) => {
      dispatch(inviteUserDialogOpen(status));
    },
    inviteUserInitialValue: (user: IUserBySubscription) => {
      dispatch(inviteUserInitialValue(user));
    },
    clearUserBySubscription: () => {
      dispatch(clearUserBySubscription());
    },
    setUserBySubEditFormValue: (
      userId: string,
      membersList: IUserBySubscriptionCollection,
      userBuSub: IUserBySubscription
    ) => {
      dispatch(setUserBySubEditFormValue(userId, membersList, userBuSub));
    },
    updateUser: (
      user: IUserBySubscription,
      subId: string
    ): Promise<undefined | SnackbarError[]> => {
      return dispatch(updateUser(user, subId));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withThemeProvider(withSnackbar(EditUserBySubscription)));
