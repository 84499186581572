import React from "react";
import { TableBody } from "@material-ui/core";

import { IUserBySubscription } from "../models/IUserBySubscription";
import { StyledTable } from "../../components/sharedStyledComponents";
import { IPaginationView, ITableDeleteCell } from "../../models/shared/IShared";
import { UserBySubTableFooter } from "./UserBySubTable/UserBySubTableFooter";
import { UserBySubTableHeader } from "./UserBySubTable/UserBySubTableHeader";
import { UserBySubTableRow } from "./UserBySubTable/UserBySubTableRow";

export interface IUserBySubTableProps {
  pagination?: IPaginationView;
  isLoading?: boolean;
  rows: IUserBySubscription[];
  totalItems?: number;
  onChangePage?: (page: string) => void;
  handleDialogOpen: (
    userBySubInfo: ITableDeleteCell
  ) => (_: React.MouseEvent) => void;
}

export const UsersBySubscriptionTable: React.FunctionComponent<
  IUserBySubTableProps
> = props => {
  return (
    <StyledTable>
      <UserBySubTableHeader />
      {!props.isLoading && (
        <React.Fragment>
          <TableBody>
            {props.rows.map(row => (
              <UserBySubTableRow
                key={row.id}
                user={row}
                handleDialogOpen={props.handleDialogOpen}
              />
            ))}
          </TableBody>
          <UserBySubTableFooter {...props} />
        </React.Fragment>
      )}
    </StyledTable>
  );
};
