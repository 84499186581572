import { IApiResponseHeader } from "../../models/IApiResponseHeader";
import { IDetermination, ILoadable } from "../../core/models/MixinInterfaces";
import {
  ISubscription,
  initialSubscription
} from "../../Subscriptions/models/ISubscription";
import {
  ISubscriptionSelection,
  ITableDeleteCell,
  ICollection
} from "../../models/shared/IShared";

export interface IPolicyApi extends IApiResponseHeader {
  name: string;
  subscription: string;
  permissions: IPermission[];
}

export interface IPolicyCollectionApi extends IApiResponseHeader, ICollection {
  "@context": string;
  member: IPolicyApi[];
  totalItems: number;
  page?: number;
}

export interface IPolicy extends ILoadable, IDetermination {
  name: string;
  subscription: ISubscription;
  subscriptionList: ISubscription[];
  permissions: IPermission[];
  policyDelete: ITableDeleteCell;
}

export interface IPolicyCollection
  extends ILoadable,
    IDetermination,
    ICollection {
  context?: string;
  members: IPolicy[];
  totalItems: number;
  page: number;
  policySubscriptionSelection: ISubscriptionSelection;
  policySubscriptionSelectionList: ISubscriptionSelection[];
}

export const initialPolicy: IPolicy = {
  id: "",
  shortId: "",
  isLoading: false,
  type: "",
  name: "",
  subscription: { ...initialSubscription },
  subscriptionList: [],
  permissions: [],
  policyDelete: {
    visible: false,
    id: "",
    title: ""
  }
};

export const initialPolicyCollection: IPolicyCollection = {
  id: "",
  shortId: "",
  isLoading: false,
  type: "",
  members: [],
  page: 0,
  totalItems: 0,
  policySubscriptionSelection: { label: "", value: "" },
  policySubscriptionSelectionList: [],
  view: {
    "@id": "",
    "@type": "",
    first: "",
    last: "",
    next: "",
    previous: ""
  }
};

export interface IPermission {
  access: string;
  methods: string[];
  resource: string;
}
