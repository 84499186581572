export enum ActionType {
  // device
  GET_DEVICE_COLLECTION = "GET_DEVICE_COLLECTION",
  CHANGE_DEVICE_COLLECTION_PAGE = "CHANGE_DEVICE_COLLECTION_PAGE",
  DEVICE_LOADING = "DEVICE_LOADING",
  DEVICE_COLLECTION_LOADING = "DEVICE_COLLECTION_LOADING",
  INITIALIZE_DEVICE_EDIT = "INITIALIZE_DEVICE_EDIT",
  GET_DEVICE = "GET_DEVICE",
  UPDATE_DEVICE = "UPDATE_DEVICE",
  CREATE_DEVICE = "CREATE_DEVICE",
  CLEAR_DEVICE = "CLEAR_DEVICE",
  SET_DEVICE_FORM_VALUE = "SET_DEVICE_FORM_VALUE",
  DELETE_DEVICE_DIALOG = "DELETE_DEVICE_DIALOG",
  GET_DEVICEMODEL_BY_SUB_FOR_DEVICE = "GET_DEVICEMODEL_BY_SUB_FOR_DEVICE",
  DEVICEMODEL_LIST_LOADING_FOR_DEVICE = "DEVICEMODEL_LIST_LOADING_FOR_DEVICE",
  DEVICE_SEARCH_BY_SERIAL_SET_VALUE = "DEVICE_SEARCH_BY_SERIAL_SET_VALUE",
  DEVICE_SEARCH_BY_SERIAL = "DEVICE_SEARCH_BY_SERIAL",

  // device model
  GET_DEVICEMODEL_COLLECTION = "GET_DEVICEMODEL_COLLECTION",
  CHANGE_DEVICEMODEL_COLLECTION_PAGE = "CHANGE_DEVICEMODEL_COLLECTION_PAGE",
  GET_DEVICEMODEL = "GET_DEVICEMODEL",
  UPDATE_DEVICEMODEL = "UPDATE_DEVICEMODEL",
  CREATE_DEVICEMODEL = "CREATE_DEVICEMODEL",
  CLEAR_DEVICEMODEL = "CLEAR_DEVICEMODEL",
  DEVICEMODEL_LOADING = "DEVICEMODEL_LOADING",
  DEVICEMODEL_COLLECTION_LOADING = "DEVICEMODEL_COLLECTION_LOADING",
  INITIALIZE_DEVICEMODEL_EDIT = "INITIALIZE_DEVICEMODEL_EDIT",
  SET_DEVICEMODEL_FORM_VALUE = "SET_DEVICEMODEL_FORM_VALUE",
  DELETE_DEVICE_MODEL_DIALOG = "DELETE_DEVICE_MODEL_DIALOG",

  // subscription
  GET_SUBSCRIPTION_COLLECTION = "GET_SUBSCRIPTION_COLLECTION",
  CHANGE_SUBSCRIPTION_COLLECTION_PAGE = "CHANGE_SUBSCRIPTION_COLLECTION_PAGE",
  GET_SUBSCRIPTION = "GET_SUBSCRIPTION",
  UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION",
  CREATE_SUBSCRIPTION = "CREATE_SUBSCRIPTION",
  CLEAR_SUBSCRIPTION = "CLEAR_SUBSCRIPTION",
  SUBSCRIPTION_LOADING = "SUBSCRIPTION_LOADING",
  SUBSCRIPTION_COLLECTION_LOADING = "SUBSCRIPTION_COLLECTION_LOADING",
  INITIALIZE_SUBSCRIPTION_EDIT = "INITIALIZE_SUBSCRIPTION_EDIT",
  SET_SUBSCRIPTION_FORM_VALUE = "SET_SUBSCRIPTION_FORM_VALUE",

  // user
  GET_USER_COLLECTION = "GET_USER_COLLECTION",
  CHANGE_USER_COLLECTION_PAGE = "CHANGE_USER_COLLECTION_PAGE",
  GET_USER = "GET_USER",
  UPDATE_USER = "UPDATE_USER",
  CREATE_USER = "CREATE_USER",
  CLEAR_USER = "CLEAR_USER",
  USER_LOADING = "USER_LOADING",
  USER_COLLECTION_LOADING = "USER_COLLECTION_LOADING",
  INITIALIZE_USER_EDIT = "INITIALIZE_USER_EDIT",
  SET_USER_FORM_VALUE = "SET_USER_FORM_VALUE",
  GET_USERS_HASROLES_ROLELIST = "GET_USERS_HASROLES_ROLELIST",
  UPDATE_USERS_HASROLES_ROLELIST = "UPDATE_USERS_HASROLES_ROLELIST",

  // customer admin
  INITIALIZE_USER_BY_SUBSCRIPTION = "INITIALIZE_USER_BY_SUBSCRIPTION",
  USER_BY_SUBSCRIPTION_SELECTION_LOADING = "USER_BY_SUBSCRIPTION_SELECTION_LOADING",
  USER_BY_SUBSCRIPTION_LOADING = "USER_BY_SUBSCRIPTION_LOADING",
  USER_BY_SUBSCRIPTION_COLLECTION_LOADING = "USER_BY_SUBSCRIPTION_COLLECTION_LOADING",
  GET_USER_BY_SUBSCRIPTION_COLLECTION = "GET_USER_BY_SUBSCRIPTION_COLLECTION",
  CHANGE_USER_BY_SUBSCRIPTION_COLLECTION_PAGE = "CHANGE_USER_BY_SUBSCRIPTION_COLLECTION_PAGE",
  CUSTOMER_ADMIN = "CUSTOMER_ADMIN",
  CURRENT_SUBSCRIPTION_CUSTOMER_ADMIN = "CURRENT_SUBSCRIPTION_CUSTOMER_ADMIN",
  INVITE_USER_DIALOG_OPEN = "INVITE_USER_DIALOG_OPEN",
  INVITE_USER_INITIAL_VALUE = "INVITE_USER_INITIAL_VALUE",
  GET_SUBSCRIPTION_COLLECTION_USER_BY_SUBSCRIPTION = "GET_SUBSCRIPTION_COLLECTION_USER_BY_SUBSCRIPTION",
  CLEAR_USER_BY_SUBSCRIPTION_COLLECTION = "CLEAR_USER_BY_SUBSCRIPTION_COLLECTION",
  CLEAR_USER_BY_SUBSCRIPTION = "CLEAR_USER_BY_SUBSCRIPTION",
  CLEAR_CURRENT_SUB_USER_BY_SUBSCRIPTION = "CLEAR_CURRENT_SUB_USER_BY_SUBSCRIPTION",
  SET_ADD_USER_FORM_VALUE = "SET_ADD_USER_FORM_VALUE",
  NORMAL_USER = "NORMAL_USER",
  SET_USER_BY_SUBSCRIPTION_STATE_FOR_EDIT = "SET_USER_BY_SUBSCRIPTION_STATE_FOR_EDIT",
  UPDATE_USER_BY_SUBSCRIPTION = "UPDATE_USER_BY_SUBSCRIPTION",
  DELETE_USER_BY_SUB_DIALOG = "DELETE_USER_BY_SUB_DIALOG",

  // utility
  REFRESH_REQUIRED = "REFRESH_REQUIRED",
  EXPIRED_SESSION = "EXPIRED_SESSION",
  CLIENT_UPDATE = "CLIENT_UPDATE",
  TOOLBAR_MENU_CHANGE_STATE = "TOOLBAR_MENU_CHANGE_STATE",
  OPEN_MENU_CHANGE = "OPEN_MENU_CHANGE",
  IS_MASTER_ADMIN = "IS_MASTER_ADMIN",
  LOGIN_ERROR = "LOGIN_ERROR",
  RESET_USER_ACCESS_LEVEL = "RESET_USER_ACCESS_LEVEL",

  // authentication
  AUTHENTICATION = "AUTHENTICATION",
  LOGOUT = "LOGOUT",

  // user profile
  USER_PROFILE_LOADING = "USER_PROFILE_LOADING",
  GET_USER_PROFILE = "GET_USER_PROFILE",
  GET_USER_PROFILE_HASROLES_ACTIVE_SUB_ROLE = "GET_USER_PROFILE_HASROLES_ACTIVE_SUB_ROLE",
  UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE",
  CLEAR_USER_PROFILE = "CLEAR_USER_PROFILE",
  SET_USER_PROFILE_FORM_VALUE = "SET_USER_PROFILE_FORM_VALUE",

  // roles
  GET_ROLE_COLLECTION = "GET_ROLE_COLLECTION",
  CHANGE_ROLE_COLLECTION_PAGE = "CHANGE_ROLE_COLLECTION_PAGE",
  GET_ROLE = "GET_ROLE",
  UPDATE_ROLE = "UPDATE_ROLE",
  CREATE_ROLE = "CREATE_ROLE",
  CLEAR_ROLE = "CLEAR_ROLE",
  ROLE_LOADING = "ROLE_LOADING",
  ROLE_COLLECTION_LOADING = "ROLE_COLLECTION_LOADING",
  INITIALIZE_ROLE_EDIT = "INITIALIZE_ROLE_EDIT",
  SET_ROLE_FORM_VALUE = "SET_ROLE_FORM_VALUE",
  DELETE_ROLE_DIALOG = "DELETE_ROLE_DIALOG",
  SET_SUBSCRIPTION_SELECTION = "SET_SUBSCRIPTION_SELECTION",

  // policies
  GET_POLICY_COLLECTION = "GET_POLICY_COLLECTION",
  CHANGE_POLICY_COLLECTION_PAGE = "CHANGE_POLICY_COLLECTION_PAGE",
  GET_POLICY = "GET_POLICY",
  UPDATE_POLICY = "UPDATE_POLICY",
  CREATE_POLICY = "CREATE_POLICY",
  CLEAR_POLICY = "CLEAR_POLICY",
  POLICY_LOADING = "POLICY_LOADING",
  INITIALIZE_POLICY_EDIT = "INITIALIZE_POLICY_EDIT",
  POLICY_COLLECTION_LOADING = "POLICY_COLLECTION_LOADING",
  SET_POLICY_FORM_VALUE = "SET_POLICY_FORM_VALUE",
  DELETE_POLICY_DIALOG = "DELETE_POLICY_DIALOG",
  SET_POLICY_SUBSCRIPTION_SELECTION = "SET_POLICY_SUBSCRIPTION_SELECTION",

  //calibration
  GET_INITIAL_CALIBRATION_HISTORY = "GET_INITIAL_CALIBRATION_HISTORY",
  GET_CALIBRATION_HISTORY = "GET_CALIBRATION_HISTORY",
  INITIALIZE_CALIBRATION = "INITIALIZE_CALIBRATION",
  CALIBRATION_LOADING = "CALIBRATION_LOADING",
  GET_DEVICE_CALIBRATION_NEW_FORMULA = "GET_DEVICE_CALIBRATION_NEW_FORMULA",
  SET_DEVICE_CALIBRATION_TABLE_FORM_VALUE = "SET_DEVICE_CALIBRATION_TABLE_FORM_VALUE",
  CALIBRATION_NEW_FORMULA_LOADING = "CALIBRATION_NEW_FORMULA_LOADING",
  CALIBRATION_HISTORY_LOADING = "CALIBRATION_HISTORY_LOADING",
  CALIBRATION_HISTORY_DETAILS_LOADING = "CALIBRATION_HISTORY_DETAILS_LOADING",
  CLEAR_CALIBRATION_CURRENT_TABLE = "CLEAR_CALIBRATION_CURRENT_TABLE",
  CLEAR_CALIBRATION = "CLEAR_CALIBRATION",
  CLEAR_CALIBRATION_HISTORY = "CLEAR_CALIBRATION_HISTORY",
  UPDATE_DEVICE_CALIBRATION_VALUE_LOADING = "UPDATE_DEVICE_CALIBRATION_VALUE_LOADING",
  GET_DEVICE_CALIBRATION_VALUE = "GET_DEVICE_CALIBRATION_VALUE",
  ADD_CREATED_CALIBRATION_TO_HISTORY = "ADD_CREATED_CALIBRATION_TO_HISTORY",
  GET_CREATE_CALIBRATION = "GET_CREATE_CALIBRATION"
}
